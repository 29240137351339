import React, { useEffect, useState, useContext } from 'react'
import { Input, Button, Select, Radio, Checkbox, message } from 'antd'
// import locale from 'antd/lib/date-picker/locale/es_ES';
import { useHistory, useParams } from "react-router-dom";
import { Form } from './FormTypes';
import moment from 'moment';
// import 'moment/locale/es';
import Http from 'Http';
import './Form.css';
import Theme from 'Theme';
import InputColor from './InputColor';
import Permitido from 'Procesos';
import { AppContext } from 'App';
import { SetAppTitle } from 'Utils';
import Config from 'Config';
import InputFile from './InputFile';
import { ArrowLeftOutlined, DeleteOutlined } from '@ant-design/icons';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Trumbowyg  from 'react-trumbowyg';


export const FormComponent = ({ data: FormData, onValueChanged: onDataChange, children, onSubmit, onSave = null, defaultId = null, label = true, loading = false, goBack = false }) => {
  const { Fields, Buttons, Model, ComponentSave } = FormData;
  const Params = useParams();
  const History = useHistory();
  const [CSave, setCSave] = useState(false);
  const AppValues = useContext(AppContext);
  const [Loading, setLoading] = useState(false)

  const Procesos = () => {
    if (!Model) {
      setCSave(true);
      return;
    }
    if (!Params.id) {
      // console.log(Permitido.Agregar(AppValues, Model))
      setCSave(Permitido.Agregar(AppValues, Model))
    }
    else {
      setCSave(Permitido.Modificar(AppValues, Model))
    }
  }

  const loadPreds = async (id = 1) => {
    if (!Params.id) return;
    if (!Model || Model.length === 0) return;
    id = defaultId || Params.id;
    try {
      let Res = await Http.get(`${Model}/${id}`);
      // console.log(Res.data)
      if (!Res.error) {
        const { data } = Res;
        // console.log(data)
        onDataChange({ ...Form.PatchPreds({ ...FormData }, data) })
        // setFormData({ ...Form.PatchPreds(FormData, data) })
        // let FieldsWPreds = Fields.map((Field) => {
        //   if (!data[Field.Name]) return Field;
        //   if (isDate(data[Field.Name])) data[Field.Name] = moment(data[Field.Name]);
        //   Field.Value = data[Field.Name];
        //   return Field;
        // })
        // onDataChange({ ...FormData, FieldsWPreds })
      }
    } catch (error) {

    }
  }

  useEffect(() => {
    // console.log('[FORM] USE EFFECT')
    Procesos();
    SetAppTitle(Model)
    loadPreds();
    // eslint-disable-next-line
  }, [])

  const onFieldsChange = (Fields) => {
    onDataChange({ ...FormData, Fields })
  }

  const formOnSubmit = (Values, Button, Fields) => {
    if (!ComponentSave) return onSubmit(Values, Button, Fields);
    doSubmit(Values);
  }

  const isDate = (date) =>{
    let isdate = false;
    if (isNaN(date) && typeof date !== 'object' && moment(date, [moment.ISO_8601], true).isValid()) {
      isdate = true;
    }
    if(/^[0-9][0-9]:[0-9][0-9]$/.test(date)) {
      isdate = true;
    }
    if (/^(((19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])) [0-9][0-9]:[0-9][0-9])$/.test(date)) {
      isdate = true;
    }
    if(date instanceof Date) {
      isdate = true;
    }
    // console.log(`Se verifica si ${date} es Date: ${isdate}`);
    return isdate;
  }

  const doSubmit = async (Values) => {
    setLoading(true);
    try {
      for (const key in Values) {
        if(isDate(Values[key])) {
          if (typeof Values[key] === 'string') {
            Values[key] = Values[key].replace('Z', '');
          }
          const dateString = moment(Values[key]).format('YYYY/MM/DD HH:mm');
          Values[key] = dateString;
        }
      }
      let Res = await Http.post(`${Model}${Params.id ? `/${Params.id}` : ''}/save`, { ...Values });
      // console.log(Res)
      if (!Res.error) {
        message.info(`Guardado`);
        if (onSave) {
          onSave(true, Res.data);
          setLoading(false);
          return;
        }
        if (!Params.id) {
          History.goBack()
          // History.push(`${Res.data}`)
        }
      }
      else {
        message.warn(`Error: ${Res.message}`);
        if (onSave)
          onSave(false, Res.errorMsg);
      }
    } catch (error) {
      message.warn(`Error al guardar`);
      if (onSave)
        onSave(false, error);
      console.log(`Error al guardar (doSubmit) para ${Model}: `, error)
    }
    setLoading(false);
    return;
  }

  return (
    <div>
      <DynamicForm
        fields={Fields}
        buttons={Buttons}
        onFieldsChange={onFieldsChange}
        onSubmit={formOnSubmit}
        label={label}
        disabled={!CSave}
        loading={loading || Loading}
        goBack={goBack}
      >
        {children}
      </DynamicForm>
    </div>
  )
}

// function usePrevious(value) {
//   const ref = useRef();
//   useEffect(() => {
//     ref.current = value;
//   });
//   return ref.current;
// }

export const DynamicForm = ({ fields: Fields, onFieldsChange, onSubmit, buttons: Buttons = [], children, loading: Loading = false, label: Label = false, disabled: Disabled = false, goBack = true }) => {
  let SubmitButton = 1;
  // const FieldsConst = Fields;
  const History = useHistory();
  const AppValues = useContext(AppContext);
  const [valoresHTML, setValoresHTML] = useState({});

  const isDate = (date) =>{
    let isdate = false;
    if (isNaN(date) && typeof date !== 'object' && moment(date, [moment.ISO_8601], true).isValid()) {
      isdate = true;
    }
    if(/^[0-9][0-9]:[0-9][0-9]$/.test(date)) {
      isdate = true;
    }
    if (/^(((19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])) [0-9][0-9]:[0-9][0-9])$/.test(date)) {
      isdate = true;
    }
    if(date instanceof Date) {
      isdate = true;
    }
    // console.log(`Se verifica si ${date} es Date: ${isdate}`);
    return isdate;
  }

  const dynamicOnSubmit = (event) => {
    event.preventDefault();
    // console.log(`[DynamicForm] onSubmit`, '1');
    let ValidatedFields = Form.ValidateFields(Fields, true);
    // ValidatedFields.forEach((Field) => {if(!Field.Valid) console.log(`El field ${Field.Name} no es Valid`)})
    if (ValidatedFields.some((Field) => !Field.Valid)) {
      onFieldsChange(ValidatedFields);
      return;
    }
    // console.log(`[DynamicForm] onSubmit`, '2');
    const ValuesObject = {};
    Fields.forEach(Field => {
      if (Form.NoFields.includes(Field.Type)) return;
      ValuesObject[Field.Name] = Field.Value;
      if (Field.Type === 'trumbowyg') {
        ValuesObject[Field.Name] = valoresHTML[Field.Name];
      }
      if(isDate(ValuesObject[Field.Name])) {
        if (typeof ValuesObject[Field.Name] === 'string') {
          ValuesObject[Field.Name] = ValuesObject[Field.Name].replace('Z', '');
        }
        const dateString = moment(ValuesObject[Field.Name]).format('YYYY/MM/DD HH:mm');
        ValuesObject[Field.Name] = dateString;
      }
    })
    // console.log(`[DynamicForm] onSubmit`, ValuesObject);
    onSubmit(ValuesObject, SubmitButton, Fields);
    // onSubmit(ValuesObject, SubmitButton, Fields);
  }

  const onFieldBlured = (Field, Value) => {
    // console.log(`[DynamicForm] Se ha hecho Blurred el campo ${Field.Name}`);
    let BlurredFields = Fields.map((MapField) => {
      if (MapField.Name !== Field.Name) return MapField;
      MapField.Blurred = true;
      return MapField;
    })
    onFieldsChange(Form.ValidateFields(BlurredFields));
    // console.log(Value)
  }

  const onFieldChange = (Field, Value) => {
    let Watchers = [];
    let ChangedFields = Fields.map((MapField) => {
      if (MapField.Name !== Field.Name) return MapField;
      MapField.Value = Value;
      // if(MapField.Watcher) MapField.Watcher(Value, Fields);
      if (MapField.Watcher) {
        Watchers.push({ Function: MapField.Watcher, Field })
      }
      return MapField;
    })
    // console.log(FieldsConst, ChangedFields)
    // onFieldsChange(ChangedFields)
    onFieldsChange(Form.ValidateFields(ChangedFields));
    for (const Watcher of Watchers) {
      Watcher.Function(Field.Value, ChangedFields);
    }
    // setTimeout(() => {
    //   Watchers.forEach(Watcher => Watcher.Function(Field.Value, ChangedFields))
    // }, 1000);
  }

  // console.log(Fields)
  return (
    <div>
      { goBack && (
        <div style={{ marginBottom: 10 }}>
          <Button onClick={() => History.goBack()} icon={<ArrowLeftOutlined />} type='ghost' size='small'>Regresar</Button>
        </div>
      )}

      <form onSubmit={dynamicOnSubmit}>
        <div className='row'>
          {
            Fields.map((Field, Fi) => {
              const DefaultProps = {
                key: Field.Name + Fi.toString(),
                name: Field.Name,
                type: Field.Type,
                value: Field.Value,
                disabled: Field.Disabled,
                rules: Field.Validators,
                placeholder: Field.Label,
                className: `${(Field.Blurred && !Field.Valid) ? 'form-field-invalid' : ''}`,
                autoFocus: Fi === 0,
                style: { width: '100%', position: 'relative', display: `${Field.Hidden ? 'none' : 'inherit'}` },
                size: "large",
                onChange: (event) => onFieldChange(Field, event.target.value),
                onBlur: (event) => onFieldBlured(Field, event)
              }

              if (Field.Type === 'section') return (
                <div key={Fi} className={`col-12 col-sm-${Field.Col} section`}>
                  {Field.Value === 1 &&
                    <h3 style={{ color: Theme.primary }}>{Field.Label}</h3>
                  }
                  {Field.Value === 2 &&
                    <h4>{Field.Label}</h4>
                  }
                </div>
              )

              if (Field.Type === 'id')
                return Field.Value ?
                  (
                    <div key={Fi} className={`col-12 col-sm-${Field.Col} id`}>
                      <span style={{ borderColor: Theme.primary, color: Theme.primary }}>ID: {Field.Value}</span>
                    </div>
                  ) : (<div key={Fi}></div>)

              return (
                <div key={Field.Name} className={`col-12 col-sm-${Field.Col} col-form`}>

                  <div className='form-field'>
                    {
                      Label &&
                      <label className='form-label' style={{ display: `${Field.Hidden ? 'none' : 'inherit'}` }}>
                        <span style={{ color: `#${AppValues.prop.color1}` }}>
                          {`${(Field.Validators && Field.Validators.required) ? '*' : ''}${Field.Label}`}
                        </span>
                      </label>
                    }
                    {(Field.Type === 'text' || Field.Type === 'password') && (
                      <Input
                        {...DefaultProps}
                        maxLength={Field.Validators.maxLength}
                      />
                    )}

                    {(Field.Type === 'number') && (
                      <Input
                        {...DefaultProps}
                        min={Field.Validators.min}
                        max={Field.Validators.max}
                        step={0.01}
                        // onChange={(value) => onFieldChange(Field, value)}
                      />
                    )}

                    {(Field.Type === 'textarea') && (
                      <Input.TextArea
                        {...DefaultProps}
                        maxLength={Field.Validators.maxLength}
                      />
                    )}

                    {(Field.Type === 'trumbowyg') && (
                      <Trumbowyg 
                        {...DefaultProps}
                        id='react-trumbowyg'
                        data={DefaultProps.value ? DefaultProps.value : ''}
                        onChange = {(event) => {
                          setValoresHTML(data => {
                            data[Field.Name] = event.target.innerHTML;
                            return {...data};
                          })
                        }}
                        onBlur = {(event) => onFieldBlured(Field, event)}
                      />
                    )}

                    {(Field.Type === 'select' || Field.Type === 'selectm') && (
                      <Select
                        {...DefaultProps}
                        onChange={(value) => onFieldChange(Field, value)}
                        // mode={`${Field.Type === 'select'}`}
                        mode={Field.Type === 'select' ? 'default' : 'multiple'}
                      >
                        {
                          Field.Options.map((Option, Oi) => (
                            <Select.Option key={`${Option.Value}-${Oi}`} value={Option.Value}>{Option.Label}</Select.Option>
                          ))
                        }
                      </Select>
                    )}

                    {(Field.Type === 'selectsearch' || Field.Type === 'selectsearchm') && (
                      <Select
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) => {
                          // console.log(input, option.props.children)
                          return option.props.children.toLowerCase().includes(input.toLowerCase())
                          // return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                          // option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        {...DefaultProps}
                        onChange={(value) => onFieldChange(Field, value)}
                        mode={Field.Type === 'selectsearch' ? 'default' : 'multiple'}
                      >
                        {
                          Field.Options.map((Option, Oi) => (
                            <Select.Option key={`${Option.Value}-${Oi}`} value={Option.Value}>{Option.Label}</Select.Option>
                          ))
                        }
                      </Select>
                    )}

                    {
                      (Field.Type === 'autocomplete' && (
                        <Select
                          showSearch
                          onSearch={(val) => { Field.AutocompleteFetch(val) }}
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          {...DefaultProps}
                          onChange={(value) => onFieldChange(Field, value)}
                        >
                          {
                            Field.Options.map((Option, Oi) => (
                              <Select.Option key={`${Option.Value}-${Oi}`} value={Option.Value}>{Option.Label}</Select.Option>
                            ))
                          }
                        </Select>
                      ))
                    }

                    {/* {(Field.Type === 'selectm') && (
                    <Select
                      {...DefaultProps}
                      mode="multiple"
                      onChange={(value) => onFieldChange(Field, value)}
                    >
                      {
                        Field.Options.map((Option, Oi) => (
                          <Select.Option key={`${Option.Value}-${Oi}`} value={Option.Value}>{Option.Label}</Select.Option>
                        ))
                      }
                    </Select>
                  )} */}

                    {(Field.Type === 'date') && (
                      <DatePicker  
                        {...DefaultProps}
                        autoComplete="off"
                        placeholderText={Field.Label}
                        selected={Field.Value ? Field.Value : undefined} 
                        onChange={(date) => onFieldChange(Field, date)}
                        customInput={<Input 
                          autoComplete="off" 
                          placeholder={Field.Label}
                          size="large" 
                          className= {`${(Field.Blurred && !Field.Valid) ? 'form-field-invalid' : ''}`} />
                        }
                        dateFormat="dd/MM/yyyy"
                      />
                    )}

                    {(Field.Type === 'datetime') && (
                      <DatePicker  
                        {...DefaultProps}
                        autoComplete="off"
                        placeholderText={Field.Label}
                        selected={Field.Value ? Field.Value : undefined} 
                        onChange={(date) => onFieldChange(Field, date)}
                        customInput={<Input  
                          autoComplete="off"
                          placeholder={Field.Label}
                          size="large" 
                          className= {`${(Field.Blurred && !Field.Valid) ? 'form-field-invalid' : ''}`} />
                        }
                        dateFormat="dd/MM/yyyy HH:mm"
                        showTimeSelect
                      />
                    )}

                    {(Field.Type === 'time' || Field.Type === 'hour') && (
                      <DatePicker
                        {...DefaultProps}
                        autoComplete="off"
                        placeholderText={Field.Label}
                        selected={Field.Value ? Field.Value : undefined} 
                        onChange={(date) => onFieldChange(Field, date)}
                        customInput={<Input  
                          autoComplete="off"
                          placeholder={Field.Label}
                          size="large" 
                          className= {`${(Field.Blurred && !Field.Valid) ? 'form-field-invalid' : ''}`} />
                        }
                        showTimeSelect
                        showTimeSelectOnly
                        timeCaption="Hora"
                        timeFormat="HH:mm"
                        dateFormat="HH:mm"
                      />
                    )}

                    {(Field.Type === 'radio') && (
                      <Radio.Group
                        {...DefaultProps}
                      >
                        {/* <span className='radio-label'>{Field.Label}</span> */}
                        {
                          Field.Options.map((Option, Oi) => (
                            <Radio key={Option.Value} value={Option.Value}>{Option.Label}</Radio>
                          ))
                        }
                      </Radio.Group>
                    )}

                    {(Field.Type === 'check' || Field.Type === 'checkbox') && (
                      <Checkbox
                        {...DefaultProps}
                        onChange={(event) => onFieldChange(Field, event.target.checked)}
                        checked={Field.Value}
                        style={{ display: 'flex' }}
                      >{Field.Label}
                      </Checkbox>
                    )}

                    {(Field.Type === 'color') && (
                      <InputColor
                        {...DefaultProps}
                        onChange={(value) => onFieldChange(Field, value)}
                      />
                    )}

                    {(Field.Type === 'file') && (
                      <div className={`row`}  key={Field.Name + Fi.toString()}>
                        <div className={`col-10 col-sm-10`}>
                          <InputFile
                            {...DefaultProps}
                            onChange={(value) => onFieldChange(Field, value)}
                          />
                        </div>
                        <div className={`col-2 col-sm-2`}>
                          <Button 
                            key={Field.Name + Fi.toString()}
                            type="primary" 
                            icon={<DeleteOutlined />} 
                            onClick={() => {
                              onFieldChange(Field, 'deleted')
                            }}
                          />
                        </div>
                      </div>
                    )}

                    <div style={{ maxWidth: '100%' }}>
                      {(Field.Type === 'image') && Field.Value && (
                          Field.Value ?
                          <img
                            style={{ maxWidth: '100%' }}
                            alt='img-form'
                            src={`${Field.Value}`}
                          /> :
                          <div></div>
                      )}
                    </div>

                    {(Field.Type === 'hidden') && (
                      <Input
                        {...DefaultProps}
                        hidden={true}
                      />
                    )}

                    {(
                      Field.Warnings &&
                      Field.Warnings.map((message, index) => (<span className='warning-message' key={`warning-${index}`} >{message}</span>))
                    )}

                    {(
                      Field.Errors && Field.Errors.length > 0 &&
                      <span className='error-message'>
                        {
                          Field.Errors.map((error, ei) => (
                            <span key={`error-${ei}`}>{error} {ei < (Field.Errors.length - 1) && ' / '}</span>
                          ))
                        }
                      </span>
                      // Field.Errors.map((message, index) => (<span className='error-message' key={`error-${index}`} >{message}</span>))
                    )}
                    {
                      Field.Caption && Field.Caption.length > 0 && (
                        <span className='caption'>{Field.Caption}</span>
                      )
                    }
                  </div>

                </div>
              )
            })
          }
        </div>
        <div>
          {children}
        </div>
        { Buttons.length > 0 &&
          <div style={{ textAlign: 'right', marginTop: 10 }}>
            {Config.Production && <span className='aviso-estado'>Los cambios <b>NO</b> son de prueba</span>}
            {!Config.Production && <span className='aviso-estado son'>Los cambios son de prueba</span>}
            {
              Buttons.map((ButtonI, Bi) => (
                <Button
                  key={Bi}
                  onClick={() => { SubmitButton = Bi + 1 }}
                  htmlType='submit'
                  type={Bi === 0 ? 'primary' : 'default'}
                  style={{ marginLeft: 6 }}
                  loading={Loading}
                  disabled={Disabled}
                >
                  {ButtonI}
                </Button>
              ))
            }
          </div>
        }
      </form>
    </div>
  )
}