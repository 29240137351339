import React, { Component } from 'react'
// import Base from '../Form/Base'
// import BaseTable from '../Table/Base'
import Side from './Side'
import {
  Switch,
  Route,
} from "react-router-dom";
import Catalogo from '../Catalogos/Catalogo';
import './Inicio.css';
import Evento from 'components/Evento/Evento';
import Sistema from 'components/Sistema';
import EventoProceso from 'components/Evento/EventoProceso';
import Recursos from 'components/Recursos';
import Fechas from 'components/Evento/Eventos';
import { BaseButacas } from 'components/Catalogos/Recintos/Butacas';

import Numerar from 'components/Catalogos/Recintos/NumerarCalendario';
// import Prueba from './Prueba';
import { AppContext } from '../../App';

export default class Inicio extends Component {
  render() {
    return (
      <div>
        {/* <BaseTable />
        <Base /> */}
        <AppContext.Consumer>
          {(context) => ( 
            <div style={{ 
              width: '100%', 
              height: '5px', 
              background: `#${context.prop ? context.prop.color1 : '000'}`,
              position: 'absolute', 
              zIndex: 102 }} >
              </div>) 
          }         
        </AppContext.Consumer>
        <Side onLogOut={this.props.onLogOut} />
        <div className='inicio-route'>
          <Switch>
            <Route path="/Catalogo">
              <Catalogo />
            </Route>
            <Route path="/Evento">
              <Evento />
            </Route>
            <Route path="/Calendario/:idcalendario?/Numeracion/:idarea?">
              <Numerar />
            </Route>
            <Route path="/Calendario/:id?">
              <EventoProceso />
            </Route>
            <Route path="/Calendarios">
              <Fechas />
            </Route>
            <Route path="/Sistema">
              <Sistema />
            </Route>
            <Route path="/Recursos">
              <Recursos />
            </Route>
            <Route path="/Butacas">
              <BaseButacas />
            </Route>
            {/* <Route path="/Numerar">
              <Numerar />
            </Route> */}
          </Switch>
          {/* <Prueba /> */}
        </div>
      </div>
    )
  }
}
