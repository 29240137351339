import React, { useState } from 'react'
import { Table, TableComponent } from 'components/Table'

export default function PromotorContactos(props) {
  const [TableData, setTableData] = useState(
    Table.Data([
      Table.Field('idcontacto', 'idcontacto'),
      Table.Field('nombre', 'Nombre'),
      Table.Field('puesto', 'Puesto'),
      Table.Field('correo', 'Correo'),
      Table.Field('telefono', 'Telefono'),
    ], [
      {Id: 'edit_contact', Label: 'Editar', Icon: 'edit', Cb: (a) => {
        props.onClickContact(a)
      } }
    ],
      Table.Config(`Promotor/contactos/${props.idpromotor}`, 'nombre', 'idpromotor')
    )
  )
  
  return (
    <div>
      <TableComponent
        goBack={false}
        create={false}
        data={TableData}
        newRow={props.row}
      />
    </div>
  )
}
