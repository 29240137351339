export interface TableField {
  Label: string;
  Name: string;
}
export interface TableAction {
  Label?: string;
  Icon?: string;
  Id: string;
}
export interface TableConfig {
  DataUrl: string;
  DeleteUrl: string;
  DeleteFieldId: string;
}

const ConsoleDefaultCbs = true;

export class Table {
  static Data(Fields: TableField[], Actions: TableAction[] = [], Config: TableConfig) {
    return {
      Fields,
      Actions,
      Config
    }
  }

  static Field(Name: string, Label: string) {
    return {
      Name,
      Label
    }
  }

  static Action(Id: string, Label: string, Cb: any = null, Icon: string | null = null) {
    return {
      Id,
      Label,
      Cb: Cb || ((row: any) => { if (ConsoleDefaultCbs) console.warn(`[Cb (acción sin controlar)] ${Label} (${Id}): `, row) }),
      Icon,
    }
  }

  static Config(Model: string, DeleteFieldId: string, FieldId: string = 'id', Selectable: boolean | Function = false) {
    return {
      Model,
      DeleteFieldId,
      FieldId,
      Selectable
    }
  }

  static Config2(DataUrl: string, DeleteUrl: string, DeleteFieldId: string) {
    return {
      DataUrl,
      DeleteUrl,
      DeleteFieldId,
    }
  }

  static DefaultActions() {
    return [
      Table.Action('defDelete', 'Eliminar', null, 'delete'),
      Table.Action('defUpdate', 'Modificar', null, 'edit'),
    ]
  }
}