import { DatePicker } from 'antd';
import React from 'react'
import './CambioPrecios.css'

export default function Area({
  nombre = '',
  productos = [],
  onProductosChange = () => { }
}) {

  const productoChage = (i, value, key) => {
    productos[i][key] = value;
    onProductosChange(productos);
  }

  return (
    <div className='cambio-precios-area'>
      <h3>{nombre}</h3>
      {
        productos.map((producto, pi) => (
          <div className='producto' key={producto.producto}>
            <div className='nombre'>
              <span>{producto.producto}</span>
            </div>
            <div className='precios'>
              <PrecioIndividual onChange={(val) => productoChage(pi, val, 'precio')} label='Precio' precio={producto.precio} />
              <PrecioIndividual onChange={(val) => productoChage(pi, val, 'costoboleto')} label='Boleto' precio={producto.costoboleto} />
              <PrecioIndividual onChange={(val) => productoChage(pi, val, 'costoservicio')} label='Servicio' precio={producto.costoservicio} />
              <PrecioIndividual onChange={(val) => productoChage(pi, val, 'costotc')} label='TC' precio={producto.costotc} />
              <PrecioIndividual onChange={(val) => productoChage(pi, val, 'alimentos')} label='Alimentos' precio={producto.alimentos} />
              <PrecioIndividual onChange={(val) => productoChage(pi, val, 'hospedaje')} label='Hospedaje' precio={producto.hospedaje} />
              <FechaIndividual onChange={(val) => productoChage(pi, val, 'fec_ini')} label='Inicio' precio={producto.fec_ini} />
              <FechaIndividual onChange={(val) => productoChage(pi, val, 'fec_fin')} label='Fin' precio={producto.fec_fin} />
            </div>
          </div>
        ))
      }
    </div>
  )
}

const PrecioIndividual = ({
  precio = 0,
  label = '',
  onChange = () => { }
}) => {
  return (
    <div className='precio-individual'>
      <label>{label}</label>
      <input value={precio} onChange={(e) => onChange(e.target.value)} />
    </div>
  )
}

const FechaIndividual = ({
  fecha = null,
  label = '',
  onChange = () => { }
}) => {
  return (
    <div className='fecha-individual'>
      <DatePicker
        placeholder={label}
        value={fecha}
        onChange={(e) => onChange(e.target.value)}
        format={'DD-MM-YYYY HH:mm'}
        showTime={{ format: 'HH:mm' }}
      />
    </div>
  )
}