import React, { useState } from 'react'
import { Form, FormComponent } from 'components/Form'

export default function PromotorContacto(props) {
  
  const [FormData, setFormData] = useState(
    Form.Group([
      Form.Field('hidden', 'idcontacto', 'idcontacto', 0, {}, [], false, false, false, false, true),
      Form.Field('text', 'nombre', 'Nombre', 12, { required: true }),
      Form.Field('text', 'puesto', 'Puesto', 12, { required: true }),
      Form.Field('text', 'correo', 'Correo', 12, { required: true, email: true }),
      Form.Field('text', 'telefono', 'Telefono', 12, { required: true }),
    ], ['Guardar'], 'Promotor-Contacto', false)
  );
  
  return (
    <div>
      <h2>Contacto</h2>
      <FormComponent
        defaultId={props.idcontacto}
        goBack={false}
        data={FormData}
        onSubmit={(contacto) => props.onSave(contacto)}
        onValueChanged={setFormData}
      />
    </div>
  )
}
