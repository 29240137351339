import React, { useState, useEffect } from 'react'
import { Form, FormComponent } from 'components/Form'
import BackButton from 'components/BackButton/BackButton';
import Http from 'Http';

export default function PuntoVenta({ onSave = null, idpropietario = null, goBack = true }) {
  
  const [FormData, setFormData] = useState(
    Form.Group([
      Form.ID('idpdv'),
      Form.Field('selectsearch', 'idpropietario', 'Propietario', 12, { required: true }),
      Form.Field('text', 'descripcion', 'Descripcion', 12, { required: true, maxLength: 30 }),
      Form.Field('checkbox', 'imprimir', 'Imprimir', 4, { required: true }),
      Form.Field('checkbox', 'entregar', 'Entregar', 4, { required: true }),
      Form.Field('checkbox', 'cargoservicio', 'Cargo x Servicio', 4, { required: true }),
      Form.Field('checkbox', 'callcenter', 'Call Center', 4, { required: true }),
      Form.Field('checkbox', 'ventanum', 'Venta Numerada', 4, { required: true }),
      Form.Field('checkbox', 'ventanonum', 'Venta No Numerada', 4, { required: true }),
      Form.Field('checkbox', 'sololectura', 'Solo Lectura', 4, { required: true }),
      /* Form.Field('number', 'zeta', 'Zeta', 4, { required: true }, [], 0),
      Form.Field('number', 'consecutivo', 'Consecutivo', 4, { required: true }, [], 0), */
    ], ['Guardar'], 'punto-venta')
  );
  
  useEffect(() => {
    fillForm();
    if (idpropietario) {
      setFormData(Form.Patch({ ...FormData }, { idpropietario }))
    }
    // eslint-disable-next-line
  }, []);
  
  const fillForm = async () => {
    try {
      let Res = await Http.get('evento/form');
      console.log(Res);
      if (!Res.error) {
        let newFD = Form.FieldOptions({ ...FormData }, 'idpropietario', Res.data.propietarios, 'idpropietario', 'descripcion');
        setFormData(newFD)
      }
    } catch (error) {

    }
  }

  const onSubmit = (Values, Button) => {
    console.log(`[Submit] Botón ${Button}, valores: `, Values)
  }
  
  return (
    <div>
      <BackButton />
      <h2>Punto de Venta</h2>
      <FormComponent
        data={FormData}
        onSubmit={onSubmit}
        onValueChanged={setFormData}
      />
    </div>
  )
}
