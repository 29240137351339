import { DeleteOutlined, EditOutlined, NumberOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Divider } from 'antd';
import moment from 'moment';
import React, { useMemo } from 'react'
import Theme from 'Theme';
import './Pagos.css';

export default function FormaPago({
	precioArea = {},
	onDelete = () => { },
	onEditPrecio = () => { },
}) {
	return (
		<div className='pagos-area'>
			<div className='pa-header'>
				<h3>{precioArea.DESCRIPCION}</h3>
          <div className='pa-acciones'>
					  <Button htmlType='button' size='small' icon={<DeleteOutlined />} onClick={onDelete}>Eliminar Forma de Pago</Button>
            <Button htmlType='button' size='small' icon={<EditOutlined />} onClick={onEditPrecio}>Editar Datos</Button>
				</div>
			</div>
      <div className='pa-producto'>
        <div className='pa-precios'>
          <div className='pa-forma'>
            <p style={{ borderColor: Theme.primary }}>
              { moment(precioArea.fec_ini, 'YYYY/MM/DD HH:mm').format('DD/MM/YYYY HH:mm') }
              <span>Fecha Inicio</span>
            </p>
          </div>
          <div className='pa-forma'>
            <p style={{ borderColor: Theme.primary }}>
              { moment(precioArea.fec_fin, 'YYYY/MM/DD HH:mm').format('DD/MM/YYYY HH:mm') }
              <span>Fecha Final</span>
            </p>
          </div>
          <div className='pa-forma'>
            <p style={{ borderColor: Theme.primary }}>
              { precioArea.forzar ? 'SI' : 'NO' }
              <span>Forzar</span>
            </p>
          </div>
        </div>
      </div>
			<Divider />
		</div>
	)
}
