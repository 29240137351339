import React, { useState, useEffect } from 'react'
import { Form, FormComponent } from 'components/Form'
import { useParams, useHistory } from "react-router-dom";
import Http from 'Http';
import BackButton from 'components/BackButton/BackButton';
import { Button, Collapse, message, Modal } from 'antd';

const { Panel } = Collapse;
let Params = []

function PreguntaOpcion({ opcion, onOpcionChange }) {
  
  const [FormData, setFormData] = useState(
    Form.Group([
      Form.Field('text', 'valor', 'Valor', 8, { required: true, minLength: 0, maxLength: 256 }, [], opcion.valor, (val) => onOpcionChange('valor', val)),
      Form.Field('checkbox', 'activo', 'Activo', 2, {}, [], opcion.activo, (val) => onOpcionChange('activo', val)),
      Form.Field('checkbox', 'cancelada', 'Cancelada', 2, {}, [], opcion.cancelada, (val) => onOpcionChange('cancelada', val)),
    ], [], 'formulario')
  );

  return (
    <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }} >
      <FormComponent
        data={FormData}
        onValueChanged={setFormData}
      />
    </div>
  );
}

function Pregunta({ pregunta, onPreguntaChange, tiposPreguntas }) {
  
  const [showOptions, setShowOptions] = useState(false);
  const [opciones, setOpciones] = useState(pregunta.opciones);
  const [FormData, setFormData] = useState(
    Form.Group([
      Form.Field('select', 'idtipo', 'Tipo', 2, { required: true }, [], pregunta.idtipo, (val) => onTipoChange('idtipo', val)),
      Form.Field('text', 'pregunta', 'Pregunta', 10, { required: true, minLength: 0, maxLength: 256 }, [], pregunta.pregunta, (val) => onTipoChange('pregunta', val)),
      Form.Field('text', 'comentario', 'Comentario', 12, { maxLength: 64 }, [], pregunta.comentario, (val) => onTipoChange('comentario', val)),
      Form.Field('checkbox', 'requerida', 'Requerida', 4, {}, [], pregunta.requerida, (val) => onTipoChange('requerida', val)),
      Form.Field('checkbox', 'activa', 'Activa', 4, {}, [], pregunta.activa, (val) => onTipoChange('activa', val)),
      Form.Field('checkbox', 'cancelada', 'Cancelada', 4, {}, [], pregunta.cancelada, (val) => onTipoChange('cancelada', val)),
      Form.Section('Pregunta por Orden'),
      Form.Field('checkbox', 'todo_folio', 'Pregunta por Orden', 4, {}, [], pregunta.todo_folio, (val) => onTipoChange('todo_folio', val)),
    ], [],)
  );

  useEffect(() => {
    setFormData(FormData => {
      return Form.FieldOptionsGroup({ ...FormData }, [
        Form.FOG_Field('idtipo', tiposPreguntas, 'idtipo', 'descripcion'),
      ])
    }
    )
  }, [tiposPreguntas])

  useEffect(() => {
   onTipoChange('idtipo', pregunta.idtipo)
  }, [])

  const onTipoChange = (propierty, val) => {
    console.log({propierty, val})
    if (propierty === 'idtipo') {
      setShowOptions(val === '3' || val === '2');
    }
    onPreguntaChange(propierty, val);
  }

  const onAddOption = () => {
    const nuevasOpciones = pregunta.opciones;
    nuevasOpciones.push({
      valor: 'Nueva Opcion',
      activo: true,
      cancelada: false
    })
    setOpciones([...nuevasOpciones])
    onPreguntaChange('opciones', [...nuevasOpciones]);
  }
  
  const onOpcionChange = (index, prop, value) => {
    setOpciones(opciones => {
      let opcionesModified = opciones;
      opcionesModified[index][prop] = value;
      onPreguntaChange('opciones', opcionesModified);
      return opcionesModified;
    })
  }

  return (
    <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }} >
      <FormComponent
        data={FormData}
        onValueChanged={(data) => {
          setFormData(data)
        }}
      />
      { showOptions &&
        <div style={{ 
          width: '100%', 
          display: 'flex', 
          flexDirection: 'column', 
          justifyContent: 'space-between' }} >
          <div style={{ 
            padding: '10px 0px',
            width: '50%', 
            display: 'flex', 
            justifyContent: 'space-between' }}>
              <strong>Opciones</strong>
              <Button onClick={() => { onAddOption() }} >
                Agregar Opcion
              </Button>
          </div>
          {
            (opciones.map((opcion, index) => (
              <PreguntaOpcion 
                key={`a-${index}`} 
                opcion={opcion} 
                onOpcionChange={(prop, value) => onOpcionChange(index, prop, value)}
              />
            )))
          }
        </div>
      }
    </div>
  );
}

export default function Formulario() {
  
  const RouteParams = useParams();
  const History = useHistory();
  const [TiposPreguntas, setTiposPreguntas] = useState([]);
  const [ModalPregunta, setModalPregunta] = useState(false);
  const [nuevaPreguntaModal, setNuevaPreguntaModal] = useState({
    idtipo: '1',
    pregunta: '',
    comentario: '',
    requerida: true,
    activa: true,
    cancelada: false,
    todo_folio: false,
    opciones: [],
  });
  const [FormData, setFormData] = useState(
    Form.Group([
      Form.Field('selectsearch', 'idevento', 'Evento', 6, { required: true }, [], undefined, (val) => onEventoChange(val)),
      Form.Field('select', 'tipo_valido', 'Tipo', 6, { required: true }, [], '1', (val) => onTipoFormularioChange(val)),
      Form.Field('selectsearch', 'idcalendario', 'Calendario', 12, {}, [], undefined, false, false, false, true),
      Form.Field('selectsearch', 'idproducto', 'Producto', 12, {}, [], undefined, false, false, false, true),
    ], [],)
  );
  const [preguntas, setPreguntas] = useState([]);
  
  useEffect(() => {
    fillForm();
    loadPreds();
  }, []);

  const onTipoFormularioChange = (val) => {
    setFormData(newForm => {
      if (val === '2') {
        newForm = Form.FieldMod(newForm, 'idcalendario', 'Hidden', false);
        newForm = Form.FieldMod(newForm, 'idproducto', 'Hidden', true);
      } else if (val === '3') {
        newForm = Form.FieldMod(newForm, 'idproducto', 'Hidden', false);
        newForm = Form.FieldMod(newForm, 'idcalendario', 'Hidden', true);
      } else {
        newForm = Form.FieldMod(newForm, 'idcalendario', 'Hidden', true);
        newForm = Form.FieldMod(newForm, 'idproducto', 'Hidden', true);
      }
      return newForm
    });
  }

  const onEventoChange = (val) => {
    Params = Form.FillParam(Params, 'idevento', val);
    fillForm(Params);
  }

  const loadPreds = async () => {
    if (RouteParams.id && preguntas.length === 0) {
      try {
        let Res = await Http.get(`formulario/${RouteParams.id}`);
        if (!Res.error) {
          const { data } = Res;
          setFormData(FormData => {
            return { ...Form.PatchPreds({ ...FormData }, data) }
          });
          setPreguntas(preguntas => {
            return [...data.preguntas];
          });
          onEventoChange(data.idevento);
          onTipoFormularioChange(data.tipo_valido);
        }
      } catch (error) {
        console.error(error)
      }
    }
  }

  const fillForm = async (params) => {
    try {
      let Res = await Http.get('formulario/form', params);
      if (!Res.error) {
        let fills = []
        if (Res.data.eventos) {
          fills.push(Form.FOG_Field('idevento', Res.data.eventos, 'idevento', 'nombre'))
        }
        if (Res.data.tipos_preguntas_validas) {
          fills.push(Form.FOG_Field('tipo_valido', Res.data.tipos_preguntas_validas, 'idtipo', 'descripcion'))
        }
        if (Res.data.eventos_calendario) {
          fills.push(Form.FOG_Field('idcalendario', Res.data.eventos_calendario, 'idcalendario', 'descripcion'))
        }
        if (Res.data.productos) {
          fills.push(Form.FOG_Field('idproducto', Res.data.productos, 'idproducto', 'descrip'))
        }
        if (Res.data.tipos_preguntas) {
          setTiposPreguntas((prev) => {
            return [...prev, ...Res.data.tipos_preguntas]
          })
        }
        setFormData(newForm => {
          return Form.FieldOptionsGroup({ ...newForm }, fills)
        }
        )
      }
    } catch (error) {
      console.error(error)
    }
  }
  
  const onSubmit = async () => {
    const vals = {};
    for (const Field of FormData.Fields) {
      vals[Field.Name] = Field.Value;
    }
    vals.preguntas = preguntas;
    try {
      const Resp = await Http.post('formulario/save', vals)
      if (!Resp.error) {
        message.info(`Guardado Correctamente`);
        History.goBack();
      } else {
        message.warn(`Error: ${Resp.message}`);
      }
    } catch (error) {
      console.error(error)
      message.warn(`Error inesperado`);
    }
  }

  const nuevaPregunta = (pregunta) => {
    if (pregunta) {
      setPreguntas([
        ...preguntas,
        pregunta
      ]);
    } else {
      setPreguntas([
        ...preguntas,
        {
          idtipo: '1',
          pregunta: '',
          comentario: '',
          requerida: true,
          activa: true,
          cancelada: false,
          todo_folio: false,
          opciones: []
        }
      ]);
    }
  }

  const onPreguntaChange = (index, prop, value) => {
    setPreguntas(preguntas => {
      let preguntasModified = preguntas;
      preguntasModified[index][prop] = value;
      return preguntasModified;
    })
  }
  
  return (
    <div>
      <BackButton />
      <h2>Formulario</h2>
      <FormComponent data={FormData} onValueChanged={setFormData} />
      <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            margin: "10px 0px",
          }}
        >
          <Button
            type="primary" 
            ghost
            onClick={() => {
              setModalPregunta(true);
            }}
          >
            Nueva Pregunta
          </Button>

          <Button
            type="primary"
            onClick={() => {
              onSubmit();
            }}
          >
            Guardar
          </Button>
        </div>
        <div style={{ width: "100%" }}>
          <Collapse>
            <Panel key="1"
                header={`Preguntas por Boleto (${preguntas.filter((p) => !p.todo_folio).length})`}>
                {preguntas.filter((p) => !p.todo_folio).length > 0 && (
                  <Collapse
                    defaultActiveKey={preguntas
                      .filter((p) => !p.todo_folio)
                      .map((preg, index) => `pregunta-${index}`)}
                  >
                    {preguntas
                      .map((pregunta, index) => 
                        {return !pregunta.todo_folio ? ( 
                          <Panel
                            header={pregunta.pregunta}
                            key={`pregunta-${index}`}
                            forceRender={true}
                          >
                            <Pregunta
                              tiposPreguntas={TiposPreguntas}
                              pregunta={pregunta}
                              onPreguntaChange={(prop, value) =>
                                onPreguntaChange(index, prop, value)
                              }
                            />
                          </Panel>
                        ) : <span></span>}
                      )}
                  </Collapse>
                )}
            </Panel>

            {preguntas.filter((p) => p.todo_folio).length > 0 && (
              <Panel key="2"
                header={`Preguntas por Orden (${preguntas.filter((p) => p.todo_folio).length})`}>
                
                  <Collapse
                    defaultActiveKey={preguntas
                      .filter((p) => p.todo_folio)
                      .map((preg, index) => `pregunta-${index}`)}
                  >
                    {preguntas.map((pregunta, index) => {
                      return pregunta.todo_folio ? (
                        <Panel
                          header={pregunta.pregunta}
                          key={`pregunta-${index}`}
                          forceRender={true}
                        >
                          <Pregunta
                            tiposPreguntas={TiposPreguntas}
                            pregunta={pregunta}
                            onPreguntaChange={(prop, value) =>
                              onPreguntaChange(index, prop, value)
                            }
                          />
                        </Panel>)
                        : 
                        <span></span>}
                    )}
                </Collapse>
              </Panel>
            )}
          </Collapse>
        </div>
      </div>

      <Modal
        title="Nueva Pregunta"
        visible={ModalPregunta}
        onOk={() => {
          nuevaPregunta(nuevaPreguntaModal);
          setModalPregunta(false);
          setNuevaPreguntaModal({
            idtipo: "1",
            pregunta: "",
            comentario: "",
            requerida: true,
            activa: true,
            cancelada: false,
            opciones: [],
          });
        }}
        onCancel={() => {
          setModalPregunta(false);
          setNuevaPreguntaModal({
            idtipo: "1",
            pregunta: "",
            comentario: "",
            requerida: true,
            activa: true,
            cancelada: false,
            opciones: [],
          });
        }}
        width="70%"
        destroyOnClose={true}
      >
        <Pregunta
          tiposPreguntas={TiposPreguntas}
          pregunta={nuevaPreguntaModal}
          onPreguntaChange={(prop, value) =>
            setNuevaPreguntaModal((pregunta) => {
              return {
                ...pregunta,
                [prop]: value,
              };
            })
          }
        />
      </Modal>
    </div>
  );
}
