import React, { useState, useEffect } from 'react'
import { Form, FormComponent } from 'components/Form'
import Http from 'Http';
import BackButton from 'components/BackButton/BackButton';
import PromotorContactos from '../PromotorContactos'
import PromotorContacto from '../PromotorContactos/PromotorContacto'
import { useParams, useHistory } from "react-router-dom";
import { Button, message, Modal } from 'antd';

export default function Promotor() {
  
  const Params = useParams();
  const History = useHistory();
  const [modalVisible, setModalVisible] = useState(false)
  const [idcontacto, setIdcontacto] = useState(true)
  const [loading, setLoading] = useState(true)
  const [contactos, setContactos] = useState([]);
  const [contacto, setContacto] = useState(null);
  const [FormData, setFormData] = useState(
    Form.Group([
      Form.ID('idpromotor'),
      Form.Field('text', 'nombre', 'Nombre', 6, { required: true }),
    ], [], 'promotor')
  );
  
  useEffect(() => {
    setFormData({ ...FormData, Buttons: [] })
  }, []);
  
  const onSubmit = async () => {
    const Values = {}
    Values.contactos = contactos;
    for (const field of FormData.Fields) {
      Values[field.Name] = field.Value;
    }
    setLoading(true);
    try {
      let Res = await Http.post(`promotor/${Params.id}/save`, { ...Values });
      if (!Res.error) {
        message.info(`Guardado`);
        if (!Params.id) {
          History.goBack()
        }
      } else {
        message.warn(`Error: ${Res.message}`);
      }
    } catch (error) {
      message.warn(`Error al guardar`);
    }
    setLoading(false);
    return;
  }
  
  return (
    <div>
      <BackButton />
      <h2>Promotor</h2>
      <div style={{ display: 'flex', flexDirection: 'column' }} >
        <b style={{ marginLeft: 21, fontSize: 18 }} >Reglas de los promotores</b>
        <ul>
          <li>Los promotores deben incluir por lo menos un contacto, con su correo.</li>
          <li>Si no se tiene el teléfono, dejar en blanco.</li>
          <li>Los campos deben escribirse propio, no solo mayúsculas o solo minúsculas.</li>
          <li>No introducir información incompleta.</li>
          <li>No crear promotores duplicados.</li>
        </ul>
      </div>
      <FormComponent
        data={FormData}
        onSubmit={onSubmit}
        onValueChanged={setFormData}
      />
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10, alignItems: 'flex-end' }}>
        <div>
          <h4>Contactos</h4>
        </div>
        <div>
        <Button
          onClick={() => { setModalVisible(true) }}
          type={'primary'}
          style={{ marginTop: 15 }}
        >
          Nuevo Contacto
        </Button>
        </div>
      </div>
      <div className='col-sm-12'>
        <PromotorContactos 
          idpromotor={Params.id ? Params.id : true}
          row={contacto}
          onClickContact={(row) => {
            setIdcontacto(row.idcontacto);
            setModalVisible(true);
          }}
        />
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          onClick={() => { onSubmit() }}
          type={'primary'}
          style={{ marginTop: 15 }}
        >
          Guardar
        </Button>
      </div>
    
      <Modal
        title="Nuevo Contacto"
        visible={modalVisible}
        // onOk={() => setModalProducto({ ...ModalProducto, visible: false })}
        onCancel={() => { 
          setIdcontacto(true);
          setModalVisible(false) 
        }}
        footer={<div />}
        width='50%'
        destroyOnClose={true}
      >
        <PromotorContacto
          idcontacto={idcontacto}
          onSave={(contacto)=> {
            setContactos([...contactos, contacto]) 
            setContacto(contacto);
            setModalVisible(false)
            setIdcontacto(true);
          }}
        />
      </Modal>
    
    </div>
  )
}
