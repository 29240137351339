import React from 'react'
import './Butacas.css'
import { Tooltip, Menu, Dropdown } from 'antd'

export function BaseButacas() {

  return (
    <Butacas />
  )
}

export default function Butacas({ filas = 10, columnas = 10, children, width = '100%', height = '100vh', onClick = () => { } }) {
  // console.log(children)

  // const onMouseMove = (event) => {
  //   // console.log('MOUSE MOVE SVG')
  // }

  const onMouseDown = (e, butaca) => {
    // console.log('CLICK SVG down butaca', butaca)
    document.butacasMouseDown = true;
    // document.mousedown
  }
  const onMouseUp = () => {
    // console.log('UNCLICK SVG')
    document.butacasMouseDown = false;
    // document.mousedown
  }
  const onMouseLeave = () => {
    // console.log('LEAVE SVG')
    // document.butacasMouseDown = false;
  }
  const onSVGClick = () => {
    onClick()
  }


  return (
    <div className='butacas'>
      <svg
        width={width}
        height={height}
        // onMouseMove={onMouseMove}
        onClick={onSVGClick}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onMouseLeave={onMouseLeave}
      >
        {children}
      </svg>
    </div>
  )
}

export const Butaca = ({
  modoVenta = false,
  id,
  selected = false,
  size = 35,
  br = 10,
  fila = 0,
  columna = 0,
  onClick,
  onClickContextMenu,
  onMouseEnter,
  onMouseDown,
  butaca = null,
  muerta = false,
  seccion = null,
  estado,
  butacaRef = null,
  filaValue = null,
  butacaValue = null,
  disabled = false,
}) => {
  let X = (size * columna) + (2 * columna);
  let Y = (size * fila) + (2 * fila);
  // const onMouseEnter = (event) => {
  //   console.log(event)
  // }

  const onClickButaca = (event) => {
    if (!disabled) {
      onClick(event)
    }
  }
  const onMouseEnterButaca = (event) => {
    // console.log('MOUSE ENTER BUTACA', document.mouseDown)
    // if (document.mouseDown)
    //   onClick(event, true)
    // onMouseEnter(event)
  }
  const onMouseDownButaca = (event) => {
    if (!disabled) { 
      onMouseDown(event, butaca);
    }
  }

  const menu = (
    <Menu onClick={onClickContextMenu} >
      <Menu.Item key="1">Agregar Columna Izquierda</Menu.Item>
      <Menu.Item key="2">Agregar Fila Arriba</Menu.Item>
      <Menu.Item key="3">Eliminar esta Columna</Menu.Item>
      <Menu.Item key="4">Eliminar esta Fila</Menu.Item>
    </Menu>
  );
  // console.log(`Butaca en ${fila}, ${columna}`)

  return (
    // <svg height={`${size}`} width={`${size}`} className='butaca'>
    <React.Fragment>
      {/* <Tooltip  trigger={['hover']} title={`butaca: ${butaca || ''}, seccion: ${seccion || ''} `}> */}
        <Dropdown disabled={modoVenta} overlay={menu} trigger={['contextMenu']}>
          <g id="AremaButaca" ref={butacaRef}>
            <path
              className={`butaca 
                ${selected ? 'selected' : ''}
                ${(filaValue || butacaValue) ? 'esButaca' : ''}
                ${estado === 6 ? 'bloqueada' : ''}
                ${estado === 3 ? 'vendida' : ''}
                ${muerta ? 'muerta' : ''}
                estado-${estado}
                `
              }
              onClick={onClickButaca}
              onMouseEnter={onMouseEnterButaca}
              onMouseDown={onMouseDownButaca}
              title='butaca'
              d={`
              M ${X},${Y + br}
              q 0,-${br} ${br},-${br}
              l ${size - (br * 2)},0
              q ${br},0 ${br},${br}
              l 0,${size - (br * 2)}
              q 0,${br} -${br},${br}
              l -${size - (br * 2)},0
              q -${br},0 -${br},-${br}
              z
              `}
            />
            <text x={`${X + 1}`} y={`${Y + 11}`} fontSize="10" fill="black" stroke="black" strokeWidth="0.4" style={{ cursor: "pointer", pointerEvents: "none", userSelect: 'none' }}>
              {butacaValue ? `B:${butacaValue}` : ''}
            </text>
            <text x={`${X + 1}`} y={`${Y + 21}`} fontSize="10" fill="black" stroke="black" strokeWidth="0.4" style={{ cursor: "pointer", pointerEvents: "none", userSelect: 'none' }}>
              {filaValue ? `F:${filaValue}` : ''}
            </text>
            <text x={`${X + 1}`}  y={`${Y + 31}`} fontSize="8" fill="black" stroke="black" strokeWidth="0.4" style={{ cursor: "pointer", pointerEvents: "none", userSelect: 'none' }}>
              {seccion || ''}
            </text>
          </g>
        </Dropdown>
{/*       </Tooltip> */}
    </React.Fragment>
    // </svg>
  )
}

// const Butaca = ({ selected = true }) => {

//   return (
//     <div className={`butaca ${selected ? 'selected' : ''}`}>

//     </div>
//   )
// }
