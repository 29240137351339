import React, { useState, useEffect } from 'react'
import { Form, FormComponent } from 'components/Form'
import { useHistory, useParams } from "react-router-dom";
import Http from 'Http';
import { Select, Button, message } from 'antd';
import './Promociones.css'
import Global from 'Global';
import BackButton from 'components/BackButton/BackButton';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

let Params = [];
export default function Promocion() {

  const RouteParams = useParams();
  const History = useHistory();
  const [FormData, setFormData] = useState(
    Form.Group([
      Form.Field('selectsearch', 'idpropietario', 'Propietario', 12, { required: true }, [], undefined, (val, f) => propChange(val, f)),
      Form.Field('text', 'promocion', 'Promoción', 6, { required: true }),
      Form.Field('text', 'descrip', 'Descripción', 6, { required: false }),
      Form.Field('datetime', 'fec_ini', 'Fecha inicial', 6, { required: true }),
      Form.Field('datetime', 'fec_fin', 'Fecha final', 6, { required: true }),
      Form.Section('Vender los días', 2),
      Form.Field('checkbox', 'domingo', 'Domingo', 3, { required: false }, null, 1),
      Form.Field('checkbox', 'lunes', 'Lunes', 3, { required: false }, null, 1),
      Form.Field('checkbox', 'martes', 'Martes', 3, { required: false }, null, 1),
      Form.Field('checkbox', 'miercoles', 'Miércoles', 3, { required: false }, null, 1),
      Form.Field('checkbox', 'jueves', 'Jueves', 4, { required: false }, null, 1),
      Form.Field('checkbox', 'viernes', 'Viernes', 4, { required: false }, null, 1),
      Form.Field('checkbox', 'sabado', 'Sábado', 4, { required: false }, null, 1),
      Form.Field('radio', 'tipo', 'Aplicable a', 12, { required: true }, [
        { Value: '1', Label: 'Entrada gratis' },
        { Value: '2', Label: 'Porcentaje' }
      ], '1', (val, fields) => tipoChange(val, fields)),
      Form.Field('number', 'porciento', 'Porcentaje de descuento', 12, { required: false }, null, undefined, null, false, null, true),
      Form.Field('number', 'pagan', 'Pagan', 6, { required: false }),
      Form.Field('number', 'entran', 'Entran', 6, { required: false }),
    ], ['Guardar'], 'promocion', false)
  );
  const [ProductosSelect, setProductosSelect] = useState([])
  const [Producto, setProducto] = useState(undefined)
  const [Productos, setProductos] = useState([])

  useEffect(() => {
    propSettings();
    fillForm()
    // eslint-disable-next-line
  }, []);

  const propSettings = () => {
    if (Global.PROP === "BPMEX") {
      Params = Form.FillParam(Params, 'prop', Global.PROP);
      setFormData({ ...Form.FieldMod(FormData, "idpropietario", "Value", Global.PROP) })
    }
  }

  const fillForm = async () => {
    try {
      let Res = await Http.get('promocion/form', Params);
      if (!Res.error) {
        console.log(Res.data)
        setProductosSelect(Res.data.productos);
        setFormData(Form.FieldOptions({ ...FormData }, 'idpropietario', Res.data.propietarios, 'idpropietario', 'descripcion'))
        fillProductos();
      }
    } catch (error) {

    }
  }

  const fillProductos = async () => {
    if (!RouteParams.id) return;
    try {
      let Res = await Http.get(`promocion/${RouteParams.id}/productos`);
      if (!Res.error) {
        setProductos(Res.data);
      }
    } catch (error) {

    }
  }

  const tipoChange = (val, fields) => {
    let newForm = { ...FormData, Fields: fields };
    if (val === '2') {
      newForm = Form.FieldMod(newForm, 'porciento', 'Hidden', false)
      newForm = Form.FieldMod(newForm, 'pagan', 'Hidden', true)
      newForm = Form.FieldMod(newForm, 'entran', 'Hidden', true)
    }
    else if (val === '1') {
      newForm = Form.FieldMod(newForm, 'porciento', 'Hidden', true)
      newForm = Form.FieldMod(newForm, 'pagan', 'Hidden', false)
      newForm = Form.FieldMod(newForm, 'entran', 'Hidden', false)
    }
    setFormData({ ...newForm });
  }

  const propChange = (val, fields) => {
    Params = Form.FillParam(Params, 'prop', val);
    fillForm();
  }

  const onAddProducto = () => {
    if (!Producto) return;
    console.log(Producto)
    let Prod = ProductosSelect.find((prod) => prod.idproducto === Producto);
    if (!Prod) return;
    let ProdEn = Productos.find(prod => !prod.deleted && prod.idproducto === Producto);
    if (ProdEn) {
      // if (!ProdEn.deleted)
      return message.warn('Ese producto ya está')
      // Prod.deleted = false;
    }
    Productos.push({ ...Prod })
    setProductos([...Productos])
  }

  const onSubmit = async (Values, Button) => {
    let Req = { ...Values, productos: Productos, id: RouteParams.id }
    console.log(`[Submit] Botón ${Button}, valores: `, Req)

    try {
      let Res = await Http.post(`promocion/save`, { ...Req });
      console.log(Res.data)
      if (!Res.error) {
        message.info(`Guardado`);

        if (!RouteParams.id) {
          History.goBack()
        }
      }
      else {
        message.warn(`Error: ${Res.message}`);
      }
    } catch (error) {
      message.warn(`Error al guardar`);
    }

  }
  const onDeleteProducto = (Pi) => {
    Productos[Pi].deleted = true;
    setProductos([...Productos]);
  }

  return (
    <div>
      <BackButton />
      <h2>Promoción</h2>
      <FormComponent
        data={FormData}
        onSubmit={onSubmit}
        onValueChanged={setFormData}
      >
        <div>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Select value={Producto} onChange={(val) => setProducto(val)} style={{ width: '200px' }}>
              {
                ProductosSelect.map((Prod, Pi) => (
                  <Select.Option key={Pi} value={Prod.idproducto}>{Prod.producto}</Select.Option>
                ))
              }
            </Select>
            <Button icon={<PlusOutlined />} shape='round' onClick={onAddProducto}>Producto</Button>
          </div>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {
              Productos.map((Prod, Pi) => (!Prod.deleted &&
                <ProductoComp
                  key={Pi}
                  producto={Prod}
                  onDelete={() => onDeleteProducto(Pi)}
                />
              ))
            }
          </div>
        </div>
      </FormComponent>
    </div>
  )
}

const ProductoComp = ({ producto, onDelete }) => {

  return (
    <div className='producto-comp'>
      <div>{producto.producto}</div>
      <Button size='small' onClick={onDelete} shape='circle' icon={<DeleteOutlined />} />
    </div>
  )
}
