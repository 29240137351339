import React, { useState, useEffect } from 'react'
import { Form, FormComponent } from 'components/Form'
import { useHistory, useParams } from "react-router-dom";
import Http from 'Http';
import BackButton from 'components/BackButton/BackButton';
import { message } from 'antd'
import Global from 'Global';

export default function Evento({ onSave = null, idpropietario = null, goBack = true }) {
  const Params = useParams();
  const History = useHistory();
  const [FormInfo, setFormData] = useState(
    Form.Group([
      Form.ID('idevento'),
      Form.Field('selectsearch', 'idpropietario', 'Propietario', 12, { required: true }),
      Global.SHOW_PROMOTORES ?
        Form.Field('selectsearch', 'idpromotor', 'Promotor', 12, { required: true })
        : Form.Field('hidden', '', '')
      ,
      Form.Field('text', 'nombre_display', 'Nombre Display', 12, { required: true, maxLength: 64 }, [], ' '),
      Form.Field('text', 'nombre', 'Nombre', 12, { required: true, maxLength: 40 }),
      Form.Field('text', 'nombre2', 'Nombre del Evento 2', 12, { required: true, maxLength: 40 }, [], ' '),
      Form.Field('text', 'nombre3', 'Nombre del Evento 3', 12, { required: true, maxLength: 40 }, [], ' '),
      Form.Field('text', 'comentario', 'Comentario', 8, { required: true, maxLength: 40 }, [], ' '),
      Form.Field('checkbox', 'card_secure', 'Card secure', 4, { required: true }, [], 1, null, false),
      Form.Field('select', 'tipo_evento', 'Tipo de evento', 12, { required: true }),
      Form.Field('number', 'retorno', 'Retorno', 12, { required: true, min: 0, max: 1 }, [], 0),
      Form.Field('trumbowyg', 'sinopsis', 'Subir sinopsis', 12, { required: false }),
      Form.Field('file', 'poster', 'Subir poster', 6, { required: false }),
      Form.Field('image', 'poster_img', 'Poster', 6, { required: false }),
      Form.Field('file', 'mapa', 'Subir Mapa', 6, { required: false }),
      Form.Field('image', 'mapa_img', 'Mapa', 6, { required: false }),
    ], ['Guardar'], 'evento', false)
  );

  const onFileChange = (file, field) => {
    if (file === 'deleted') {
      let newForm = Form.FieldMod(FormInfo, field, 'Value', undefined);
      setFormData({ ...newForm });
    } else if (file) { 
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let newForm = Form.FieldMod(FormInfo, field, 'Value', reader.result);
        setFormData(newForm);
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    } else {
      let newForm = Form.FieldMod(FormInfo, field, 'Value', undefined);
      setFormData(newForm);
    }
  }

  useEffect(() => {
    fillForm();
    // eslint-disable-next-line
  }, []);

  const fillForm = async () => {
    let newFD = FormInfo;
    try {
      let Res = await Http.get('evento/form');
      if (!Res.error) {
        if (Res.data.propietarios) {
          newFD = Form.FieldOptions({ ...newFD }, 'idpropietario', Res.data.propietarios, 'idpropietario', 'descripcion');
        } 
        if (Res.data.promotores) {
          newFD = Form.FieldOptions({ ...newFD }, 'idpromotor', Res.data.promotores, 'idpromotor', 'nombre');
        }
        if (Res.data.tipos_evento) {
          newFD = Form.FieldOptions({ ...newFD }, 'tipo_evento', Res.data.tipos_evento, 'idtipo', 'descripcion');
        }
      }
    } catch (error) {
      console.error(error)
    }
    newFD = Form.FieldMod(newFD, 'poster', 'Watcher', (file) => {onFileChange(file, 'poster_img')})
    newFD = Form.FieldMod(newFD, 'mapa', 'Watcher', (file) => {onFileChange(file, 'mapa_img')})
    if (idpropietario) {
      setFormData(Form.Patch(newFD, { idpropietario }))
    } else {
      setFormData(newFD)
    }
  }

  const onSubmit = (Values, Button) => {
    // console.log(`[Submit] Botón ${Button}, valores: `, Values);
    saveProp(Values);
  }

  const saveProp = async (vals) => {
    console.log(vals)
    let postData = new FormData();
    postData.append('idpropietario', vals.idpropietario);
    if (vals.idpromotor) {
      postData.append('idpromotor', vals.idpromotor);
    }
    postData.append('nombre_display', vals.nombre_display);
    postData.append('nombre', vals.nombre);
    postData.append('nombre2', vals.nombre2);
    postData.append('nombre3', vals.nombre3);
    postData.append('comentario', vals.comentario);
    postData.append('card_secure', vals.card_secure);
    postData.append('tipo_evento', vals.tipo_evento);
    postData.append('sinopsis', vals.sinopsis);
    postData.append('poster', vals.poster);
    postData.append('mapa', vals.mapa);
    postData.append('retorno', vals.retorno);
    try {
      let Res = await Http.post(`evento${Params.id ? `/${Params.id}` : ''}/save`, postData);
      if (!Res.error) {
        message.info(`Guardado`);
        if (onSave) {
          onSave(true, Res.data);
          return;
        }
        if (!vals.idevento) {
          History.goBack();
        }
      }
      else {
        message.warn(`Error: ${Res.message}`);
        if (onSave) {
          onSave(false, Res.errorMsg);
        }
      }
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <div>
      {goBack && <BackButton />}
      <h2>Evento</h2>
      <FormComponent
        data={FormInfo}
        onSubmit={onSubmit}
        onValueChanged={setFormData}
        // onSave={onSave}
      />
    </div>
  )
}
