import React, { useState, useEffect } from 'react'
import { Form, FormComponent } from 'components/Form'
import { Button, Modal, message } from 'antd';
import EventoForm from 'components/Catalogos/Eventos/Evento';
import Recinto from 'components/Catalogos/Recintos/Recinto';
import Global from 'Global';
import { PlusCircleOutlined } from '@ant-design/icons';
import moment from 'moment';

export default function Evento({ onNext, data = {}, fill = {}, refill, onData = () => { }, loading = false, saveButton = false }) {

  const botones = saveButton ?  ['Siguiente', 'Guardar'] : ['Siguiente'];
  const [ModalEvento, setModalEvento] = useState(false)
  const [ModalRecinto, setModalRecinto] = useState(false)
  const [FormData, setFormData] = useState(
    Form.Group([
      Form.Field('selectsearch', 'idrecinto', 'Recinto', 6, { required: true }),
      Form.Field('selectsearch', 'idevento', 'Evento', 6, { required: true }),
      Form.Field('select', 'categoria', 'Categoría', 6, { required: true }),
      Form.Field('selectsearch', 'ciudad', 'Ciudad', 6, { required: true }),
      Form.Field('date', 'fecha', 'Fecha', 8, { required: true }),
      Form.Field('time', 'hora', 'Hora', 4, { required: true }),
      Form.Field('number', 'numevento', 'No. Evento', 2, { required: false }),
      Form.Field('datetime', 'fec_ini_vta', 'Vigencia inicio', 5, { required: true }),
      Form.Field('datetime', 'fec_fin_vta', 'Vigencia fin', 5, { required: true }),
      Form.Field('checkbox', 'activo', 'Activo', 2, { required: false }),
      Form.Field('datetime', 'puerta_ini', 'Apertura de puertas', 5, { required: true }),
      Form.Field('datetime', 'puerta_fin', 'Cierre de puertas', 5, { required: true }),
      Form.Field('file', 'mapa', 'Subir Mapa', 6, { required: false }),
      Form.Field('image', 'mapa_img', 'Mapa', 6, { required: false }),
    ], botones, null, false)
  );

  useEffect(() => {
    let newForm = FormData;
    if (data) {
      newForm = Form.Patch({ ...newForm }, { ...data });
      setFormData(newForm);
    }
    newForm = Form.FieldMod({ ...newForm }, 'mapa', 'Watcher', (file) => {onFileChange(file, 'mapa_img')})
    if (fill) {
      console.log('fill', fill)
      setFormData(
        Form.FieldOptionsGroup({ ...newForm }, [
          Form.FOG_Field('categoria', fill.categorias, 'categoria', 'categoria'),
          Form.FOG_Field('ciudad', fill.ciudades, 'ciudad', 'ciudad'),
          Form.FOG_Field('idevento', fill.eventos, 'idevento', 'nombre'),
          Form.FOG_Field('idrecinto', fill.recintos, 'idrecinto', 'nombre'),
        ])
      )
    }
    propSettings();
    // eslint-disable-next-line
  }, [fill]);

  const onFileChange = (file, field) => {
    if (file) { 
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = ()  => {
        let newForm = Form.FieldMod(FormData, field, 'Value', reader.result);
        newForm = Form.FieldMod(FormData, 'mapa', 'Value', reader.result);
        setFormData(newForm);
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    } else {
      let newForm = Form.FieldMod(FormData, field, 'Value', null);
      setFormData(newForm);
    }
  }

  const propSettings = () => {
    if (Global.PROP === "BPMEX") {
      setFormData({
        ...Form.FieldModGroup(FormData, [
          Form.FMG_Field("numevento", "Disabled", true),
          Form.FMG_Field("categoria", "Value", "Especiales"),
          Form.FMG_Field("ciudad", "Value", "Estado de México"),
        ])
      })
    }
  }

  const onSubmit = (Values, Button) => {
    console.log(`[Submit] Botón ${Button}, valores: `, Values)
    delete Values.mapa_img;
    if (moment(Values.fec_fin_vta).isBefore(moment(Values.fec_ini_vta))) {
      message.error("El campo 'Vigencia fin' debe tener una fecha mayor al campo 'Vigencia inicio'")
      return;
    }
    if (moment(Values.puerta_fin).isBefore(moment(Values.puerta_ini))) {
      message.error("El campo 'Cierre de puertas' debe tener una fecha mayor al campo 'Apertura de puertas'")
      return;
    }
    onNext(Values, Button === 2);
  }

  const addEvento = () => {
    setModalEvento(true)
  }
  const onAddEvento = (saved, data) => {
    setModalEvento(false);
    console.log(saved, data)
    if (saved) {
      refill()
      message.info('El evento fue añadido a la lista')
      setFormData(Form.Patch({ ...FormData }, { idevento: data.idevento.toString() }))
    }
  }

  const addRecinto = () => {
    setModalRecinto(true)
  }
  const onAddRecinto = (saved, data) => {
    setModalRecinto(false);
    console.log(saved, data)
    if (saved) {
      refill()
      message.info('El recinto fue añadido a la lista')
      setFormData(Form.Patch({ ...FormData }, { idrecinto: data.idevento.toString() }))
    }
  }

  const onValueChange = (value) => {
    const values = Form.Values(value);
    setFormData(value)
    onData(values);
  }

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginBottom: 15 }}>
        <Button shape='round' icon={<PlusCircleOutlined />} onClick={addRecinto}>Recinto</Button>
        <Button shape='round' icon={<PlusCircleOutlined />} onClick={addEvento}>Evento</Button>
      </div>

      <FormComponent
        data={FormData}
        onSubmit={onSubmit}
        onValueChanged={onValueChange}
        loading={loading}
      />

      <Modal
        title="Agregar evento"
        visible={ModalEvento}
        onOk={() => setModalEvento(false)}
        onCancel={() => setModalEvento(false)}
        footer={<div />}
        destroyOnClose={true}
      >
        <EventoForm goBack={false} onSave={onAddEvento} idpropietario={data.idpropietario || null} />
      </Modal>

      <Modal
        title="Agregar recinto"
        visible={ModalRecinto}
        onOk={() => setModalRecinto(false)}
        onCancel={() => setModalRecinto(false)}
        footer={<div />}
        destroyOnClose={true}
      >
        <Recinto goBack={false} onSave={onAddRecinto} idpropietario={data.idpropietario || null} />
      </Modal>
    </div>
  )
}
