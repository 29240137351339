import React, { useState, useEffect } from 'react'
import { Form, FormComponent } from 'components/Form'
import Http from 'Http';
import moment from 'moment';
import { DynamicForm } from 'components/Form/Form';
import { Button, message, Spin } from 'antd';
import './Evento.css'
import Theme from 'Theme';
import loadashClone from 'lodash.clonedeep';
import { randomStringId } from 'utils/randomStringId';


const FechaObj = (fecha, hora, fec_ini_vta, fec_fin_vta, puerta_ini, puerta_fin, fec_ini_vta_web, fec_fin_vta_web) => {
  // fecha = moment.utc(fecha, 'YYYY-MM-DD[T]HH:mm:ss[.]SSS[Z]');
  fecha = new Date(moment(fecha).utc().format('YYYY/MM/DD HH:mm'));
  hora = new Date(moment(hora).utc().format('YYYY/MM/DD HH:mm'));
  fec_ini_vta = new Date(moment(fec_ini_vta).utc().format('YYYY/MM/DD HH:mm'));
  fec_fin_vta = new Date(moment(fec_fin_vta).utc().format('YYYY/MM/DD HH:mm'));
  puerta_ini = new Date(moment(puerta_ini ? puerta_ini : '').utc().format('YYYY/MM/DD HH:mm'));
  puerta_fin = new Date(moment(puerta_fin ? puerta_fin : '').utc().format('YYYY/MM/DD HH:mm'));
  fec_ini_vta_web = new Date(moment(fec_ini_vta_web || moment()).utc().format('YYYY/MM/DD HH:mm'));
  fec_fin_vta_web = new Date(moment(fec_fin_vta_web || moment().add(1, 'd')).utc().format('YYYY/MM/DD HH:mm'));
  return {
    fecha, hora, fec_ini_vta, fec_fin_vta, puerta_ini, puerta_fin, fec_ini_vta_web, fec_fin_vta_web
  }
}

// const FechaObjWOM = ({ fecha, hora, fec_ini_vta, fec_fin_vta, puerta_ini, puerta_fin, fec_ini_vta_web, fec_fin_vta_web }) => {
//   return {
//     fecha, hora, fec_ini_vta, fec_fin_vta, puerta_ini, puerta_fin, fec_ini_vta_web, fec_fin_vta_web
//   }
// }

const FechaObjWOMF = (fecha, hora, fec_ini_vta, fec_fin_vta, puerta_ini, puerta_fin, fec_ini_vta_web, fec_fin_vta_web) => {
  return {
    fecha, hora, fec_ini_vta, fec_fin_vta, puerta_ini, puerta_fin, fec_ini_vta_web, fec_fin_vta_web
  }
}

let FechaBase = {};
export default function Multiplicar({ calendario = null, onMultiplicada }) {

  const [FormData, setFormData] = useState(
    Form.Group([
      Form.Field('checkbox', 'avanzado', 'Configurar nueva fecha', 12, { required: true }, null, false, (val, fields) => avanzadoChange(val, fields)),
      // Form.Field('number', 'cantidad', 'Aumento', 2, { required: false }, [], 1, null, false, null, true),
      // Form.Field('select', 'que', 'Aumentar', 4, { required: false }, [
      //   { Value: 1, Label: 'Minuto(s)' },
      //   { Value: 2, Label: 'Hora(s)' },
      //   { Value: 3, Label: 'Día(s)' },
      // ], 2, null, false, null, true),
      Form.Field('number', 'dias', '+ días', 2, { required: true }, null, 0, null, false, null, true),
      Form.Field('number', 'horas', '+ horas', 2, { required: true }, null, 1, null, false, null, true),
      Form.Field('number', 'minutos', '+ minutos', 2, { required: true }, null, 0, null, false, null, true),
      Form.Field('select', 'desde', 'Desde', 6, { required: false }, [
        { Value: 1, Label: 'Fecha inicial' },
        { Value: 2, Label: 'Última fecha añadida' },
        { Value: 3, Label: 'Ahora mismo' },
      ], 1, null, false, null, true),
      Form.Field('selectm', 'en', 'En', 12, { required: false }, [
        { Value: 1, Label: 'Fecha' },
        { Value: 2, Label: 'Vigencia inicio' },
        { Value: 3, Label: 'Vigencia fin' },
        { Value: 4, Label: 'Apertura' },
        { Value: 5, Label: 'Cierre' },
        { Value: 6, Label: 'Vigencia inicio (internet)' },
        { Value: 7, Label: 'Vigencia fin (internet)' },
      ], [1, 2, 3, 4, 5, 6, 7], null, false, null, true),
      Form.Field('number', 'repeticion', 'Veces que se seguirá la configuración', 12, { required: true }, null, 1, null, false, null, true),
    ], ['Añadir fecha'], null, false)
  );
  const [Fechas, setFechas] = useState([
    // FechaObj(moment(), moment(), )
  ])
  const [Loading, setLoading] = useState(false)
  const [LoadingFechas, setLoadingFechas] = useState(false)

  useEffect(() => {
    loadFecha()
    // eslint-disable-next-line
  }, []);

  const loadFecha = async () => {
    try {
      let Res = await Http.get(`calendario/${calendario}/fecha`);
      if (!Res.error) {
        const { fecha, hora, fec_ini_vta, fec_fin_vta, puerta_ini, puerta_fin, fec_ini_vta_web, fec_fin_vta_web } = Res.data;
        FechaBase = FechaObj(fecha, hora, fec_ini_vta, fec_fin_vta, puerta_ini, puerta_fin, fec_ini_vta_web, fec_fin_vta_web);
        // setFechas([
        //   FechaObj(fecha, hour, fec_ini_vta, fec_fin_vta, puerta_ini, puerta_fin, fec_ini_vta_web, fec_fin_vta_web)
        // ]
        // )
      }
    } catch (error) {

    }
  }

  const avanzadoChange = (val, fields) => {
    // console.log(val, fields)
    let newFG = Form.FieldMod({ ...FormData, Fields: fields }, 'dias', 'Hidden', !val);
    newFG = Form.FieldMod({ ...newFG, Fields: fields }, 'horas', 'Hidden', !val);
    newFG = Form.FieldMod({ ...newFG, Fields: fields }, 'minutos', 'Hidden', !val);
    newFG = Form.FieldMod({ ...newFG, Fields: fields }, 'en', 'Hidden', !val);
    newFG = Form.FieldMod({ ...newFG, Fields: fields }, 'desde', 'Hidden', !val);
    newFG = Form.FieldMod({ ...newFG, Fields: fields }, 'repeticion', 'Hidden', !val);
    setFormData({ ...newFG })
  }

  const onSubmit = (Values) => {
    // let Values = Form.Values(FormData);
    // console.log(`[Submit] Valores: `, Values, FechaBase.fecha.format('DD-MM-YYYY HH:mm'))
    // console.log(`FECHA BASE ES ${FechaBase.fecha.format('DD-MM-YYYY HH:mm')}`)
    // save(Values);
    if (!Values.avanzado) {
      addFecha();
      return;
    }

    let fechas = loadashClone(Fechas);
    // let fechas = []
    setLoadingFechas(true)
    for (let i = 0; i < Values.repeticion; i++) {
      fechas.push(calcularFecha(Values, fechas))
    }
    // console.log(fechas)
    addFechas(fechas);
    setLoadingFechas(false)
  }

  const calcularFecha = ({ dias, horas, minutos, desde, en }, fechas = null) => {
    // const { dias, horas, minutos, desde, en } = Values;
    let desdeFecha;
    let nuevaFecha = {}; 
    let fechaProps = ['fecha', 'fec_ini_vta', 'fec_fin_vta', 'puerta_ini', 'puerta_fin', 'fec_ini_vta_web', 'fec_fin_vta_web'];

    // if (desde === 1) desdeFecha = Object.assign({}, FechaBase);
    if (desde === 1) {
      desdeFecha = loadashClone(FechaBase)
    } else if (desde === 2) {
      if (!fechas) {
        // console.log('fechass 1')
        if (Fechas.length === 0) {
          message.warn('Aún no se añade una fecha. Se tomó de referencia la fecha inicial');
          desdeFecha = loadashClone(FechaBase)
        } else {
          desdeFecha = loadashClone({ ...Fechas[Fechas.length - 1] })
        }
      } else {
        // console.log('fechass')
        if (fechas.length === 0) {
          message.warn('Aún no se añade una fecha. Se tomó de referencia la fecha inicial');
          desdeFecha = loadashClone(FechaBase)
        } else {
          desdeFecha = loadashClone({ ...fechas[fechas.length - 1] })
        }
      }
    } else if (desde === 3) {
      desdeFecha = FechaObjWOMF(moment.utc().add(moment().utcOffset(), 'm'), 
        moment.utc().add(moment().utcOffset(), 'm'), 
        moment.utc().add(moment().utcOffset(), 'm'), 
        moment.utc().add(moment().utcOffset(), 'm'), 
        moment.utc().add(moment().utcOffset(), 'm'), 
        moment.utc().add(moment().utcOffset(), 'm'), 
        moment.utc().add(moment().utcOffset(), 'm'), 
        moment.utc().add(moment().utcOffset(), 'm')
      );
    }
    // console.log(desdeFecha)

    fechaProps.forEach((prop, pi) => {
      if (!en.includes(pi + 1)) {
        nuevaFecha[prop] = desdeFecha[prop];
        if (prop === 'fecha') nuevaFecha['hora'] = desdeFecha['hora'];
        // console.log(`La prop ${prop} no está en selección, se deja la fecha igual (${nuevaFecha[prop].format('DD-MM-YYYY HH:mm')}).`)
        return;
      }
      nuevaFecha[prop] = moment(desdeFecha[prop]).add({ days: dias, hours: horas, minutes: minutos }).toDate();
      if (prop === 'fecha') {
        nuevaFecha['hora'] = moment(desdeFecha['hora']).add({ days: dias, hours: horas, minutes: minutos }).toDate();
      }
      // if (prop === 'fecha') nuevaFecha['hora'] = desdeFecha['hora'];
      // if (prop === 'fecha') nuevaFecha['hora'] = desdeFecha['fecha'];
      // console.log(`La prop ${prop} se aumenta: ${cantidad} ${aumento} (${nuevaFecha[prop].format('DD-MM-YYYY HH:mm')})`)
    })

    // console.log(aumento, desdeFecha, nuevaFecha)
    if (!fechas)
      addFecha(nuevaFecha)
    else
      return nuevaFecha;
  }

  const addFecha = ({ fecha = null, hora, fec_ini_vta, fec_fin_vta, puerta_ini, puerta_fin, fec_ini_vta_web, fec_fin_vta_web } = {}) => {
    setFechas((prevFechas) => {
      let baseParams = { id: randomStringId() };
      if (!fecha) {
        prevFechas.push({ ...FechaBase, ...baseParams });
        return [...prevFechas]
      }
      prevFechas.push({ fecha, hora, fec_ini_vta, fec_fin_vta, puerta_ini, puerta_fin, fec_ini_vta_web, fec_fin_vta_web, ...baseParams });
      return [...prevFechas]
    })
  }

  const addFechas = (fechas = []) => {
    setFechas((prevFechas) => {
      // fechas.forEach(fechaInfo => {
      //   const { fecha, hora, fec_ini_vta, fec_fin_vta, puerta_ini, puerta_fin, fec_ini_vta_web, fec_fin_vta_web } = fechaInfo;
      //   prevFechas.push({ fecha, hora, fec_ini_vta, fec_fin_vta, puerta_ini, puerta_fin, fec_ini_vta_web, fec_fin_vta_web });
      // })
      fechas = fechas.map(fecha => ({ ...fecha, id: randomStringId() }));
      prevFechas = fechas;
      return [...prevFechas]
    })
  }

  const onMultiplicar = async () => {
    let mFechas = loadashClone(Fechas);
    // console.log(mFechas)
    let Values = mFechas.map((fecha) => {
      for (let prop in fecha) {
        if (typeof fecha[prop] !== 'boolean') {
          fecha[prop] = moment(fecha[prop]).format('YYYY/MM/DD HH:mm');
        }
      }
      return fecha;
    })
    save(Values)
  }

  const save = async (vals) => {
    try {
      setLoading(true);
      let Res = await Http.post(`calendario/${calendario}/multiplicar`, { Fechas: vals });
      console.log(Res.data)
      if (!Res.error) {
        onMultiplicada(Res.data)
      }
      else {
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      message.error('Error al multiplicar fechas')
      console.log(Error)
    }
  }

  const onFechaChange = (fi, values) => {
    // console.log(fi, values)  
    // setFechas([...values]); 
    setFechas(prevFechas => {
      // prevFechas[fi] = FechaObjWOM({ ...values });
      prevFechas[fi] = { ...prevFechas[fi], ...values };
      // console.log(prevFechas[fi])
      return [...prevFechas];
    })
  }
  const onFechaDelete = (fi) => {
    console.log(`Delete fecha ${fi}`);
    setFechas(prevFechas => {
      prevFechas = prevFechas.filter((p, i) => i !== fi);
      console.log(prevFechas)
      return [...prevFechas];
    })
  }

  return (
    <div className='multiplicar'>
      <h2>Multiplicar</h2>
      {/* Los campos de fechas y eso deben ser dinámicos, dependiendo del número de veces */}
      <FormComponent
        data={FormData}
        onSubmit={onSubmit}
        onValueChanged={setFormData}
      />
      <div className='fechas'>
        {
          LoadingFechas && (
            <div style={{ textAlign: 'center' }}>
              <Spin />
            </div>
          )
        }
        {
          Fechas.map((Fecha, Fi) => (
            <FechaForm
              key={`fecha-${Fecha.id}`}
              num={Fi + 1}
              fecha={Fecha}
              onChange={(vals) => onFechaChange(Fi, vals)}
              onDelete={(vals) => onFechaDelete(Fi)}
            />
            // <div></div>
          ))
        }
      </div>
      {
        Fechas.length > 0 &&
        <div style={{ textAlign: 'right' }}>
          <Button onClick={() => onMultiplicar(Fechas)} type='primary' disabled={Loading}>Multiplicar</Button>
        </div>
      }
    </div>
  )
}

const FechaForm = ({ onChange, onDelete, fecha: { fecha, hora, fec_ini_vta, fec_fin_vta, puerta_ini, puerta_fin, fec_ini_vta_web, fec_fin_vta_web, pinternet }, num = 0 }) => {
  const [Fields] = useState([
    Form.Field('date', 'fecha', 'Fecha', 8, { required: true }, null, fecha),
    Form.Field('hour', 'hora', 'Hora', 4, { required: true }, null, hora),
    Form.Field('datetime', 'fec_ini_vta', 'Vigencia inicio', 6, { required: true }, null, fec_ini_vta),
    Form.Field('datetime', 'fec_fin_vta', 'Vigencia fin', 6, { required: true }, null, fec_fin_vta),
    Form.Field('datetime', 'puerta_ini', 'Apertura de puertas', 6, { required: true }, null, puerta_ini),
    Form.Field('datetime', 'puerta_fin', 'Cierre de puertas', 6, { required: true }, null, puerta_fin),
    Form.Field('datetime', 'fec_ini_vta_web', 'Vigencia inicio (internet)', 6, { required: false }, null, fec_ini_vta_web),
    Form.Field('datetime', 'fec_fin_vta_web', 'Vigencia fin (internet)', 6, { required: false }, null, fec_fin_vta_web),
    Form.Field('checkbox', 'pinternet', 'Publicar', 12, { required: false }, null, false),
  ])
  const fieldsChange = (fields) => {
    // let Values = Form.Values({ Fields: fields });
    // let Values = Fields.map((Field) => Field.Value)
    let Values = {}
    fields.forEach((field) => {
      Values[field.Name] = field.Value;
    })
    onChange(Values);
    // setFields(fields);
  }
  const onSubmit = (Values, Submit) => {
    console.log(Values)
  }
  return (
    <div className='fecha' style={{ borderColor: Theme.primary }}>
      <DynamicForm
        fields={Fields}
        onSubmit={onSubmit}
        onFieldsChange={fieldsChange}
        label={true}
        goBack={false}
      />
      <span onClick={onDelete} className='numero' style={{ color: '#fff', backgroundColor: Theme.primary }}>x</span>
    </div>
  )
}
