import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from "react-router-dom";
import { Form, FormComponent } from 'components/Form'
import Http from 'Http';
import { message } from 'antd';
import BackButton from 'components/BackButton/BackButton';

export default function Producto({ onSave = null, defaultValues = null }) {

  const Params = useParams();
  const History = useHistory();
  const [FormData, setFormData] = useState(
    Form.Group([
      Form.Field('selectsearch', 'idpropietario', 'Propietario', 12, { required: true }),
      Form.Field('text', 'producto', 'Producto', 6, { required: true, maxLength: 20 }),
      Form.Field('text', 'descrip', 'Descripción', 6, { required: true, maxLength: 40 }),
      Form.Field('date', 'fec_ini', 'Fecha inicial', 6, { required: true }),
      Form.Field('date', 'fec_fin', 'Fecha final', 6, { required: true }),
      Form.Field('text', 'formato', 'Formato', 4, { required: true, maxLength: 20 }),
      Form.Field('radio', 'tipoformato', 'RPG', 4, { required: true }, [
        { Value: "1", Label: 'Usar PRG' },
        { Value: "2", Label: 'Usar Script' }
      ]),
      Form.Field('number', 'pases', 'Pases', 4, { required: true, min: 1 }, [], 1),
      /* Form.Field('number', 'mincantventa', 'Min. Cantidad Venta', 6, { min: 1 }, [], 1),
      Form.Field('number', 'maxcantventa', 'Max. Cantidad Venta', 6, { max: 10 }, [], 10), */
      Form.Section('Vender los días', 2),
      Form.Field('checkbox', 'domingo', 'Domingo', 3, { required: false }, null, 1),
      Form.Field('checkbox', 'lunes', 'Lunes', 3, { required: false }, null, 1),
      Form.Field('checkbox', 'martes', 'Martes', 3, { required: false }, null, 1),
      Form.Field('checkbox', 'miercoles', 'Miércoles', 3, { required: false }, null, 1),
      Form.Field('checkbox', 'jueves', 'Jueves', 4, { required: false }, null, 1),
      Form.Field('checkbox', 'viernes', 'Viernes', 4, { required: false }, null, 1),
      Form.Field('checkbox', 'sabado', 'Sábado', 4, { required: false }, null, 1),
      Form.Field('textarea', 'comentario1', 'Comentario 1', 12, { required: false, maxLength: 40 }),
      Form.Field('textarea', 'comentario2', 'Comentario 2', 12, { required: false, maxLength: 40 }),
      Form.Field('textarea', 'comentario3', 'Comentario 3', 12, { required: false, maxLength: 40 }),
      Form.Field('color', 'cfondo', 'Color fondo', 6, { required: false }),
      Form.Field('color', 'cletra', 'Color letra', 6, { required: false }),
      Form.Field('number', 'nivel', 'Nivel', 2, { required: true }),
      Form.Field('text', 'folio_producto', 'Folio de producto', 4, { required: true }),
      Form.Field('checkbox', 'pidedescrip', 'Solicitar descripción', 2, { required: false }),
      Form.Field('checkbox', 'ventaweb', 'Vender en Internet', 2, { required: false }),
      Form.Field('checkbox', 'nomostraraforo', 'No mostrar aforo', 2, { required: false }),
      /* Form.Field('checkbox', 'afecta_aforo', 'Afecta aforo', 2, { required: false }, [], true, null, false, null, false, 'Usar en casos especiales'), */
      Form.Field('number', 'multiplo', 'Múltiplo', 12, { required: false }),
    ], ['Guardar'], 'producto', false)
  );

  useEffect(() => {
    fillForm();
    if (defaultValues) {
      console.log(defaultValues)
      setFormData(Form.Patch({ ...FormData }, { ...defaultValues }))
    }
    // eslint-disable-next-line
  }, []);

  const fillForm = async () => {
    try {
      let Res = await Http.get('productor/form');
      if (!Res.error) {
        setFormData(Form.FieldOptions({ ...FormData }, 'idpropietario', Res.data.propietarios, 'idpropietario', 'descripcion'))
      }
    } catch (error) {

    }
  }

  const onSubmit = async (Values, Button) => {
    console.log(`[Submit] Botón ${Button}, valores: `, Values)
    try {
      let Res = await Http.post('producto/save/colors', { ...Values, id: Params.id });
      console.log(Res)
      if (!Res.error) {
        message.info(`Guardado`);
        if (onSave) {
          onSave(true, Res.data);
          return;
        }
        History.goBack()
      }
      else {
        message.warn(`Error: ${Res.message}`);
      }
    } catch (error) {
      message.warn(`Error al guardar`);
      console.log(`Error al guardar (onSubmit) para Producto: `, error)
    }
  }

  return (
    <div>
      <BackButton />
      <h2>Producto</h2>
      <FormComponent
        data={FormData}
        onSubmit={onSubmit}
        onValueChanged={setFormData}
        onSave={onSave}
      // onValueChanged={(data) => {console.log(data); setFormData(data)}}
      />
    </div>
  )
}
