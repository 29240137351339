// import moment from 'moment';

import moment from "moment";

export interface FormFieldOption {
  Value: any;
  Label: string;
}

export interface FormField {
  Name: string;
  Type: string;
  Label: string;
  Value: any;
  Options: FormFieldOption[];
  Valid: boolean;
  Blurred: boolean;
  Warnings: string[];
  Errors: string[];
  Disabled: boolean;
  Col: number;
  Validators: FormValidator;
  Watcher: any;
  ValueChange: boolean;
  AutocompleteFetch: any;
  Hidden: boolean;
  Caption?: string;
  [key: string]: string | number | FormFieldOption[] | boolean | any;
}

export interface FormGroup {
  Fields: FormField[];
  Buttons: string[];
  Model: string | null;
  ComponentSave: boolean;
  FillParams: any[];
}

export interface FormValidator {
  required?: string | boolean | {
    value?: boolean;
    message?: string;
  };
  min?: number
  max?: number
  maxLength?: number;
  minLength?: number;
  pattern?: any;
  validate?: any;
  email?: boolean;
}
// export interface FormButton {
//   Label: string;
//   Id: any;
// }

const ConsoleDefaultWatchers = false;
export class Form {

  static NoFields = ['section', 'id'];
  /**
   * 
   * @param Fields Campos del formulario (Form.Field)
   * @param Buttons Botones del formulario (['Guardar', ...])
   * @param Model Modelo usado en el back
   * @param ComponentSave 'true' si se hará automático con el componente FormComponent, 'false' si se controla demtro del componente
   */
  static Group(Fields: FormField[], Buttons: string[] = ['Enviar'], Model: string | null = null, ComponentSave: boolean = true): FormGroup {
    return {
      Fields,
      Buttons,
      Model,
      ComponentSave,
      FillParams: []
    }
  }

  static Field(Type: string, Name: string, Label: string, Col: number = 12, Validators: FormValidator = {}, Options: FormFieldOption[] = [], Value: any = undefined, Watcher: any = null, Disabled: boolean = false, AutocompleteFetch: any = null, Hidden: boolean = false, Caption = ''): FormField {
    // if (Type === 'date' || Type === 'time') Value = moment()
    // if (Type === 'date' || Type === 'time') Value = undefined
    // if (Type === 'selectm') Value = []
    // if (Type === 'select' || Type === 'autocomplete' || Type === 'selectsearch') Value = undefined
    // if (Type === 'check' || Type === 'checkbox') { Value = false; Validators = { ...Validators, required: false } }
    // if (Type === 'color') { Value = { r: 227, g: 123, b: 0, a: 1 }; Validators = { ...Validators, required: false } }
    return {
      Type,
      Name,
      Value,
      Label,
      Col,
      Validators,
      Options,
      Disabled,
      Blurred: false,
      Warnings: [],
      Errors: [],
      Valid: false,
      ValueChange: false,
      Watcher: Watcher || ((val: any, fval: any) => { if (ConsoleDefaultWatchers) console.log(`[Watcher] ${Name}: ${val} / ${fval}`) }),
      AutocompleteFetch,
      FillParams: [],
      Hidden,
      Caption,
      ...this.GetDefaultProps(Type, Value)
    }
  }

  static GetDefaultProps(Type: string, Value: any = null) {
    if (Type === 'date' || Type === 'time' || Type === 'datetime' || Type === 'hour') return { Value: Value || undefined }
    if (Type === 'selectm' || Type === 'selectsearchm') return { Value: Value || [] }
    if (Type === 'select' || Type === 'autocomplete' || Type === 'selectsearch') return { Value: Value || undefined }
    if (Type === 'check' || Type === 'checkbox') { return { Value: Value || false, Validators: { ...Validators, required: false } } }
    if (Type === 'color') { return { Value: { r: 227, g: 123, b: 0, a: 1 }, Validators: { ...Validators, required: false } } }
    if (Type === 'hidden') { return { Validators: { ...Validators, required: false } } }
    if (this.NoFields.includes(Type)) return { Valid: true }
    return {}
  }

  static Hidden(Name: string, Value: any = '') {
    return {
      Type: 'hidden',
      Name,
      Value,
      Label: '',
      Col: 0,
      Valid: true,
      ...this.GetDefaultProps('hidden')
    }
  }

  static ID(Name: string) {
    return {
      Type: 'id',
      Name,
      Value: undefined,
      Label: 'ID',
      Col: 0,
      Valid: true,
      ...this.GetDefaultProps('hidden')
    }
  }

  static Section(Label: string, Value: any = 1, Col: number = 12) {
    return {
      Type: 'section',
      Name: 'section',
      Label,
      Value,
      Col,
      Valid: true,
    }
  }

  static FieldValue(FormGroup: FormGroup, FieldName: string) {
    let Value = FormGroup.Fields.find((field) => field.Name === FieldName)?.Value || null;
    // console.log(FormGroup.Fields)
    return Value;
  }

  static FieldMod(FormGroup: FormGroup, FieldName: string, FieldProp: any, Value: any) {
    let FieldIdx = FormGroup.Fields.findIndex((field) => field.Name === FieldName);
    if (FieldIdx < 0) {
      console.error(`[FieldMod] Campo '${FieldName}' en formulario '${FormGroup}' no existe`)
      return FormGroup
    }
    FormGroup.Fields[FieldIdx][FieldProp] = Value;
    // console.log(FormGroup)
    return FormGroup;
  }
  static FieldModGroup(FormGroup: FormGroup, Group: { fieldName: string, fieldProp: any, value: any }[]) {
    let NewForm = FormGroup;
    Group.forEach((Grupo) => {
      NewForm = this.FieldMod(NewForm, Grupo.fieldName, Grupo.fieldProp, Grupo.value);
    })
    // console.log(NewForm)
    return NewForm;
  }
  static FMG_Field(fieldName: string, fieldProp: any, value: any) {
    return { fieldName, fieldProp, value };
  }

  static FieldOptions(FormGroup: FormGroup, fieldName: string, newOptions: any[], opVal: string, opLabel: string) {
    var format: FormFieldOption[] = newOptions.map((option) => ({ Value: option[opVal] + '', Label: option[opLabel] + '' }))
    // console.log(format)
    return Form.FieldMod(FormGroup, fieldName, 'Options', format);
  }

  // setFormData(Form.FieldOptions({ ...FormData }, 'idpropietario', Res.data.propietarios, 'idpropietario', 'descripcion'))
  static FieldOptionsGroup(FormGroup: FormGroup, Group: { fieldName: string, newOptions: any[], opVal: string, opLabel: string }[]) {
    // console.log(Group)
    let NewForm = FormGroup;
    Group.forEach((Grupo) => {
      if (!Grupo.newOptions || Grupo.newOptions.length === 0) {
        // console.warn(`[FieldOptionsGroup] No existen las opciones para el campo '${Grupo.fieldName}'`)
        return;
      }
      NewForm = this.FieldOptions(NewForm, Grupo.fieldName, Grupo.newOptions, Grupo.opVal, Grupo.opLabel);
    })
    // console.log(NewForm)
    return NewForm;
  }
  static FOG_Field(fieldName: string, newOptions: any[], opVal: string, opLabel: string) {
    return { fieldName, newOptions, opVal, opLabel };
  }

  static FillParam(Params: any[], Name: string, Value: any) {
    if (Value.length < 1) return Params;
    let NewParams = Params;
    // console.log(`[FillParam] Se asigna parámetro '${Name}' con valor '${Value}'`)
    let Idx = Params.findIndex((param) => param[0] === Name)
    if (Idx >= 0) {
      // console.log(`[FillParam] El parámetro '${Name}' ya está creado, se modifica a '${Value}'`)
      NewParams[Idx][1] = Value;
    }
    else {
      let a = [Name, Value]
      // console.log(`[FillParam] Se crea el parámetro '${Name}' = '${Value}'`, a)
      NewParams.push(a);
    }
    // console.log(NewParams)
    return NewParams;
  }

  static MergeParams(Params: any[], NewParams: any[]) {
    if (NewParams.length === 0) return Params;
    let MergedParams = Params;
    NewParams.forEach((nparam, npi) => {
      let mParamIdx = MergedParams.findIndex((mparam) => mparam[0] === nparam[0]);
      if (mParamIdx >= 0)
        MergedParams[mParamIdx][1] = nparam[1];
      else
        MergedParams.push([nparam[0], nparam[1]]);
    })
    // console.log(`[MergeParams] Se mergean parámetros, resultado es `, MergedParams);
    return MergedParams;
  }

  static FillParamDelete(Params: any[], Name: string) {
    Params = Params.filter((param) => param[0] !== Name)
    return Params;
  }

  static FillParamClear() {
    return [];
  }

  static Patch(FormGroup: FormGroup, Values: { [key: string]: any }) {
    FormGroup.Fields = FormGroup.Fields.map(
      (Field) => {
        for (let Prop in Values) {
          if (Prop === Field.Name) {
            if (isDate(Values[Prop])) {
              if (typeof Values[Prop] === 'string') {
                Values[Prop] = Values[Prop].replace('Z', '');
              }
              const dateString = moment(Values[Prop]).format('MM/DD/YYYY HH:mm');
              Values[Prop] = new Date(dateString);
            }
            Field.Value = Values[Prop];
            Field.ValueChange = true;
          }
        }
        return { ...Field }
      }
    )
    // console.log(FormGroup)
    return FormGroup;
  }

  static PatchPreds(FormGroup: FormGroup, data: any): FormGroup {
    let FieldsWPreds = FormGroup.Fields.map((Field) => {
      if (!data[Field.Name] && data[Field.Name] !== 0 && typeof data[Field.Name] !== 'boolean') return Field;
      // console.log(`${Field.Name} = ${data[Field.Name]} (${typeof data[Field.Name]})`)
      if (isDate(data[Field.Name])) {
        if (typeof data[Field.Name] === 'string') {
          data[Field.Name] = data[Field.Name].replace('Z', '');
        }
        const dateString = moment(data[Field.Name]).format('MM/DD/YYYY HH:mm');
        data[Field.Name] = new Date(dateString);
      }
      else if (!isNaN(data[Field.Name]) && typeof data[Field.Name] !== "boolean") {
        // console.log(`${Field.Name} isNumber`)
        data[Field.Name] = data[Field.Name].toString();
      }
      Field.Value = data[Field.Name];
      return Field;
    })
    // console.log(FieldsWPreds)
    return { ...FormGroup, Fields: FieldsWPreds }
  }

  static Reset(FormGroup: FormGroup) {
    FormGroup.Fields = FormGroup.Fields.map((Field) => ({ ...Field, Value: undefined, ...this.GetDefaultProps(Field.Type), ValueChange: true }))
    console.log('Formulario reset', FormGroup.Fields)
    return FormGroup;
  }

  static Values(FormGroup: FormGroup) {
    const ValuesObject: any = {}, { Fields } = FormGroup;
    Fields.forEach(Field => {
      if (Form.NoFields.includes(Field.Type)) return;
      ValuesObject[Field.Name] = Field.Value;
      if (Field.Value instanceof moment) {
        Field.Value = moment(Field.Value);
        ValuesObject[Field.Name] = Field.Value.format()
      }
    })
    return ValuesObject;
  }

  static ValidateFields(Fields: FormField[], BlurAll = false) {
    let ValidatedFields = Fields;
    if (BlurAll) {
      // Esto se da en un submit, donde no se sabe si todos fueron blurred pero se debe comprobar si son válidos como si los hayan blurred
      ValidatedFields = ValidatedFields.map((Field) => ({ ...Field, Blurred: true }))
      // console.log(`[ValidateFields] Se Blurred todos los Fields: `, ValidatedFields)
    }
    const FieldError = (Field: FormField, Error: string) => { Field.Errors.push(Error); Field.Valid = false; return Field; }
    ValidatedFields = ValidatedFields.map((Field) => {
      if (this.NoFields.includes(Field.Type)) return Field;
      if (!Field.Blurred) return Field;
      Field.Errors = []
      if (Field.Validators.required || (Field.Value && typeof Field.Value == "string" && Field.Value.length > 0)) {
        if (Field.Value === 0 || Field.Value) Field.Valid = true;
        else Field = FieldError(Field, 'Campo obligatorio');
        // else Field.Errors = ['Campo inválido']
        // else Field.Errors.push('Campo obligatorio')

        if (Field.Validators.minLength) {
          if (!Field.Value) Field = FieldError(Field, `Longitud mínima: ${Field.Validators.minLength}`);
          else {
            let ValueStr = Field.Value.toString();
            if (ValueStr && ValueStr.length >= Field.Validators.minLength) Field.Valid = true;
            else Field = FieldError(Field, `Longitud mínima: ${Field.Validators.minLength}`);
          }
          // else Field.Errors.push(`Longitud mínima: ${Field.Validators.minLength}`);
        }

        if (Field.Validators.maxLength) {
          if (!Field.Value) Field.Valid = true;
          else {
            let ValueStr = Field.Value.toString();
            if (ValueStr && ValueStr.length <= Field.Validators.maxLength) Field.Valid = true;
            else Field = FieldError(Field, `Longitud máxima: ${Field.Validators.maxLength}`);
          }
        }

        if (Field.Validators.email) {
          let regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (regex.test(String(Field.Value).toLowerCase())) Field.Valid = true;
          else Field = FieldError(Field, `Debe ser un correo`);
        }

        if (Field.Validators.min) {
          if (isNaN(Field.Value)) Field = FieldError(Field, `Cantidad mínima: ${Field.Validators.min}`);
          else {
            let num = parseFloat(Field.Value);
            if (num >= Field.Validators.min) Field.Valid = true;
            else Field = FieldError(Field, `Cantidad mínima: ${Field.Validators.min}`);
          }
        }

        if (Field.Validators.max) {
          if (isNaN(Field.Value)) Field = FieldError(Field, `Cantidad maxima: ${Field.Validators.max}`);
          else {
            let num = parseFloat(Field.Value);
            if (num <= Field.Validators.max) Field.Valid = true;
            else Field = FieldError(Field, `Cantidad maxima: ${Field.Validators.max}`);
          }
        }
      }
      else {
        Field.Valid = true;
      }

      // if (Field.Valid) Field.Errors = []
      // console.log(`[ValidateFields] El campo ${Field.Name} es valido: ${Field.Valid}`)
      return Field;
    })
    return ValidatedFields;
  }
}

export class Validators {
  static Email() {
    return {
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        message: "Correo inválido"
      }
    }
  }
}

export function isDate(date: any) {
  let isdate = false;
  if (isNaN(date) && typeof date !== 'object' && moment(date, [moment.ISO_8601], true).isValid()) {
    isdate = true;
  }
  if (/^[0-9][0-9]:[0-9][0-9]$/.test(date)) {
    isdate = true;
  }
  if (/^(((19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])) [0-9][0-9]:[0-9][0-9])$/.test(date)) {
    isdate = true;
  }
  if (date instanceof Date) {
    isdate = true;
  }
  // console.log(`Se verifica si ${date} es Date: ${isdate}`);
  return isdate;
}