import React, { useState } from 'react'
import { Table, TableComponent } from 'components/Table'
import { Button, message, Modal } from 'antd';
import CambioPrecios from './CambioPrecios/CambioPrecios';
import { MoneyCollectOutlined } from '@ant-design/icons';

export default function Fechas() {
  const [TableData] = useState(
    Table.Data([
      Table.Field('idpropietario', 'Propietario'),
      Table.Field('ciudad', 'Ciudad'),
      Table.Field('recinto', 'Recinto'),
      Table.Field('evento', 'Evento'),
      Table.Field('fecha', 'Fecha'),
      Table.Field('hora', 'Hora'),
      Table.Field('categoria', 'Categoría'),
      Table.Field('promotor', 'Promotor'),
    ], [
      ...Table.DefaultActions(),
    ],
      Table.Config('calendario', 'evento', 'idcalendario', onSelectionChange)
    )
  )
  const [selectedRows, setSelectedRows] = useState([]);
  const [modalPrecios, setModalPrecios] = useState({
    visible: false,
    rows: []
  })

  function onSelectionChange(selection) {
    console.log(selection)
    setSelectedRows(selection)
  }

  function validarCambioPrecios() {
    const iguales = selectedRows.every((row, ri) => {
      if (ri === 0) return true;
      if (row.evento === selectedRows[ri - 1].evento && row.idpropietario === selectedRows[ri - 1].idpropietario && row.recinto === selectedRows[ri - 1].recinto)
        return true;
      return false;
    })
    console.log(`Los eventos son iguales? ${iguales}`)
    if(!iguales) return message.error('Los eventos deben ser iguales')
    setModalPrecios({
      visible: true,
      rows: selectedRows,
    })
  }

  return (
    <div>
      <h2>Programación de eventos</h2>
      <div style={{ display: 'flex', justifyContent: 'flex-end', paddingBottom: 10 }}>
        {
          selectedRows.length > 1 && (
            <Button onClick={() => validarCambioPrecios()} icon={<MoneyCollectOutlined />} type='primary'>Cambiar precios</Button>
          )
        }
      </div>
      <TableComponent
        data={TableData}
      />

      <Modal
        title="Cambiar precios"
        visible={modalPrecios.visible}
        onOk={() => setModalPrecios({ ...modalPrecios, visible: false })}
        onCancel={() => setModalPrecios({ ...modalPrecios, visible: false })}
        footer={<div />}
        width='80%'
        destroyOnClose={true}
      >
        <CambioPrecios eventos={modalPrecios.rows} />
      </Modal>
    </div>
  )
}
