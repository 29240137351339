import React, { useState } from 'react'
import { Table, TableComponent } from 'components/Table'

export default function PuntosVenta() {
  const [TableData] = useState(
    Table.Data([
      Table.Field('idpropietario', 'idpropietario'),
      Table.Field('descripcion', 'Descripcion'),
    ], [
      ...Table.DefaultActions(),
    ],
      Table.Config('punto-venta', 'punto-venta', 'idpdv')
    )
  )
  
  return (
    <div>
      <h2>Puntos de Venta</h2>
      <TableComponent
        data={TableData}
      />
    </div>
  )
}
