const Options = [
  {
    id: 5,
    type: 'children',
    label: 'Sistema',
    link: '/Sistema',
    children: [
      {
        id: 501,
        type: 'link',
        label: 'Seguridad',
        model: 'Proceso',
        link: '/Sistema/Procesos'
      },
      {
        id: 502,
        type: 'link',
        label: 'Datos generales',
        model: 'Spec',
        link: '/Sistema/Generales'
      },
      {
        id: 503,
        type: 'link',
        label: 'Apertura',
        model: 'Apertura',
        link: '/Sistema/AperturaT2'
      },
      {
        id: 504,
        type: 'link',
        label: 'Personalizar',
        model: 'Apertura',
        link: '/Sistema/Personalizacion'
      }
    ]
  },
  // {
  //   id: 6,
  //   type: 'children',
  //   label: 'Evento',
  //   // model: 'Calendario',
  //   link: '/Fecha',
  //   children: [
  //     {
  //       id: 601,
  //       type: 'link',
  //       label: 'Nuevo',
  //       link: '/Calendario'
  //     },
  //     {
  //       id: 602,
  //       type: 'link',
  //       label: 'Listado',
  //       link: '/Calendarios'
  //     }
  //   ]
  // },
  {
    id: 602,
    type: 'link',
    label: 'Programación',
    link: '/Calendarios'
  },
  {
    id: 7,
    type: 'children',
    label: 'Recursos',
    model: 'Recurso',
    children: [
      {
        id: 701,
        type: 'link',
        label: 'Listado',
        model: 'Recurso',
        link: '/Recursos/Recursos'
      },
      {
        id: 702,
        type: 'link',
        label: 'Disponibilidad',
        model: 'RecursoDisponibilidad',
        link: '/Recursos/Disponibilidades'
      }
    ]
  },
  {
    id: 4,
    type: 'children',
    link: '/Catalogo',
    label: 'Catálogos',
    'children': [
      {
        id: 401,
        type: 'link',
        label: 'Propietarios',
        model: 'Propietario',
        link: '/Catalogo/Propietarios'
      },
      {
        id: 402,
        type: 'link',
        label: 'Productores',
        model: 'Productor',
        link: '/Catalogo/Productores'
      },
      {
        id: 403,
        type: 'link',
        label: 'Productos',
        model: 'Producto',
        link: '/Catalogo/Productos'
      },
      {
        id: 404,
        type: 'link',
        label: 'Eventos',
        model: 'Evento',
        link: '/Catalogo/Eventos'
      },
      {
        id: 405,
        type: 'link',
        label: 'Recintos',
        model: 'Recinto',
        link: '/Catalogo/Recintos'
      },
      {
        id: 405,
        type: 'link',
        label: 'Boletos',
        model: 'Boletoh',
        link: '/Catalogo/Boletos'
      },
      {
        id: 406,
        type: 'link',
        label: 'Categorías',
        model: 'Categoria',
        link: '/Catalogo/Categorias'
      },
      {
        id: 407,
        type: 'link',
        label: 'Ciudades',
        model: 'Ciudad',
        link: '/Catalogo/Ciudades'
      },
      {
        id: 417,
        type: 'link',
        label: 'Promociones',
        model: 'Promocion',
        link: '/Catalogo/Promociones'
      },
      {
        id: 408,
        type: 'link',
        label: 'Formas de pago',
        model: 'Forma-Pago',
        link: '/Catalogo/Formas-Pago'
      },
      {
        id: 409,
        type: 'link',
        label: 'Formas de envío',
        model: 'Forma-Envio',
        link: '/Catalogo/Formas-Envio'
      },
      {
        id: 410,
        type: 'link',
        label: 'Bancos',
        model: 'Banco',
        link: '/Catalogo/Bancos'
      },
      {
        id: 411,
        type: 'link',
        label: 'Empresas',
        model: 'Empresa',
        link: '/Catalogo/Empresas'
      },
      {
        id: 412,
        type: 'link',
        label: 'Usuarios',
        model: 'Usuario',
        link: '/Catalogo/Usuarios'
      },
      {
        id: 413,
        type: 'link',
        label: 'Puntos de Venta',
        model: 'Punto-Venta',
        link: '/Catalogo/Puntos-Venta'
      },
      {
        id: 414,
        type: 'link',
        label: 'Promotores',
        model: 'Promotor',
        link: '/Catalogo/Promotores'
      },
      {
        id: 415,
        type: 'link',
        label: 'Formularios',
        model: 'Formulario',
        link: '/Catalogo/Formularios'
      }
    ]
  },
];

// const Main = () => {
//   let Menus = Options.map((Option) => ({
//     id: Option.id,
//     type: Option.type,
//     label: Option.label,
//     link: Option.link,
//   }))
//   console.log(Menus)
//   return Menus; 
// }

// const Children = (de) => {
//   let Menu = Options.find((Option) => Option.id === de && Option.type === 'children')
//   if(!Menu) return []
//   let Menus = Menu.children.map((Option) => Option)
//   console.log(Menus)
//   return Menus; 
// }

// Main()
// Children(4)

export default Options;