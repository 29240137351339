import React, { useState, useEffect, useRef } from 'react'
import { useParams } from "react-router-dom";
import Butacas, { Butaca } from './Butacas'
import { Button, Modal, Input, message, Spin } from 'antd'
import './Recintos.css'
import loadashClone from 'lodash.clonedeep';
import Theme from 'Theme'
import AsignarNumero from './AsignarNumero'
import Http from 'Http';
import { MinusCircleOutlined, PlusCircleOutlined, ExclamationOutlined } from '@ant-design/icons';

export const Numeros = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59', '60', '61', '62', '63', '64', '65', '66', '67', '68', '69', '70', '71', '72', '73', '74', '75', '76', '77', '78', '79', '80', '81', '82', '83', '84', '85', '86', '87', '88', '89', '90', '91', '92', '93', '94', '95', '96', '97', '98', '99', '100'];
export const Letras = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
export const DobleLetras = ['AA', 'BB', 'CC', 'DD', 'EE', 'FF', 'GG', 'HH', 'II', 'JJ', 'KK', 'LL', 'MM', 'NN', 'OO', 'PP', 'QQ', 'RR', 'SS', 'TT', 'UU', 'VV', 'WW', 'XX', 'YY', 'ZZ'];

const ButacaOb = (Fil, Col, Selected = false, Muerta = false) => {
  return {
    Fila: Fil,
    Columna: Col,
    Selected,
    Muerta,
    ref: React.createRef()
  }
}
const ButacaFOb = ({ Fil, Col, Selected = false, Muerta, Butaca, Seccion, Estado = 0, idbutaca = false, FilaValue, ButacaValue }) => {
  return {
    Fila: Fil,
    Columna: Col,
    Selected,
    Muerta,
    Butaca,
    Seccion,
    Estado,
    ref: React.createRef(),
    idbutaca,
    FilaValue: FilaValue,
    ButacaValue: ButacaValue,
  }
}

let ButacaBase = ButacaOb(0, 0);
const ButacasDefault = (filas, cols) => {
  let butacas = [];
  for (let fi = 0; fi < filas; fi++) {
    for (let ci = 0; ci < cols; ci++) {
      butacas.push(ButacaOb(fi, ci, false, true))
    }
  }
  return butacas;
}

export default function Numerar({ modoVenta = false, filas: filasDefault = 11, columnas: columnasDefault = 11, calendario = null, onSaveModal }) {

  const RouteParams = useParams();
  const { id: area } = RouteParams;
  const [Area, setArea] = useState({
    Filas: filasDefault,
    Columnas: columnasDefault,
    Butacas: [],
    area: '',
  });
  const refArea = useRef(Area);
  const [Asignar, setAsignar] = useState({
    visible: false,
    tipo: 0,
  });
  const [Seccion, setSeccion] = useState({ visible: false, seccion: '' });
  const [Saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    refArea.current = Area;
  }, [Area])

  useEffect(() => {
    loadNumeracion();

    let buts = ButacasDefault(filasDefault, columnasDefault);
    setArea({ ...Area, Butacas: buts });

    document.addEventListener("mousemove", mouseMove)
    document.addEventListener("mouseup", () => { document.butacasMouseDown = false })
    return () => {
      document.removeEventListener("mousemove", mouseMove);
    }
    // eslint-disable-next-line
  }, []);

  const loadNumeracion = async () => {
    if (!area) return;
    setLoading(true);
    try {
      let url = `area/${area}/numeracion`;
      let Res = await Http.get(url);
      // console.log(Res.data)
      if (!Res.error) {
        setLoading(false);
        if (Res.data.butacas.length === 0) return;
        let butacas = Res.data.butacas.map(b => ({ ...b, ref: React.createRef() }));
        setArea({
          Filas: Res.data.filas,
          Columnas: Res.data.columnas,
          Butacas: butacas,
          area: Res.data.area,
          // Butacas: Res.data.butacas,
        })
      }
    } catch (error) {

    }
    setLoading(false);
  }

  const modFila = (val = 1) => {
    setArea(prevArea => {
      prevArea.Filas += parseInt(val);
      modButacas(prevArea.Filas, prevArea.Columnas);
      return { ...prevArea };
    })
  }

  const modButacas = (filas, columnas) => {
    setArea(prevArea => {
      let newButacas = []
      for (let f = 0; f < filas; f++) {
        for (let c = 0; c < columnas; c++) {
          let ButIdx = prevArea.Butacas.findIndex(butaca => butaca.Columna === c && butaca.Fila === f);
          if (ButIdx < 0) {
            // butaca nueva
            newButacas.push(ButacaFOb({ Fil: f, Col: c }));
          } else {
            newButacas.push(ButacaFOb({ ...prevArea.Butacas[ButIdx], Fil: f, Col: c }));
          }
        }
      }
      return { ...prevArea, Butacas: newButacas }
    })
  }

  const modColumna = (val = 1) => {
    setArea(prevArea => {
      prevArea.Columnas += parseInt(val);
      modButacas(prevArea.Filas, prevArea.Columnas);
      return { ...prevArea };
    })
  }

  const clickButaca = (Bi) => {
    // console.log({ ButacaBase });
    setArea(prevArea => {
      ButacaBase = { ...prevArea.Butacas[Bi] };
      prevArea.Butacas[Bi].Selected = !ButacaBase.Selected;
      return { ...prevArea }
    })
  }
  const mouseDownButaca = (Bi) => {
    ButacaBase = { ...Area.Butacas[Bi] };
  }
  const mouseEnterButaca = (Bi) => {
    return;
  }

  const mouseMove = (event) => {
    try {
      if (document.butacasMouseDown) {
        setArea(prevArea => {
          prevArea = refArea.current;
          prevArea.Butacas = prevArea.Butacas.map((Butaca, Bi) => {
            if (Butaca.Selected) { return Butaca; }
            if (!Butaca.ref) { return Butaca; }
            let rect = Butaca.ref.current.getBoundingClientRect();
  
            if (Butaca.Fila === ButacaBase.Fila && Butaca.Columna === ButacaBase.Columna) {
              Butaca.Selected = true;
            }
            else if (Butaca.Fila >= ButacaBase.Fila && Butaca.Columna >= ButacaBase.Columna) {
              if (event.pageX > rect.x && event.clientY > (rect.y)) {
                Butaca.Selected = true;
              }
              else if (event.pageX < (rect.x + 30) || event.clientY < (rect.y + 30)) {
                Butaca.Selected = false;
              }
            }
            else if (Butaca.Fila <= ButacaBase.Fila && Butaca.Columna <= ButacaBase.Columna) {
              if (event.pageX < (rect.x + 30) && event.clientY < (rect.y + 30)) {
                Butaca.Selected = true;
              }
              else if (event.pageX > (rect.x) || event.clientY > (rect.y)) {
                Butaca.Selected = false;
              }
            }
            else if (Butaca.Fila <= ButacaBase.Fila && Butaca.Columna >= ButacaBase.Columna) {
              if (event.pageX > (rect.x) && event.clientY < (rect.y + 30)) {
                Butaca.Selected = true;
              }
              else {
                Butaca.Selected = false;
              }
            }
            else if (Butaca.Fila >= ButacaBase.Fila && Butaca.Columna <= ButacaBase.Columna) {
              if (event.pageX < (rect.x) && event.clientY > (rect.y + 30)) {
                Butaca.Selected = true;
              }
              else {
                Butaca.Selected = false;
              }
            }
            return Butaca;
          })
          return { ...prevArea }
        })
      }
    } catch (error) {
      message.error('Error inesperado');
    }
  }

  const areaMuerta = () => {
    setArea(prevArea => {
      prevArea.Butacas = prevArea.Butacas.map((Butaca) => {
        if (Butaca.Selected) {
          Butaca.Muerta = true;
          Butaca.Selected = false;
          Butaca.Butaca = null;
        }
        return Butaca;
      })
      return { ...prevArea }
    })
  }

  const desmarcar = () => {
    setArea(prevArea => {
      prevArea.Butacas = prevArea.Butacas.map((Butaca) => {
        if (Butaca.Selected) {
          Butaca.Muerta = false;
          Butaca.Butaca = null;
          Butaca.Selected = false;
        }
        return Butaca;
      })
      return { ...prevArea }
    })
  }

  const deseleccionar = () => {
    setArea(prevArea => {
      prevArea.Butacas = prevArea.Butacas.map((Butaca) => {
        if (Butaca.Selected) {
          Butaca.Selected = false;
        }
        return Butaca;
      })
      return { ...prevArea }
    })
  }

  const onAsignar = (tipo = 1) => {
    setAsignar({ ...Asignar, visible: true, tipo });
  }

  const sentidoMuerto = (sentido, valor, butacas) => {
    let todasMuertas = true;
    /* butacas.forEach(b => {
      if (b[sentido] === valor && !b.Muerta) todasMuertas = false;
    }); */
    todasMuertas = butacas.some(b => b[sentido] === valor && b.Muerta)
    return todasMuertas;
  }

  const asignar = (values) => {
    try {
      setAsignar({ ...Asignar, visible: false });
      let Pivote = Area.Butacas.find(butaca => butaca.Selected);
      let ButacasTR = loadashClone(Area.Butacas);
      let Pivotent = ButacasTR.reverse().find(butaca => butaca.Selected);
  
      let FilasMuertas = [];
      let FilasMuertasReales = [];
      let ColsMuertas = [];
      let ColsMuertasReales = {};
      let FilasMuertasPasadas = [];
      let ColsMuertasPasadas = [];
      Area.Butacas.forEach((butaca) => {
        if (!(butaca.Selected && sentidoMuerto("Fila", butaca.Fila, Area.Butacas) && !FilasMuertas.find(fm => fm === butaca.Fila))) {
          if (!FilasMuertas.some(e => e === butaca.Fila)) { 
            FilasMuertas.push(butaca.Fila)
          }
        }
        if (butaca.Selected && sentidoMuerto("Fila", butaca.Fila, Area.Butacas) && !FilasMuertasReales.find(fm => fm === butaca.Fila)) {
          if (!FilasMuertasReales.some(e => e === butaca.Fila)) {
            FilasMuertasReales.push(butaca.Fila)
          }
        }
        if (butaca.Selected && sentidoMuerto("Columna", butaca.Columna, Area.Butacas) && !ColsMuertas.find(fm => fm === butaca.Columna)) {
          if (!ColsMuertas.some(e => e === butaca.Columna)) {
            ColsMuertas.push(butaca.Columna);
          }
        }
        if (butaca.Selected && butaca.Muerta) {
          if(ColsMuertasReales[butaca.Fila]) {
            if (!ColsMuertasReales[butaca.Fila].some(e => e === butaca.Columna)) {
              ColsMuertasReales[butaca.Fila].push(butaca.Columna);
            } 
          } else {
            ColsMuertasReales[butaca.Fila]= [butaca.Columna];
          }
        }
      })
  
      // fila;
      if (Asignar.tipo === 1) {
        if (values.personalizado) {
          setArea(prevArea => {
            prevArea.Butacas = prevArea.Butacas.map((Butaca) => {
              if (!Butaca.Selected) {
                return Butaca;
              }
              if (Butaca.Muerta && !values.skip_muerta) {
                Butaca.Muerta = false;
              }
              Butaca.Selected = false;
              Butaca.FilaValue = values.personalizado;
              return Butaca;
            })
            return { ...prevArea }
          })
        } else {
          setArea(prevArea => {
            let Caracteres = values.tipo === '2' ? Letras : DobleLetras;
            let CI = parseInt(values.desde);
            let FilaAnterior = -1;
            let FilasMuertasPasadasTipo = [];
            prevArea.Butacas = prevArea.Butacas.map((Butaca) => {
              if (!Butaca.Selected) { 
                return Butaca;
              }
              if (FilaAnterior !== Butaca.Fila) {
                FilaAnterior = Butaca.Fila;
                if (FilasMuertasReales.includes(Butaca.Fila)) {
                  if (!FilasMuertasPasadasTipo.includes(Butaca.Fila)) {
                    FilasMuertasPasadasTipo.push(`${Number(Butaca.Fila)}`);
                  }
                }
              }
              Butaca.Selected = false;
              if (Butaca.Muerta) {
                if (FilasMuertas.includes(Butaca.Fila)) {
                  if (!FilasMuertasPasadas.includes(Butaca.Fila)) {
                    FilasMuertasPasadas.push(Butaca.Fila);
                  }
                }
                if (!values.skip_muerta) {
                  Butaca.Muerta = false;
                } else {
                  return Butaca;
                }
              }
              if (values.tipo === '1') {
                if (values.direccion === '1') {
                  Butaca.FilaValue = CI + Butaca.Fila - Pivote.Fila - (values.skip_muerta ? FilasMuertasPasadas.length : 0);
                } else if (values.direccion === '2') {
                  Butaca.FilaValue = CI + Pivotent.Fila - Butaca.Fila - (values.skip_muerta ? (FilasMuertasReales.length - FilasMuertasPasadas.length) : 0);
                }
              } else {
                if (values.direccion === '1') {
                  Butaca.FilaValue = Caracteres[CI + Butaca.Fila - Pivote.Fila - (values.skip_muerta ? FilasMuertasPasadas.length : 0)];
                } else if (values.direccion === '2') {
                  Butaca.FilaValue = Caracteres[CI + Pivotent.Fila - Butaca.Fila - (values.skip_muerta ? (FilasMuertasReales.length - FilasMuertasPasadas.length) : 0)];
                }
              }
              Butaca.Muerta = false;
              return Butaca;
            })
            return { ...prevArea }
          })
        }
      }
  
      // butacas;
      else if (Asignar.tipo === 2) {
        if (values.personalizado) {
          setArea(prevArea => {
            prevArea.Butacas = prevArea.Butacas.map((Butaca) => {
              if (!Butaca.Selected) {
                return Butaca;
              }
              if (Butaca.Muerta && !values.skip_muerta) {
                Butaca.Muerta = false;
              }
              Butaca.Selected = false;
              Butaca.ButacaValue = values.personalizado;
              return Butaca;
            })
            return { ...prevArea }
          })
        } else {
          setArea(prevArea => {
            let Caracteres = values.tipo === '2' ? Letras : DobleLetras;
            let CI = parseInt(values.desde);
            let FilaAnterior = -1;
            prevArea.Butacas = prevArea.Butacas.map((Butaca) => { 
              if (!Butaca.Selected) return Butaca;
              if (FilaAnterior !== Butaca.Fila) {
                FilaAnterior = Butaca.Fila;
                ColsMuertasPasadas = [];
              }
              Butaca.Selected = false;
               if (Butaca.Muerta) {
                if (FilasMuertas.includes(Butaca.Fila)) {
                  if (!FilasMuertasPasadas.includes(Butaca.Fila)) {
                    FilasMuertasPasadas.push(Butaca.Fila);
                  }
                }
                if (ColsMuertasReales[Butaca.Fila].includes(Butaca.Columna)) {
                  if (!ColsMuertasPasadas.includes(Butaca.Columna)) {
                    ColsMuertasPasadas.push(Butaca.Columna);
                  }
                }
                if (!values.skip_muerta) {
                  Butaca.Muerta = false;
                } else {
                  return Butaca;
                }
              }
              if (values.tipo === '1') {
                if (values.direccion === '1') {
                  Butaca.ButacaValue = CI + Butaca.Fila - Pivote.Fila - (values.skip_muerta ? FilasMuertasPasadas.length : 0);
                } else if (values.direccion === '2') {
                  Butaca.ButacaValue = CI + Pivotent.Fila - Butaca.Fila - (values.skip_muerta ? (FilasMuertasReales.length - FilasMuertasPasadas.length) : 0);
                } else if (values.direccion === '3') {
                  Butaca.ButacaValue = CI + Butaca.Columna - Pivote.Columna - (values.skip_muerta ? ColsMuertasPasadas.length : 0);
                } else if (values.direccion === '4') {
                  Butaca.ButacaValue = (Pivotent.Columna + CI - Butaca.Columna) - (values.skip_muerta ? (ColsMuertasReales[Butaca.Fila].length - ColsMuertasPasadas.length) : 0);
                }
              } else if (values.tipo === '2' || values.tipo === '3') {
                if (values.direccion === '1') {
                  Butaca.ButacaValue = Caracteres[CI + Butaca.Fila - Pivote.Fila - (values.skip_muerta ? FilasMuertasPasadas.length : 0)];
                } else if (values.direccion === '2') {
                  Butaca.ButacaValue = Caracteres[CI + Pivotent.Fila - Butaca.Fila - (values.skip_muerta ? (FilasMuertasReales.length - FilasMuertasPasadas.length) : 0)];
                } else if (values.direccion === '3') {
                  Butaca.ButacaValue = Caracteres[CI + Butaca.Columna - Pivote.Columna - (values.skip_muerta ? ColsMuertasPasadas.length : 0)];
                } else if (values.direccion === '4') {
                  Butaca.ButacaValue = Caracteres[(Pivotent.Columna + CI - Butaca.Columna) - (values.skip_muerta ? (ColsMuertasReales[Butaca.Fila].length - ColsMuertasPasadas.length) : 0)];
                }
              }
              return Butaca;
            })
            return { ...prevArea }
          })
        }
      }
    } catch (error) {
      message.error(error.message);
    }
  }

  const onSeccion = () => {
    setSeccion({ ...Seccion, visible: true })
  }
  const seccion = () => {
    setSeccion({ ...Seccion, visible: false })
    setArea(prevArea => {
      prevArea.Butacas = prevArea.Butacas.map((Butaca) => {
        if (!Butaca.Selected) return Butaca;
        Butaca.Selected = false;
        Butaca.Seccion = Seccion.seccion;
        return Butaca;
      })
      return { ...prevArea };
    })
  }

  const onSave = () => {
    Modal.confirm({
      title: '¿Guardar la numeración del área?',
      icon: <ExclamationOutlined /> ,
      onOk() {
        save();
      },
      onCancel() {
        console.log('Cancel');
      },
    })
  }

  const save = async () => {
    setSaving(true);
    try {
      Area.Butacas = Area.Butacas.map(b => { delete b.ref; return b })
      let url = `area/${area}/numeracion/save`;
      let Res = await Http.post(url, { ...Area });
      if (!Res.error) {
        loadNumeracion();
        setSaving(false);
        message.info('Numeración guardada');
        if (onSaveModal) {
          onSaveModal();
        }
      } else {
        setSaving(false)
        let butacas = Area.Butacas.map(b => ({ ...b, ref: React.createRef() }));
        setArea({
          ...Area,
          Butacas: [...butacas]
         })
        message.error(Res.errorMsg || 'Error al guardar');
      }
    } catch (error) {
      let butacas = Area.Butacas.map(b => ({ ...b, ref: React.createRef() }));
        setArea({
         ...Area,
         Butacas: [...butacas]
      })
      setSaving(false)
    }
  }

  const onBloquear = (bloquear = true) => {
    // console.log('bloquear');
    setArea(prevArea => {
      prevArea.Butacas = prevArea.Butacas.map((Butaca) => {
        if (!Butaca.Selected) return Butaca;
        Butaca.Selected = false;
        if (Butaca.Muerta) return Butaca;
        // Butaca.Estado = Butaca.Estado && Butaca.Estado === 6 ? 0 : 6;
        Butaca.Estado = !bloquear ? 0 : 6;
        return Butaca;
      })
      return { ...prevArea }
    })
  }

  const onHabilitar = () => {
    // console.log('bloquear');
    setArea(prevArea => {
      prevArea.Butacas = prevArea.Butacas.map((Butaca) => {
        if (!Butaca.Selected) return Butaca;
        Butaca.Selected = false;
        Butaca.Muerta = false;
        Butaca.ButacaValue = ' ';
        return Butaca;
      })
      return { ...prevArea }
    })
  }

  const clickContextMenu = ({ key }, ButacaInf) => {
    if (key === '1') {
      setArea(prevArea => {
        let filas = prevArea.Filas; 
        let columnas = prevArea.Columnas + 1;
        let newButacas = [];
        for (let f = 0; f < filas; f++) {
          for (let c = 0; c < columnas; c++) {
            let ButIdx = prevArea.Butacas.find(butaca => butaca.Columna === c && butaca.Fila === f);
            if (c < ButacaInf.Columna) {
              newButacas.push(ButacaFOb({ ...ButIdx, Fil: f, Col: c }));
            } else if (c > ButacaInf.Columna) {
              ButIdx = prevArea.Butacas.find(butaca => (butaca.Columna === (c-1)) && butaca.Fila === f);
              newButacas.push(ButacaFOb({ ...ButIdx, Fil: f, Col: c }));
            } else if (ButacaInf.Columna === c) {
              newButacas.push(ButacaFOb({ Fil: f, Col: c, Muerta: true }));
            }
          }
        }
        prevArea.Columnas++;
        return { ...prevArea, Butacas: newButacas }
      })
    } else if (key === '2') {
      setArea(prevArea => {
        let filas = prevArea.Filas + 1;
        let newButacas = [];
        for (let f = 0; f < filas; f++) {
          let fila = prevArea.Butacas.filter(butaca => butaca.Fila === f)
          if (f < ButacaInf.Fila) {
            for (const [index, ButIdx] of fila.entries()) {
              newButacas.push(ButacaFOb({ ...ButIdx, Fil: f, Col: index }));
            }
          } else if (f > ButacaInf.Fila) {
            fila = prevArea.Butacas.filter(butaca => butaca.Fila === (f-1));
            for (const [index, ButIdx] of fila.entries()) {
              newButacas.push(ButacaFOb({ ...ButIdx, Fil: f, Col: index }));
            }
          } else if (ButacaInf.Fila === f) {
            for (let index = 0; index < prevArea.Columnas; index++) {
              newButacas.push(ButacaFOb({ Fil: f, Col: index, Muerta: true }));
            }
          }
        }
        prevArea.Filas++;
        return { ...prevArea, Butacas: newButacas }
      })
    } else if (key === '3') { 
      setArea(prevArea => {
        let filas = prevArea.Filas; 
        let columnas = prevArea.Columnas;
        let newButacas = [];
        for (let f = 0; f < filas; f++) {
          for (let c = 0; c < columnas; c++) {
            let ButIdx = prevArea.Butacas.find(butaca => butaca.Columna === c && butaca.Fila === f);
            if (c < ButacaInf.Columna) {
              newButacas.push(ButacaFOb({ ...ButIdx, Fil: f, Col: c }));
            } else if (c > ButacaInf.Columna) {
              ButIdx = prevArea.Butacas.find(butaca => (butaca.Columna === (c)) && butaca.Fila === f);
              newButacas.push(ButacaFOb({ ...ButIdx, Fil: f, Col: c - 1 }));
            } else if (ButacaInf.Columna === c) {
              // newButacas.push(ButacaFOb({ Fil: f, Col: c, Muerta: true }));
            }
          }
        }
        prevArea.Columnas--;
        return { ...prevArea, Butacas: newButacas }
      })
    } else if (key === '4') { 
      setArea(prevArea => {
        let filas = prevArea.Filas; 
        let columnas = prevArea.Columnas;
        let newButacas = [];
        for (let f = 0; f < filas; f++) {
          for (let c = 0; c < columnas; c++) {
            let ButIdx = prevArea.Butacas.find(butaca => butaca.Columna === c && butaca.Fila === f);
            if (f < ButacaInf.Fila) {
              newButacas.push(ButacaFOb({ ...ButIdx, Fil: f, Col: c }));
            } else if (f > ButacaInf.Fila) {
              ButIdx = prevArea.Butacas.find(butaca => (butaca.Columna === c) && butaca.Fila === f);
              newButacas.push(ButacaFOb({ ...ButIdx, Fil: f - 1, Col: c }));
            } else if (ButacaInf.Fila === f) {
              // newButacas.push(ButacaFOb({ Fil: f, Col: c, Muerta: true }));
            }
          }
        }
        prevArea.Filas--;
        return { ...prevArea, Butacas: newButacas }
      })
    }
  }

  return (
    <div className='numerar'>
      <h2>{Area.area}</h2>
      <div className='panel' style={{ borderColor: Theme.primary }}>
        <Button shape='round' onClick={() => modFila()}>Agregar Fila Final</Button>
        <Button color='primary' shape='round' onClick={() => modFila(-1)}>Eliminar Fila Final</Button>
        <Button icon={<PlusCircleOutlined />} shape='round' onClick={() => modColumna()}>Columna</Button>
        <Button icon={<MinusCircleOutlined />} shape='round' onClick={() => modColumna(-1)}>Columna</Button>
        <div>
          <span>{Area.Filas} x {Area.Columnas}</span>
        </div>
      </div>
      <div className='panel' style={{ borderColor: Theme.primary }}>
        <Button shape='round' onClick={() => onSeccion()}>Sección</Button>
        <Button shape='round' onClick={() => onAsignar()}>Fila</Button>
        <Button shape='round' onClick={() => onAsignar(2)}>Butaca</Button>
        <Button shape='round' onClick={() => areaMuerta()}>Área muerta</Button>
        <Button shape='round' onClick={() => desmarcar()}>Desmarcar</Button>
        <Button shape='round' onClick={() => deseleccionar()}>Deseleccionar</Button>
        <Button shape='round' onClick={() => onBloquear()}>Bloquear</Button>
        <Button shape='round' onClick={() => onBloquear(false)}>Desbloquear</Button>
        <Button type="primary" shape='round' onClick={() => onHabilitar()}>Habilitar Butacas</Button>
      </div>
      {/* <div className='panel' style={{ borderColor: Theme.primary }}>
      </div> */}
      <div style={{ textAlign: 'right' }}>
        <Button type='primary' size='large' onClick={() => onSave()} loading={Saving} >Guardar</Button>
      </div>
      
      { !loading ?
        <div style={{ marginTop: 20, minWidth: 600, overflow: 'auto' }}>
          <Butacas height={(Area.Filas * 35) + (Area.Filas * 2)} width={(Area.Columnas * 35) + (Area.Columnas * 2)}>
            {
              Area.Butacas.map((ButacaInf, Bi) => (
                <Butaca
                  modoVenta={modoVenta}
                  butacaRef={Area.Butacas[Bi].ref}
                  id={`Butaca${ButacaInf.Fila},${ButacaInf.Columna}`}
                  key={`Butaca${ButacaInf.Fila},${ButacaInf.Columna}`}
                  fila={ButacaInf.Fila}
                  columna={ButacaInf.Columna}
                  selected={ButacaInf.Selected}
                  muerta={ButacaInf.Muerta}
                  butaca={ButacaInf.Butaca}
                  seccion={ButacaInf.Seccion}
                  estado={ButacaInf.Estado}

                  butacaValue={ButacaInf.ButacaValue}
                  filaValue={ButacaInf.FilaValue}
                  onClick={(e) => clickButaca(Bi)}
                  onMouseDown={(e) => mouseDownButaca(Bi)}
                  onMouseEnter={(e) => mouseEnterButaca(Bi)}
                  onClickContextMenu={(e) => clickContextMenu(e, ButacaInf)}
                />
              ))
            }
          </Butacas>
        </div>
        : 
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }} >
          <Spin size="large"/>
        </div>
      }

      <Modal
        destroyOnClose={true}
        visible={Asignar.visible}
        onOk={() => setAsignar({ ...Asignar, visible: false })}
        onCancel={() => setAsignar({ ...Asignar, visible: false })}
        footer={null}
      >
        <AsignarNumero
          onSave={asignar}
          tipo={Asignar.tipo}
        />
      </Modal>

      <Modal
        destroyOnClose={true}
        visible={Seccion.visible}
        onOk={() => setSeccion({ ...Seccion, visible: false })}
        onCancel={() => setSeccion({ ...Seccion, visible: false })}
        footer={null}
      >
        <Input 
          maxLength={10}
          style={{ width: '100%' }} 
          placeholder='Sección' 
          value={Seccion.seccion} 
          onChange={(e) => setSeccion({ ...Seccion, seccion: e.target.value })} 
        />
        <Button onClick={() => seccion()}>Aceptar</Button>
      </Modal>

    </div>
  )
}
