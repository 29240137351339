import React, { useState, useEffect } from 'react';
import './App.css';
import Inicio from './components/Inicio';
import { HashRouter } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css'
import { ConfigProvider, Spin } from 'antd';
// import esES from 'antd/es/locale/es_ES';
import Login from './components/Login';
import Http from './Http';
export const AppContext = React.createContext({ nivel: 100, prop: { } });

function App() {
  const [Auth, setAuth] = useState(false)
  const [colores, setColores] = useState({
    primaryColor: 'e37b00',
    secondaryColor: 'ffc68a',
    logo: false
  })
  const [Loading, setLoading] = useState(true)
  const [AppState, setAppState] = useState({ nivel: 100, prop: { } })


  const VerifyAuth = async () => {
    // return setAuth(true)
    let primaryColor = localStorage.getItem('editor-color1')
    let secondaryColor = localStorage.getItem('editor-color2')
    let logo = localStorage.getItem('editor-logo')
    if (primaryColor && secondaryColor) {
      setColores({ primaryColor, secondaryColor, logo });
    }
    
    let BT = localStorage.getItem('bt');
    if (!BT) {
      setAuth(false);
      setLoading(false);
      return;
    }
    try {
      let Res = await Http.get('verify');
      window.less.modifyVars({
        '@primary-color': `#${Res.data.prop.color1 || '000'}`,
        '@table-header-sort-active-bg': '#000'
      });
      localStorage.setItem('editor-color1', Res.data.prop.color1)
      localStorage.setItem('editor-color2', Res.data.prop.color2)
      localStorage.setItem('editor-logo', Res.data.prop.logo_url)
      setColores({
        primaryColor: Res.data.prop.color1,
        secondaryColor: Res.data.prop.color2,
        logo: Res.data.prop.logo_url
      });
      if (!Res.error) {
        setAuth(Res.data)
        setAppState({ ...Res.data, nivel: 100 })
      } else {
        setAuth(false)
      }
    } catch (error) {
      setAuth(false)
    }
    setLoading(false)
  }

  useEffect(() => {
    // console.log('use effect')
    // document.title = 'Arema'
    VerifyAuth();
  }, [])


  return (
    <div className="App">
      <AppContext.Provider value={AppState}>
        <HashRouter>
          {/* <Router> */}
          {/* <ConfigProvider locale={esES}> */}
          <ConfigProvider>
            {/* <Inicio onLogOut={VerifyAuth} /> */}
            {Loading && <div style={{ textAlign: 'center' }}><Spin style={{ top: '48%', position: 'absolute' }} size={'large'} /></div>}
            {!Loading &&
              Auth && <Inicio onLogOut={VerifyAuth} />
            }
            {!Loading &&
              !Auth && (<Login 
                primaryColor={colores.primaryColor} 
                secondaryColor={colores.secondaryColor} 
                logo={colores.logo}
                onLogin={VerifyAuth} />)
            }
          </ConfigProvider>
        </HashRouter>
      </AppContext.Provider>
    </div>
  );
}

export default App;
