import React, { useState, useEffect } from 'react'
import { Form, FormComponent } from 'components/Form';
import './Disponibilidad.css';
import Theme from 'Theme';
import { Button, InputNumber, message } from 'antd';
import moment from 'moment';
import { useHistory, useParams } from "react-router-dom";
import Http from 'Http';
import Global from 'Global';
import { DeleteOutlined } from '@ant-design/icons';

let Params = [];
export default function Disponibilidad() {
  const RouteParams = useParams();
  const History = useHistory();
  const [Progs, setProgs] = useState([])
  const [ProgsTotales, setProgsTotales] = useState({ cantidad: 0, capacidad: 0 })
  const [FormData, setFormData] = useState(
    Form.Group([
      Form.Field('selectsearch', 'idpropietario', 'Propietario', 12, { required: false }, [], undefined, (val) => propietarioChange(val)),
      // Form.Field('selectsearch', 'idpropietario', 'Propietario', 12, { required: true }, [], Global.BIOMEX === "1" ? "BPMEX" : undefined, (val) => propietarioChange(val)),
      Form.Field('select', 'idrecurso', 'Recurso', 12, { required: false }),
      Form.Field('date', 'fecha', 'Fecha', 6, { required: false }),
      Form.Field('time', 'horainicial', 'Hora inicial', 3, { required: true }),
      Form.Field('time', 'horafinal', 'Hora final', 3, { required: true }),
      Form.Field('number', 'minutosbloque', 'Minutos de bloque', 6, { required: true }),
      Form.Field('number', 'duracionrecorrido', 'Duración del recorrido', 6, { required: true }),
      Form.Section('Cálculo previo'),
      Form.Field('number', 'chora', 'Capacidad por hora', 4, { required: false }),
      Form.Field('number', 'cdia', 'Capacidad por día', 4, { required: false }),
      Form.Field('number', 'tbloque', 'Tamaño del bloque', 4, { required: false }),
      Form.Section('Alarma de satauración'),
      Form.Field('number', 'adelantominutos', 'Avisar si el adelanto es de más de  (minutos)', 12, { required: false }),
      Form.Section('Programación'),
    ], ['Guardar', 'Cálculo previo'], '', false)
  );


  useEffect(() => {
    propSettings();
    fillForm();
    loadPreds();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    calcularTotales()
    // eslint-disable-next-line
  }, [Progs]);

  const propSettings = () => {
    if (Global.PROP === "BPMEX") {
      Params = Form.FillParam(Params, 'prop', "BPMEX");
      setFormData({ ...Form.FieldMod(FormData, "idpropietario", "Value", Global.PROP) })
    }
  }

  const propietarioChange = (val) => {
    Params = Form.FillParam(Params, 'prop', val);
    fillForm();
  }

  const fillForm = async () => {
    // if (Global.BIOMEX === "1")

    try {
      let Res = await Http.get('recurso-disponibilidad/form', Params);
      // console.log(Res)
      if (!Res.error) {
        setFormData(
          Form.FieldOptionsGroup({ ...FormData }, [
            Form.FOG_Field('idpropietario', Res.data.propietarios, 'idpropietario', 'descripcion'),
            Form.FOG_Field('idrecurso', Res.data.recursos, 'idrecurso', 'descripcion'),
          ])
        )
      }
    } catch (error) {

    }
  }

  const loadPreds = async () => {
    if (!RouteParams.id) return;
    try {
      let Res = await Http.get(`recurso-disponibilidad/${RouteParams.id}`);
      if (!Res.error) {
        const { data } = Res;
        data.fecha = new Date(new Date(data.fecha).getTime() + (6 * 60 * 60 * 1000))
        setFormData({ ...Form.PatchPreds(FormData, data) })
        setProgs(data.progs)
      }
    } catch (error) {

    }
  }

  const onSubmit = (Values, Button) => {
    console.log(`[Submit] Botón ${Button}, valores: `, Values)
    if (Button === 2) {
      console.log('Cálculo')
      calculoPrevio(Values);
      return;
    }
    else {
      Values.progs = Progs;
      Values.id = RouteParams.id;
      console.log(Values);
      save(Values);
      // Enviar progs a servidor

    }
  }

  const save = async (Values) => {
    try {
      let Res = await Http.post('recurso-disponibilidad/save/disponibilidad', Values);
      console.log(Res);
      if (!Res.error) {
        message.info('Disponibilidad guardada');
        History.goBack();
      }
    } catch (error) {
      message.warn('Error al guardar');
      console.log(error);
    }
  }

  const calculoPrevio = ({ minutosbloque, duracionrecorrido, horainicial, horafinal }) => {
    // console.log(horainicial)
    calcularTotales();
    let hor1 = parseInt(moment(horainicial).format('H'));
    let hor2 = parseInt(moment(horafinal).format('H'));
    let min1 = parseInt(moment(horainicial).format('m')) / 60;
    let min2 = parseInt(moment(horafinal).format('m')) / 60;
    hor1 += min1;
    hor2 += min2;
    // console.log(hor1, hor2)
    if (hor2 <= hor1)
      return message.warn('La hora final no debe ser mayor que la hora inicial');
    let chora = 0, cdia = 0, tbloque = 0;
    let asientos = ProgsTotales.capacidad;
    chora = Math.round((asientos) * (60 / duracionrecorrido), 0)
    cdia = chora * (hor2 - hor1)
    tbloque = Math.round(asientos * (60 / duracionrecorrido) / (60 / minutosbloque), 0)
    console.log(chora, cdia, tbloque);
    setFormData(Form.Patch({ ...FormData }, { chora, cdia, tbloque }))
  }

  const addProg = () => {
    Progs.push({
      cantidad: 0,
      capacidad: 0
    })
    setProgs([...Progs]);
  }
  const onProgChange = (pi, value) => {
    Progs[pi] = { ...Progs[pi], ...value };
    setProgs([...Progs]);
    // calcularTotales();
  }
  const onProgDelete = (pi) => {
    Progs[pi].deleted = true;
    setProgs([...Progs]);
  }
  const calcularTotales = () => {
    ProgsTotales.cantidad = 0;
    ProgsTotales.capacidad = 0;
    // console.log(Progs)
    Progs.forEach((progs) => {
      if (progs.deleted) return;
      ProgsTotales.cantidad += progs.cantidad;
      ProgsTotales.capacidad += (progs.capacidad * progs.cantidad);
    })
    setProgsTotales({ ...ProgsTotales })
  }

  return (
    <div className='disponibilidad'>
      <h2>Disponibilidad</h2>
      <FormComponent
        data={FormData}
        onSubmit={onSubmit}
        onValueChanged={setFormData}
      >
        <div className='programacion' style={{ borderColor: Theme.primary }}>
          <div style={{ textAlign: 'right' }}><Button onClick={addProg}>Agregar</Button></div>
          {
            Progs.length > 0 && (
              <div>
                <div className='row'>
                  <div className='col-sm-5' style={{ textAlign: 'center' }}>Cantidad</div>
                  <div className='col-sm-5' style={{ textAlign: 'center' }}>Capacidad</div>
                  <div className='col-sm-2'></div>
                </div>
                {Progs.map((prog, pi) => (!prog.deleted &&
                  <Prog
                    key={pi}
                    capacidad={prog.capacidad}
                    cantidad={prog.cantidad}
                    onChange={(val) => onProgChange(pi, val)}
                    onDelete={() => onProgDelete(pi)}
                  />
                ))}
                <div className='row prog'>
                  <div className='col-sm-5' style={{ textAlign: 'right' }}>Total: {ProgsTotales.cantidad}</div>
                  <div className='col-sm-5' style={{ textAlign: 'right' }}>Total: {ProgsTotales.capacidad}</div>
                  <div className='col-sm-2' style={{ textAlign: 'right' }}></div>
                </div>
              </div>
            )
          }
        </div>
      </FormComponent>
    </div>
  )
}

const Prog = ({ cantidad, capacidad, onChange, onDelete }) => {
  const _onChange = (value, caso) => {
    // console.log(value);
    if (caso === 1)
      onChange({ capacidad, cantidad: value })
    else if (caso === 2)
      onChange({ cantidad, capacidad: value })
  }
  return (
    <div className='row prog'>
      <div className='col-sm-5'><InputNumber type='number' value={cantidad} onChange={(val) => _onChange(val, 1)} style={{ width: '100%' }} /></div>
      <div className='col-sm-5'><InputNumber type='number' value={capacidad} onChange={(val) => _onChange(val, 2)} style={{ width: '100%' }} /></div>
      <div className='col-sm-2' style={{ textAlign: 'center' }}>
        <Button icon={<DeleteOutlined />} shape='circle' onClick={onDelete}></Button>
      </div>
    </div>
  )
}
