import React, { useState, useEffect } from 'react'
import { Form, FormComponent } from 'components/Form'
import { Button, Select, Modal, message, Spin } from 'antd';
import '../Proceso.css'
import Theme from 'Theme';
import Http from 'Http';
// import ProductoForm from 'components/Catalogos/Productos/Producto'
import AreaForm from 'components/Catalogos/Areas/Area'
import Global from 'Global';
import PagosArea from './PagosArea';
import FormaPagoComponent from './FormaPago';
import Numerar from 'components/Catalogos/Recintos/NumerarCalendario';
import { DollarCircleFilled, PlusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
const { confirm } = Modal;


export default function Pagos({ onNext, data = {}, fill = {}, onData = () => { }, loading = false, saveButton = false }) {

  const botones = saveButton ?  ['Siguiente', 'Guardar'] : ['Siguiente'];
  const [FormData, setFormData] = useState(
    Form.Group([
      Form.Field('textarea', 'condiciones', 'Condiciones', 12, { required: false, maxLength: 50 }),
      /* Form.Field('selectsearchm', 'FormasPago', 'Formas de pago', 6, { required: true }), */
      Form.Field('checkbox', 'cambiolocalidad', 'Permitir cambios de localidad', 6, { required: false }),
      Form.Field('number', 'comision', 'Comisión', 6, { required: false }),
      Form.Field('selectsearchm', 'FormasEnvio', 'Formas de entrega', 12, { required: false }),
    ], botones, null, false)
  );

  const [Areas, setAreas] = useState([]);
  const [FormasPago, setFormasPago] = useState([]);
  const [formaPago, setFormaPago] = useState(null);
  const [selectedFormasPago, setSelectedFormasPago] = useState([]);
  const [Area, setArea] = useState(undefined);
  const [ModalArea, setModalArea] = useState(false)
  const [PreciosAreas, setPreciosAreas] = useState([])
  const [ModalProducto, setModalProducto] = useState({ visible: false, modo: 'edicion', pai: -1, producto: {} })
  const [loadingAreas, setLoadingAreas] = useState(true)
  const [ModalNumeracion, seModalNumeracion] = useState({ visible: false, idarea: '', modo: 'edicion', filas: 0, columnas: 0 })
  const [ModalFormaPago, setModalFormaPagos] = useState({ visible: false, modo: 'edicion', formaPago: {} })
  const [ModalGanancia, setModalGanancias] = useState({ visible: false })

  useEffect(() => {
    if (data) {
      setFormData(Form.Patch({ ...FormData }, { ...data }))
      if (data.precios) {
        setPreciosAreas(data.precios);
        setLoadingAreas(false)
      }
      if (data.FormasPago) {
        setSelectedFormasPago(data.FormasPago);
      }
    }
    if (fill) {
      setFormData(
        Form.FieldOptionsGroup({ ...FormData }, [
          // Form.FOG_Field('FormasPago', fill.formas_pago, 'FORMAPAGO', 'DESCRIPCION'),
          Form.FOG_Field('FormasEnvio', fill.formas_envio, 'IDENVIO', 'DESCRIPCION'),
        ])
      )
      if (fill.formas_pago) {
        setFormasPago(fill.formas_pago);
      }
    }
    formPreciosFill();
    propSettings();
    // eslint-disable-next-line
  }, [fill]);

  const formPreciosFill = async (pred = undefined) => {
    try {
      let Res = await Http.get(`recinto/${data.idrecinto || '15'}/areas`);
      // console.log(Res)
      if (!Res.error) {
        setAreas(Res.data)
        if (pred)
          setArea(pred)
      }
    } catch (error) {

    }
  }

  const propSettings = () => {
    if (Global.PROP === "BPMEX") {
      setFormData({
        ...Form.FieldModGroup(FormData, [
          Form.FMG_Field("condiciones", "Disabled", true),
          Form.FMG_Field("FormasEnvio", "Disabled", true),
          // Form.FMG_Field("FormasPago", "Disabled", true),
          Form.FMG_Field("cambiolocalidad", "Disabled", true),
          Form.FMG_Field("comision", "Disabled", true),
        ])
      });
    }
  }

  const onSubmit = (Values, Button) => {
    console.log(`[Submit] Botón ${Button}, valores: `, Values);
    onNext({ 
      ...Values, 
      precios: PreciosAreas,
      FormasPago: selectedFormasPago
    }, 
    Button === 2);
  }

  const addArea = () => {
    setModalArea(true);
  }
  const onAddArea = (saved, data) => {
    setModalArea(false);
    if (saved) {
      formPreciosFill(data.idarea)
      message.info('El área fue añadida a la lista')
    }
  }

  const addPrecioArea = async () => {
    if (!Area) return;
    if (PreciosAreas.some((precioArea) => !precioArea.deleted && precioArea.idarea === Area))
      return message.warn('El área ya está en los precios')
    try {
      let Res = await Http.get(`area/${Area}`);
      // console.log(Res);
      PreciosAreas.push({
        ...Res.data,
        productos: [],
        deleted: false,
      })
      setPreciosAreas([...PreciosAreas]);
    } catch (error) {

    }
    // PreciosAreas.push({
    //   idarea: Area,
    // })
  }

  const addFormaArea = async () => {
    if (!formaPago) return;
    const forma = FormasPago.find(forma => forma.FORMAPAGO === formaPago);
    if (selectedFormasPago.some((forma) => !forma.deleted && forma.FORMAPAGO === formaPago)) {
      return message.warn(`La Forma de Pago ${forma.DESCRIPCION} ya esta seleccionada`);
    }
    try {
      selectedFormasPago.push({
        ...forma,
        fec_ini: data.fec_ini_vta ? data.fec_ini_vta : new Date(),
        fec_fin: data.fec_fin_vta ? data.fec_fin_vta : moment().add(1, 'y').toDate(),
        forzar: false,
        deleted: false,
      })
      setSelectedFormasPago([...selectedFormasPago]);
    } catch (error) {
      console.error(error);
    }
  }

  const onDeletePrecioArea = (precioArea, pai) => {
    PreciosAreas[pai].deleted = true;
    confirm({
      title: 'Borrar Area',
      // icon: <ExclamationCircleOutlined />,
      content: 'Al borrar se eliminaran los productos relacionados y las butacas',
      onOk() {
        setPreciosAreas([...PreciosAreas]);
        message.info('Los cambios se veran reflejados al momento de guardar', 10)
      },
      onCancel() {},
    });
  }
  const onNumerar = (precioArea, filas, columnas) => {
    seModalNumeracion({ visible: true, idarea: precioArea.idarea, filas, columnas })
  }
  const addProductoToPrecioArea = (precioArea, pai) => {
    setModalProducto({
      visible: true, modo: 'creacion', pai, producto: {
        idarea: precioArea.idarea,
        idproducto: undefined,
        precio: undefined,
        folio_precio: 0,
        costoboleto: 0,
        costoservicio: undefined,
        costotc: undefined,
        alimentos: 0,
        hospedaje: 0,
      }
    })
    // setPreciosAreas([...PreciosAreas]);
  }
  const onProductoPrecioAreaSave = (saved, producto) => {
    // console.log(producto)
    // const { modo, pai }
    setModalProducto({ ...ModalProducto, visible: false, modo: '' })
    if (ModalProducto.modo === 'creacion') {
      // if (PreciosAreas[ModalProducto.pai].productos.some((prod) => !prod.deleted && prod.idproducto.toString() === producto.idproducto.toString()))
      //   return message.warn('El producto ya está en el área')
      PreciosAreas[ModalProducto.pai].productos.push(producto);
      setPreciosAreas([...PreciosAreas]);
    }
    else if (ModalProducto.modo === 'edicion') {
      // if (PreciosAreas[ModalProducto.pai].productos.some((prod, spi) => spi !== ModalProducto.pi && !prod.deleted && prod.idproducto.toString() === producto.idproducto.toString()))
      //   return message.warn('El producto cambió a uno que ya está en el área')
      PreciosAreas[ModalProducto.pai].productos[ModalProducto.pi] = producto;
      setPreciosAreas([...PreciosAreas]);
    }
  }
  const onPrecioDelete = (pai, pi) => {
    // console.log('Delete', pai, pi)
    PreciosAreas[pai].productos[pi].deleted = true;
    setPreciosAreas([...PreciosAreas]);
    // setPreciosAreas(PreciosAreas);
  }
  const onPrecioEdit = (pai, pi) => {
    // console.log('Edit', pai, pi)
    setModalProducto({
      visible: true, modo: 'edicion', pai, pi, producto: {
        ...PreciosAreas[pai].productos[pi]
      }
    })
  }
  const onFormaPagoEdit = (pai) => {
    setModalFormaPagos({
      visible: true, 
      modo: 'edicion', 
      pai,
      formaPago: {
        ...selectedFormasPago[pai]
      }
    })
  }
  const onFormaPagoSave = (saved, formaPago) => {
    setModalFormaPagos({ ...ModalFormaPago, visible: false, modo: '' })
    selectedFormasPago[ModalFormaPago.pai] = { 
      ...selectedFormasPago[ModalFormaPago.pai],
      ...formaPago
    };
    setSelectedFormasPago([...selectedFormasPago]);
  }

  const onValueChange = (value) => {
    const values = Form.Values(value);
    setFormData(value);
    values.FormData = selectedFormasPago;
    onData(values);
  }

  const onDeleteFormaPago = (pai) => {
    selectedFormasPago[pai].deleted = true;
    confirm({
      title: 'Eliminar Forma Pago',
      // icon: <ExclamationCircleOutlined />,
      content: 'Se va a eliminar la forma de pago',
      onOk() {
        setSelectedFormasPago([...selectedFormasPago]);
        message.info('Los cambios se veran reflejados al momento de guardar', 10)
      },
      onCancel() {},
    });
  }

  const onCalcularGanancia = (area) => {
    setModalGanancias({ visible: true, idarea: area ? area.idarea : 'all' })
  }

  return (
    <div className='pagos'>
      <FormComponent
        data={FormData}
        onSubmit={onSubmit}
        onValueChanged={onValueChange}
        loading={loading}
      >

        <div className='precios' style={{ borderColor: Theme.primary }}>
          <h4 style={{ color: Theme.primary }}>Formas de Pago</h4>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Select value={formaPago}
                onChange={(val) => setFormaPago(val)} 
                placeholder='Seleccione Forma de Pago'
                className='forma-select col-sm-8'
              >
                {
                  FormasPago.map((Forma, Pi) => (
                    <Select.Option key={Forma.FORMAPAGO} value={Forma.FORMAPAGO}>{Forma.DESCRIPCION}</Select.Option>
                  ))
                }
              </Select>
              <Button 
                className='col-sm-4' 
                icon={<PlusOutlined />} 
                onClick={addFormaArea}>
                Agregar Forma de Pago
              </Button>
          </div>
          {
              selectedFormasPago.length > 0 && (
                <React.Fragment>
                  {
                    selectedFormasPago.map((precioArea, pai) => (!precioArea.deleted &&
                      <FormaPagoComponent
                        key={precioArea.FORMAPAGO}
                        precioArea={precioArea}
                        onDelete={() => onDeleteFormaPago(pai)}
                        onEditPrecio={() => onFormaPagoEdit(pai)}
                      />
                    ))
                  }
                </React.Fragment>
              )
            }
            {
              selectedFormasPago.filter(area => !area.deleted).length === 0 && !loadingAreas && (
                <div style={{ width: '100%', textAlign: 'center', border: '1px solid', borderRadius: 4, marginTop: 10, borderColor: Theme.primary, color: Theme.primary }}>
                  Sin Formas de Pago
                </div>
              )
            }
            {
              selectedFormasPago.length === 0 && loadingAreas && (
                <div style={{ width: '100%', textAlign: 'center', border: '1px solid', borderRadius: 4, marginTop: 10, borderColor: Theme.primary, color: Theme.primary }}>
                  Cargando Formas de Pago...
                </div>
              )
            }
        </div>

        <div className='precios' style={{ borderColor: Theme.primary }}>
          <h4 style={{ color: Theme.primary }}>Precios de áreas</h4>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
            <div>
              <Button icon={<PlusCircleOutlined />} onClick={addArea}>Crear Área</Button>
            </div>
            <div>
              <Button icon={<DollarCircleFilled />} onClick={() => onCalcularGanancia()}>Ingreso maximo del evento</Button>
            </div>
            <div>
              <Select value={Area} onChange={(val) => setArea(val)} style={{ width: 150 }} placeholder='Seleccione Área'>
                {
                  Areas.map((Area, Pi) => (
                    <Select.Option key={Area.idarea} value={Area.idarea}>{Area.area}</Select.Option>
                  ))
                }
              </Select>
              <Button icon={<PlusOutlined />} onClick={addPrecioArea}>Agregar Área al evento</Button>
            </div>
          </div>

          <div>
            {
              PreciosAreas.length > 0 && (
                <React.Fragment>
                  {
                    PreciosAreas.map((precioArea, pai) => (!precioArea.deleted &&
                      <PagosArea
                        key={precioArea.idarea}
                        numerado={precioArea.numerado}
                        label={precioArea.area}
                        productos={precioArea.productos}
                        onDelete={() => onDeletePrecioArea(precioArea, pai)}
                        onNumerar={() => window.open(`${window.location.protocol}//${window.location.host}/#/Calendario/${data.idcalendario}/Numeracion/${precioArea.idarea}`, '_blank')}
                        onCalcularGanancia={() => onCalcularGanancia(precioArea)}
                        onAdd={() => addProductoToPrecioArea(precioArea, pai)}
                        onDeletePrecio={(producto, pi) => onPrecioDelete(pai, pi)}
                        onEditPrecio={(producto, pi) => onPrecioEdit(pai, pi)}
                      />
                    ))
                  }
                </React.Fragment>
              )
            }
            {
              PreciosAreas.filter(area => !area.deleted).length === 0 && !loadingAreas && (
                <div style={{ width: '100%', textAlign: 'center', border: '1px solid', borderRadius: 4, marginTop: 10, borderColor: Theme.primary, color: Theme.primary }}>
                  Sin áreas
                </div>
              )
            }
            {
              PreciosAreas.length === 0 && loadingAreas && (
                <div style={{ width: '100%', textAlign: 'center', border: '1px solid', borderRadius: 4, marginTop: 10, borderColor: Theme.primary, color: Theme.primary }}>
                  Cargando áreas...
                </div>
              )
            }
          </div>
        </div>
      </FormComponent>

      <Modal
        title="Agregar área"
        visible={ModalArea}
        onOk={() => setModalArea(false)}
        onCancel={() => setModalArea(false)}
        footer={<div />}
        width='90%'
        destroyOnClose={true}
      >
        <AreaForm
          onSave={onAddArea}
          idrecinto={data.idrecinto || '16'}
          omitirParamId={true}
        />
      </Modal>

      <Modal
        title="Producto"
        visible={ModalProducto.visible}
        onOk={() => setModalProducto({ ...ModalProducto, visible: false })}
        onCancel={() => setModalProducto({ ...ModalProducto, visible: false })}
        footer={<div />}
        width='50%'
        destroyOnClose={true}
      >
        <Producto
          // onChange={onProductoPrecioAreaChange}
          producto={ModalProducto.producto}
          idpropietario={data.idpropietario || 'AREMA'}
          onSave={onProductoPrecioAreaSave}
        />
      </Modal>

      <Modal
        title="Butacas"
        visible={ModalNumeracion.visible}
        onOk={() => seModalNumeracion({ ...ModalNumeracion, visible: false })}
        onCancel={() => seModalNumeracion({ ...ModalNumeracion, visible: false })}
        footer={<div />}
        width='90%'
        destroyOnClose={true}
      >
        <Numerar
          modoVenta
          area={ModalNumeracion ? ModalNumeracion.idarea : null}
          calendario={data.idcalendario}
          filas={ModalNumeracion.filas}
          columnas={ModalNumeracion.columnas}
        />
      </Modal>

      <Modal
        title="Forma de Pago"
        visible={ModalFormaPago.visible}
        onOk={() => setModalFormaPagos({ ...ModalFormaPago, visible: false })}
        onCancel={() => setModalFormaPagos({ ...ModalFormaPago, visible: false })}
        footer={<div />}
        width='50%'
        destroyOnClose={true}
      >
        <FormaPagoFormComponent
          formaPago={ModalFormaPago.formaPago}
          idpropietario={data.idpropietario || 'AREMA'}
          onSave={onFormaPagoSave}
        />
      </Modal>

      <Modal
        title="Maxima Ganancia"
        visible={ModalGanancia.visible}
        onOk={() => setModalGanancias({ ...ModalGanancia, visible: false })}
        onCancel={() => setModalGanancias({ ...ModalGanancia, visible: false })}
        footer={<div />}
        destroyOnClose={true}
      >
        <GananciaComponent
          idarea={ModalGanancia.idarea}
          idcalendario={data.idcalendario}
        />
      </Modal>

    </div>
  )
}

// const PrecioArea = ({ label = 'Prueba', productos = [{ producto: 'Prueba', precio: 10.0 }, { producto: 'Prueba 2', precio: 110.0 }], onDelete, onAdd, onEditPrecio, onDeletePrecio }) => {
//   // console.log(productos.length)
//   return (
//     <div className='precio-area'>
//       <div className='row contenedor'>
//         <div className='col-sm-2 centrado'>
//           {label}
//         </div>
//         <div className='col-sm-8' style={{ overflow: 'auto' }}>
//           {
//             productos.length > 0 && (
//               <table>
//                 <thead>
//                   <tr>
//                     <td>Producto</td>
//                     <td>Precio</td>
//                     <td>C. por boleto</td>
//                     <td>C. por servicio</td>
//                     <td>C. por tc</td>
//                     <td>Alimentos</td>
//                     <td>Hospedaje</td>
//                     <td></td>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {
//                     productos.map((producto, pi) => (!producto.deleted &&
//                       <PrecioAreaProducto
//                         key={`${producto.producto}-${pi}`}
//                         label={producto.producto}
//                         precio={producto.precio}
//                         otrosPrecios={producto}
//                         onEdit={() => onEditPrecio(producto, pi)}
//                         onDelete={() => onDeletePrecio(producto, pi)}
//                       />
//                     ))
//                   }
//                 </tbody>
//               </table>
//             )
//           }
//           {
//             productos.length === 0 && (
//               <div style={{ textAlign: 'center' }}>Sin productos</div>
//             )
//           }
//         </div>
//         <div className='col-sm-2 centrado'>
//           <Button htmlType='button' shape='circle' icon='plus' onClick={onAdd}></Button>
//           <Button htmlType='button' shape='circle' icon='delete' onClick={onDelete}></Button>
//         </div>
//       </div>
//     </div>
//   )
// }

// const PrecioAreaProducto = ({ label, precio, onDelete, onEdit, otrosPrecios }) => {
//   return (
//     <React.Fragment>
//       <tr>
//         <td>{label}</td>
//         <td>${precio}</td>
//         <td>${otrosPrecios.costoboleto}</td>
//         <td>${otrosPrecios.costoservicio}</td>
//         <td>${otrosPrecios.costotc}</td>
//         <td>${otrosPrecios.alimentos}</td>
//         <td>${otrosPrecios.hospedaje}</td>
//         <td>
//           <Button icon='edit' shape='circle' onClick={onEdit}></Button>
//           <Button icon='delete' shape='circle' onClick={onDelete}></Button>
//         </td>
//       </tr>
//     </React.Fragment>
//     // <div className='precio-area-prodcuto' style={{ borderBottomColor: Theme.primary }}>
//     //   <div>
//     //     <span>{label}</span>
//     //   </div>
//     //   <div>
//     //     <span style={{ fontSize: 13 }}>$</span><span>{precio}</span>
//     //   </div>
//     //   <div>
//     //     <Button icon='edit' shape='circle' onClick={onEdit}></Button>
//     //     <Button icon='delete' shape='circle' onClick={onDelete}></Button>
//     //   </div>
//     // </div>
//   )
// }



export function Producto({ idpropietario = 'AREMA', producto = {}, onSave }) {
  const [Productos, setProductos] = useState([])
  // let ProductosInfo = [];
  const [FormData, setFormData] = useState(
    Form.Group([
      Form.Hidden('idprecio', null),
      Form.Field('select', 'idproducto', 'Producto', 12, { required: true }),
      Form.Field('number', 'precio', 'Precio', 12, { required: true }),
      Form.Field('number', 'costoboleto', 'Cargo por boleto', 12, { required: true }),
      Form.Field('number', 'costoservicio', 'Cargo por servicio', 12, { required: true }),
      Form.Field('number', 'costotc', 'Cargo x tc', 12, { required: true }),
      Form.Field('number', 'alimentos', 'Alimentos', 12, { required: true }),
      Form.Field('number', 'hospedaje', 'Hospedaje', 12, { required: true }),
      Form.Field('number', 'mincantventa', 'Min. Cantidad Venta', 6, { min: 1 }, [], 1),
      Form.Field('number', 'maxcantventa', 'Max. Cantidad Venta', 6, { max: 10 }, [], 10),
      Form.Field('datetime', 'fec_ini', 'Fecha de inicio', 12, { required: false }),
      Form.Field('datetime', 'fec_fin', 'Fecha de fin', 12, { required: false }),
    ], ['Guardar'], null, false)
  );
  // console.log(producto)

  useEffect(() => {
    getProductos();
    propSettings();
    // eslint-disable-next-line
  }, [])

  const propSettings = () => {
    if (Global.PROP === "BPMEX") {
      setFormData({
        ...Form.FieldModGroup(FormData, [
          Form.FMG_Field("costoboleto", "Disabled", true),
          Form.FMG_Field("costoservicio", "Validators", { required: false }),
          Form.FMG_Field("costoservicio", "Disabled", true),
          Form.FMG_Field("costotc", "Validators", { required: false }),
          Form.FMG_Field("costotc", "Disabled", true),
          Form.FMG_Field("alimentos", "Disabled", true),
          Form.FMG_Field("hospedaje", "Disabled", true),
        ])
      });
    }
  }

  useEffect(() => {
    console.log(producto)
    setFormData(Form.Patch({ ...FormData }, { ...producto, idproducto: producto.idproducto ? producto.idproducto.toString() : undefined }))
    // eslint-disable-next-line
  }, [producto])

  const onSubmit = (Values, Button) => {
    console.log(Productos, Values)
    Values.producto = Productos.find((prod) => prod.idproducto.toString() === Values.idproducto.toString()).producto;
    onSave(true, Values)
  }

  const getProductos = async () => {
    try {
      idpropietario = idpropietario.trim()
      let Res = await Http.get(`propietario/${idpropietario}/productos`);
      if (!Res.error) {
        setProductos(Res.data);
        setFormData(
          Form.FieldOptionsGroup({ ...FormData }, [
            Form.FOG_Field('idproducto', Res.data, 'idproducto', 'producto'),
          ])
        )
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div>
      <FormComponent
        data={FormData}
        onSubmit={onSubmit}
        onValueChanged={setFormData}
        label={true}
      />
    </div >
  )
}

export function FormaPagoFormComponent({ formaPago = {}, onSave }) {
  const [FormData, setFormData] = useState(
    Form.Group([
      Form.Field('datetime', 'fec_ini', 'Fecha de inicio', 12, { required: false }),
      Form.Field('datetime', 'fec_fin', 'Fecha de fin', 12, { required: false }),
      Form.Field('checkbox', 'forzar', 'Forzar', 12, { required: false }),
    ], ['Guardar'], null, false)
  );

  useEffect(() => {
    setFormData(Form.Patch({ ...FormData }, { ...formaPago }))
    // eslint-disable-next-line
  }, [formaPago])

  const onSubmit = (Values, Button) => {
    onSave(true, Values)
  }

  return (
    <div style={{ height: 300 }} >
      <FormComponent
        data={FormData}
        onSubmit={onSubmit}
        onValueChanged={setFormData}
        label={true}
      />
    </div >
  )
}


export function GananciaComponent({ idcalendario, idarea }) {
  const [ganancia, setGanancia] = useState(0);
  const [loading, setLoading] = useState(true);

   const calcular = async () => {
    try {
      let Res = await Http.get(`calendario/area/ganancia?idcalendario=${idcalendario}&idarea=${idarea}`);
      if (Res) {
        setGanancia(Res.data.total);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
   }

  useEffect(() => {
    calcular();
    // eslint-disable-next-line
  }, []);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      {loading ?
        <Spin></Spin>
        :
        <div style={{ fontWeight: 'bold', fontSize: 20 } }>
          Monto maximo posible: ${ganancia}
        </div>
      }
      <div style={{ fontWeight: 'bold' }} >Nota: no se incluyen las butacas en estado 6 (bloqueadas)</div>
    </div >
  )
}