import React, { useState } from 'react'
import { Table, TableComponent } from 'components/Table'

export default function Bancos() {
  const [TableData] = useState(
    Table.Data([
      Table.Field('idpromotor', 'Promotor'),
      Table.Field('nombre', 'Nombre'),
    ], [
      ...Table.DefaultActions(),
    ],
      Table.Config('Promotor', 'nombre', 'idpromotor')
    )
  )
  
  return (
    <div>
      <h2>Promotores</h2>
      <TableComponent
        data={TableData}
      />
    </div>
  )
}
