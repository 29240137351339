import React, { useState, useEffect } from 'react'
import { Form, FormComponent } from 'components/Form'
import Http from 'Http';
import { message } from 'antd';
import { useHistory, useParams } from "react-router-dom";
import BackButton from 'components/BackButton/BackButton';

export default function Usuario() {

  const History = useHistory();
  const Params = useParams();
  const [FormData, setFormData] = useState(
    Form.Group([
      Form.ID('idusuario'),
      Form.Field('selectsearch', 'idpropietario', 'Propietario', 4, { required: true }),
      Form.Field('text', 'usuario', 'Usuario', 4, { required: true }),
      Form.Field('text', 'clavecorta', 'Clave corta', 4, { required: true }),
      Form.Field('password', 'password', 'Contraseña Web', 6, { required: true }),
      Form.Field('password', 'password2', 'Confirmar Contraseña Web', 6, { required: true }),
      Form.Field('text', 'nombre', 'Nombre', 4, { required: true }),
      Form.Field('text', 'puesto', 'Puesto', 4, { required: true }),
      Form.Field('select', 'nivel', 'Nivel', 4, { required: true }, [
        { Value: '70', Label: 'Will Call' },
        { Value: '60', Label: 'Encargado' },
        { Value: '50', Label: 'Taquilla' },
        { Value: '40', Label: 'Supertaquilla' },
        { Value: '20', Label: 'Supervisor' },
        /* { Value: 10, Label: 'Administrador' },
        { Value: 0, Label: 'Arema Ticket' }, */
      ]),
    ], ['Guardar'], 'usuario', false)
  );

  useEffect(() => {
    fillForm();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (Params.id) {
      let newFormData = Form.FieldMod({ ...FormData }, 'password', 'Hidden', true);
      newFormData = Form.FieldMod({ ...newFormData }, 'password2', 'Hidden', true);
      newFormData = Form.FieldMod({ ...newFormData }, 'password', 'Validators', { required: false });
      newFormData = Form.FieldMod({ ...newFormData }, 'password2', 'Validators', { required: false });
      setFormData({ ...newFormData });
    }
    // eslint-disable-next-line
  }, [Params]);

  const fillForm = async () => {
    try {
      let Res = await Http.get('productor/form');
      if (!Res.error) {
        setFormData(Form.FieldOptions({ ...FormData }, 'idpropietario', Res.data.propietarios, 'idpropietario', 'descripcion'))
      }
    } catch (error) {

    }
  }

  const onSubmit = async (Values, Button) => {
    console.log(`[Submit] Botón ${Button}, valores: `, Values)
    if (Values.password !== Values.password2) {
      message.error('Las contraseñas no coinciden')
      return;
    }
    if(Params.id) Values.id = Params.id
    try {
      let Res = await Http.post(`usuario/save/dos`, { ...Values });
      console.log(Res)
      if (!Res.error) {
        // console.log('Guardado');
        History.goBack()
        message.info(`Guardado`);
      }
      else {
        message.warn(`Error: ${Res.message}`);
      }
    } catch (error) {
      message.warn(`Error al guardar`);
      console.log(`Error al guardar: `, error)
    }
    return;

  }

  return (
    <div>
      <BackButton />
      <h2>Usuario</h2>
      <FormComponent
        data={FormData}
        onSubmit={onSubmit}
        onValueChanged={setFormData}
      />
    </div>
  )
}
