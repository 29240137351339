import {
  DeleteOutlined,
  EditOutlined,
  NumberOutlined,
  PlusOutlined,
  DollarCircleOutlined,
} from "@ant-design/icons";
import { Button, Divider } from "antd";
import moment from "moment";
import React, { useMemo } from "react";
import Theme from "Theme";
import "./Pagos.css";

export default function PagosArea({
  label = "",
  productos = [],
  onDelete = () => {},
  onNumerar = () => {},
  onCalcularGanancia = () => {},
  onAdd = () => {},
  onDeletePrecio = () => {},
  onEditPrecio = () => {},
  numerado = false,
}) {
  return (
    <div className="pagos-area">
      <div className="pa-header">
        <h3>{label}</h3>
        <div className="pa-acciones">
          <Button
            htmlType="button"
            size="small"
            icon={<PlusOutlined />}
            onClick={onAdd}
          >
            Agregar Producto
          </Button>
          <Button
            htmlType="button"
            size="small"
            icon={<DeleteOutlined />}
            onClick={onDelete}
          >
            Eliminar área
          </Button>
          {numerado && (
            <Button
              htmlType="button"
              size="small"
              icon={<NumberOutlined />}
              onClick={onNumerar}
            >
              Butacas
            </Button>
          )}
          <Button
            htmlType="button"
            size="small"
            icon={<DollarCircleOutlined />}
            onClick={onCalcularGanancia}
          >
            Ganancia Maxima
          </Button>
        </div>
      </div>
      <div className="pa-productos">
        {productos.map(
          (producto, pi) =>
            !producto.deleted && (
              <PagosAreaProducto
                key={`${producto.producto}-${pi}`}
                label={producto.producto}
                precio={producto.precio}
                otrosPrecios={producto}
                onEdit={() => onEditPrecio(producto, pi)}
                onDelete={() => onDeletePrecio(producto, pi)}
              />
            )
        )}
      </div>
      <Divider />
    </div>
  );
}

const PagosAreaProducto = ({
  label,
  precio,
  onDelete,
  onEdit,
  otrosPrecios,
}) => {
  const fechas = useMemo(() => {
    return {
      inicio: otrosPrecios.fec_ini
        ? moment(otrosPrecios.fec_ini).format("DD/MM/YYYY HH:mm")
        : null,
      fin: otrosPrecios.fec_fin
        ? moment(otrosPrecios.fec_fin).format("DD/MM/YYYY HH:mm")
        : null,
    };
  }, [otrosPrecios]);

  return (
    <div className="pa-producto">
      <div className="pa-producto-acciones">
        <Button
          icon={<EditOutlined />}
          size="small"
          shape="circle"
          onClick={onEdit}
        ></Button>
        <Button
          icon={<DeleteOutlined />}
          size="small"
          shape="circle"
          onClick={onDelete}
        ></Button>
      </div>
      <div className="pa-label">
        <span style={{ color: Theme.primary }}>{label}</span>
      </div>
      <div className="pa-precios">
        <div className="pa-precio">
          <p style={{ borderColor: Theme.primary }}>
            {precio}
            <span>Precio</span>
          </p>
        </div>
        <div className="pa-precio">
          <p style={{ borderColor: Theme.primary }}>
            {otrosPrecios.costoboleto}
            <span>Boleto</span>
          </p>
        </div>
        <div className="pa-precio">
          <p style={{ borderColor: Theme.primary }}>
            {otrosPrecios.costoservicio}
            <span>Servicio</span>
          </p>
        </div>
        <div className="pa-precio">
          <p style={{ borderColor: Theme.primary }}>
            {otrosPrecios.costotc}
            <span>TC</span>
          </p>
        </div>
        <div className="pa-precio">
          <p style={{ borderColor: Theme.primary }}>
            {otrosPrecios.alimentos}
            <span>Alimentos</span>
          </p>
        </div>
        <div className="pa-precio">
          <p style={{ borderColor: Theme.primary }}>
            {otrosPrecios.hospedaje}
            <span>Hospedaje</span>
          </p>
        </div>
        <div className="pa-precio">
          <p style={{ borderColor: Theme.primary }}>
            {fechas.inicio || <i>Sin fecha</i>}
            <span>Inicio</span>
          </p>
        </div>
        <div className="pa-precio">
          <p style={{ borderColor: Theme.primary }}>
            {fechas.fin || <i>Sin fecha</i>}
            <span>Fin</span>
          </p>
        </div>
      </div>
    </div>
  );
};
