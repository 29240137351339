import { Button, message } from 'antd';
import Http from 'Http'
import React, { useEffect, useMemo, useState } from 'react'
import Area from './Area';

const validarEventosRequest = async (eventos) => {
  try {
    let res = await Http.post(`calendario/cambio-precios/validar`, { eventos });
    console.log(res)
    if (res.error) return false;
    return res.data;
  } catch (error) {
    console.log(error)
  }
  return false;
}

const guardarPreciosRequest = async (eventos, areas) => {
  try {
    let res = await Http.post(`calendario/cambio-precios/guardar`, { eventos, areas })
    console.log(res);
    if (res.error) return false;
    return res.data;
  } catch (error) {
    console.log(error)
  }
  return false;
}

export default function CambioPrecios({
  eventos = []
}) {
  const [firstLoading, setFirstLoading] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [areas, setAreas] = useState([])
  const [diferenciasPrecios, setDiferenciasPrecios] = useState([])

  const calendarios = useMemo(() => {
    return eventos.map(evento => evento.idcalendario)
  }, [eventos])

  useEffect(() => {
    const validarEventos = async () => {
      let valido = await validarEventosRequest(eventos);
      console.log(valido)
      if (valido) {
        setFirstLoading(false);
        setAreas(valido.areas);
        setDiferenciasPrecios(valido.diferenciasPrecios);
      }
      else {
        message.error('Los eventos no son compatibles');
      }
    }
    validarEventos();
  }, [eventos])

  const onProductosAreaChange = (areaIndex, productos) => {
    setAreas(areas => {
      areas[areaIndex].productos = productos;
      return [...areas];
    })
  }

  const guardarPrecios = async () => {
    console.log(areas);
    setIsLoading(true);
    const preciosGuardados = await guardarPreciosRequest(eventos, areas);
    setIsLoading(false);
    if (!preciosGuardados) return message.error(`Error al guardar los precios`);
    message.success(`Precios actualizados en calendarios ${calendarios}`);
  }

  if (firstLoading) return <div>Cargando...</div>

  return (
    <div>
      <p>Editando precios para calendarios {calendarios.map((calendario, ci) => <span key={calendario}>{ci > 0 ? ', ' : ''}{calendario}</span>)}</p>
      {
        diferenciasPrecios.length > 0 && (
          <p><b>Advertencia:</b> Los eventos tienen las mismas áreas con los mismos productos, pero algunos con diferentes precios.</p>
        )
      }
      {
        areas.map((area, ai) => (
          <Area key={area.area} nombre={area.area} productos={area.productos} onProductosChange={(productos) => onProductosAreaChange(ai, productos)} />
        ))
      }
      <div style={{ textAlign: 'right' }}>
        <Button disabled={isLoading} onClick={() => guardarPrecios()} type='primary'>Guardar precios</Button>
      </div>
    </div>
  )
}
