import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Steps, message, Button, Modal, Alert } from "antd";
import {
  ReconciliationOutlined,
  ScheduleOutlined,
  IdcardOutlined,
  WalletOutlined,
  GlobalOutlined,
} from "@ant-design/icons";
import Generales from "./Proceso/Generales";
import Http from "Http";
import Evento from "./Proceso/Evento";
import Serie from "./Proceso/Serie";
import Pagos from "./Proceso/Pagos/Pagos";
import Internet from "./Proceso/Internet";
import { Form } from "components/Form";
import Multiplicar from "./Multiplicar";
import Global from "Global";
import moment from "moment";
// import Theme from 'Theme';

let Params = [];
export default function EventoProceso() {
  const [Step, setStep] = useState(0);
  const [Fill, setFill] = useState({});
  const [Data, setData] = useState({});
  const [dataSave, setDataSave] = useState({});
  const [ModalMultiplicar, setModalMultiplicar] = useState(false);
  const [ModalValidaciones, setModalValidaciones] = useState(false);
  const [errors, setErrors] = useState([]);
  const [warnings, setWarnings] = useState([]);
  const [Loading, setLoading] = useState(false);
  const RouteParams = useParams();
  const History = useHistory();
  const stepChange = (current) => {
    if (!RouteParams.id && current > Step) return;
    setStep(current);
  };

  useEffect(() => {
    if (!RouteParams.id) fillForm();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getCalendario();
    // eslint-disable-next-line
  }, [RouteParams]);

  const getCalendario = async () => {
    const { id: idcalendario } = RouteParams;
    // console.log(idcalendario)
    if (idcalendario) {
      try {
        let Res = await Http.get(`calendario/${idcalendario}/fecha`);
        // console.log(Res);
        if (!Res.error) {
          let { data } = Res;
          data.idproductor = data.idproductor.toString();
          data.idevento = data.idevento.toString();
          data.idrecinto = data.idrecinto.toString();
          // data.puerta_ini = new Date(new Date(data.puerta_ini).getTime() + (6 * 60 * 60 * 1000))
          // data.puerta_ini = new Date(data.puerta_ini).utc Date.
          for (const key in data) {
            if (isDateA(data[key])) {
              if (typeof data[key] === "string") {
                data[key] = data[key].replace("Z", "");
              }
              const dateString = moment(data[key]).format("MM/DD/YYYY HH:mm");
              data[key] = new Date(dateString);
            }
          }
          setData(data);
          // console.log(Fill, Res.data.fill)
          setFill({ ...Fill, ...Res.data.fill });
          fillForm([], Res.data.fill);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      //   if(!Data) return;
      //   setData({...Form.Reset(Data)})
      // console.log('debug ejje')
      setData({});
    }
  };

  const isDateA = (date) => {
    let isdate = false;
    if (
      isNaN(date) &&
      typeof date !== "object" &&
      moment(date, [moment.ISO_8601], true).isValid()
    ) {
      isdate = true;
    }
    if (/^[0-9][0-9]:[0-9][0-9]$/.test(date)) {
      isdate = true;
    }
    if (
      /^(((19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])) [0-9][0-9]:[0-9][0-9])$/.test(
        date
      )
    ) {
      isdate = true;
    }
    if (date instanceof Date) {
      isdate = true;
    }
    // console.log(`Se verifica si ${date} es Date: ${isdate}`);
    return isdate;
  };

  const fillForm = async (fillParams = [], befill = {}) => {
    // console.log(Params, fillParams);
    // if(RouteParams)
    if (Global.PROP === "BPMEX") {
      Params = Form.MergeParams(Params, [["prop", Global.PROP]]);
      // Params = Form.MergeParams(Params, [["prop", "AREMA"]])
    }

    Params = Form.MergeParams(Params, fillParams);
    try {
      let Res = await Http.get("calendario/form", Params);
      // console.log(Res.data)
      if (!Res.error) {
        // console.log(Fill, Res.data)
        setFill({ ...Res.data, ...befill });
        // setFill({ ...befill, ...Res.data })
        // setFill(Res.data)
      }
    } catch (error) {}
  };

  const onNext = (data, step, submitButton = false) => {
    console.log(`[onNext] Desde step ${step} `, data);
    setData({ ...Data, ...data });
    if (step === 4 || submitButton) {
      submit({ ...Data, ...data });
      return;
    }
    setStep(step + 1);
  };

  const isDate = (date) => {
    let isdate = false;
    if (
      isNaN(date) &&
      typeof date !== "object" &&
      moment(date, [moment.ISO_8601], true).isValid()
    ) {
      isdate = true;
    }
    if (/^[0-9][0-9]:[0-9][0-9]$/.test(date)) {
      isdate = true;
    }
    if (
      /^(((19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])) [0-9][0-9]:[0-9][0-9])$/.test(
        date
      )
    ) {
      isdate = true;
    }
    if (date instanceof Date) {
      isdate = true;
    }
    // console.log(`Se verifica si ${date} es Date: ${isdate}`);
    return isdate;
  };

  const submit = async (data) => {
    setLoading(true);
    try {
      for (const key in data) {
        if (isDate(data[key])) {
          if (typeof data[key] === "string") {
            data[key] = data[key].replace("Z", "");
          }
          const dateString = moment(data[key]).format("YYYY/MM/DD HH:mm");
          data[key] = dateString;
        }
      }
      setDataSave(data);
      let Res = await Http.post(`calendario/validar/fecha`, data);
      if (Res && !Res.error) {
        if (Res.data.errors.length > 0 || Res.data.warnings.length > 0) {
          setErrors(Res.data.errors);
          setWarnings(Res.data.warnings);
          setModalValidaciones(true);
        } else {
          setErrors([]);
          setWarnings([]);
          setModalValidaciones(true);
          await submitSave()
        }
      } else {
        message.error(`Error. ${Res.errorMsg}`, 10);
      }
    } catch (error) {}
    setLoading(false);
  };

  const submitSave = async () => {
    setLoading(true);
    try {
      let Res = await Http.post(`calendario/save/fecha`, dataSave);
      // console.log(Res);
      if (!Res.error) {
        History.push(`/Calendario/${Res.data.idcalendario}`);
        message.info("Fecha guardada");
        setModalValidaciones(false);
        setStep(1);
      } else {
        message.error(`Error. ${Res.errorMsg}`, 10);
      }
    } catch (error) {}
    setLoading(false);
  };

  const onMultiplicar = () => {
    setModalMultiplicar(true);
  };

  const onMultiplicada = ({ Fechas = [] }) => {
    setModalMultiplicar(false);
    message.info(`Se multiplicó la fecha (${Fechas.length} veces)`);
  };

  const onData = (values) => {
    setData({ ...Data, ...values });
  };

  return (
    <div>
      <div
        style={{
          marginBottom: 20,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h1>FECHA</h1>
        <div>
          {RouteParams.id && (
            <Button onClick={onMultiplicar}>Multiplicar</Button>
          )}
        </div>
      </div>
      <Steps
        type="navigation"
        current={Step}
        onChange={stepChange}
        direction="horizontal"
        responsive={false}
      >
        <Steps.Step
          icon={<ReconciliationOutlined />}
          title="Generales"
          description="Datos generales del evento"
        />
        <Steps.Step
          icon={<ScheduleOutlined />}
          title="Evento"
          description="Datos del evento"
        />
        <Steps.Step
          icon={<IdcardOutlined />}
          title="Serie"
          description="Evento serie"
        />
        <Steps.Step
          icon={<WalletOutlined />}
          title="Precios"
          description="Precios y formas de pago"
        />
        <Steps.Step
          icon={<GlobalOutlined />}
          title="Internet"
          description="Información de venta por internet"
        />
      </Steps>

      <div style={{ padding: 15, backgroundColor: "transparent" }}>
        {
          {
            0: (
              <Generales
                onData={onData}
                fill={Fill}
                refill={fillForm}
                data={Data}
                onNext={(data, submit) => onNext(data, 0, submit)}
                loading={Loading}
                saveButton={RouteParams.id}
              />
            ),
            1: (
              <Evento
                onData={onData}
                fill={Fill}
                refill={fillForm}
                data={Data}
                onNext={(data, submit) => onNext(data, 1, submit)}
                loading={Loading}
                saveButton={RouteParams.id}
              />
            ),
            2: (
              <Serie
                onData={onData}
                fill={Fill}
                data={Data}
                onNext={(data, submit) => onNext(data, 2, submit)}
                loading={Loading}
                saveButton={RouteParams.id}
              />
            ),
            3: (
              <Pagos
                onData={onData}
                fill={Fill}
                data={Data}
                onNext={(data, submit) => onNext(data, 3, submit)}
                loading={Loading}
                saveButton={RouteParams.id}
              />
            ),
            4: (
              <Internet
                onData={onData}
                fill={Fill}
                data={Data}
                onNext={(data, submit) => onNext(data, 4, submit)}
                loading={Loading}
              />
            ),
          }[Step]
        }
      </div>

      <div>
        <Modal
          visible={ModalMultiplicar}
          destroyOnClose={true}
          onOk={() => setModalMultiplicar(false)}
          onCancel={() => setModalMultiplicar(false)}
          width={800}
          footer={<div />}
        >
          <Multiplicar
            calendario={RouteParams.id}
            onMultiplicada={onMultiplicada}
          />
        </Modal>

        <Modal
          visible={ModalValidaciones}
          destroyOnClose={true}
          closable={false}
          onOk={() => setModalValidaciones(false)}
          onCancel={() => setModalValidaciones(false)}
          footer={<div />}
        >
          <div>
            {errors.length === 0 && warnings.length === 0 && (
              <Alert message="Información Correcta. Guardando" type="success" showIcon />
            )}
            {errors.map((e, index) => (
              <Alert message={e} type="error" showIcon key={index} />
            ))}
            {warnings.map((w, index) => (
              <Alert message={w} type="warning" showIcon key={index} />
            ))}
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 10,
              }}
            >
              <Button type="default" style={{ marginRight: 10 }} onClick={() => setModalValidaciones(false)}>
                Cerrar
              </Button>
              {errors.length > 0 ? (
                <span />
              ) : (
                <Button type="primary" loading={Loading} onClick={submitSave}>
                  {warnings.length > 0 ? "Guardar de todos modos" : "Guardar"}
                </Button>
              )}
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}
