import React, { useState, useEffect } from 'react'
import { Form, FormComponent } from 'components/Form'
import Http from 'Http';
import { message } from 'antd';
import { useHistory, useParams } from "react-router-dom";
import BackButton from 'components/BackButton/BackButton';

export default function Productor() {
  const Params = useParams();
  const History = useHistory();

  const [FormData, setFormData] = useState(
    Form.Group([
      Form.ID('idproductor'),
      Form.Field('selectsearch', 'idpropietario', 'Propietario', 6, { required: true }),
      Form.Field('text', 'nombre', 'Nombre', 6, { required: true, maxLength: 40 }),
      Form.Field('textarea', 'comentario1', 'Comentario 1', 12, { required: false, maxLength: 40 }),
      Form.Field('textarea', 'comentario2', 'Comentario 2', 12, { required: false, maxLength: 40 }),
      Form.Field('textarea', 'comentario3', 'Comentario 3', 12, { required: false, maxLength: 40 }),
      Form.Section('Datos fiscales'),
      Form.Field('text', 'razonsocial', 'Razón social', 4, { required: true, maxLength: 60 }),
      Form.Field('text', 'direccion', 'Dirección', 4, { required: true, maxLength: 60 }),
      Form.Field('text', 'telefono', 'Teléfono', 4, { required: true, maxLength: 15 }),
      Form.Section('Datos de acceso a reportes de internet'),
      Form.Field('text', 'usuario', 'Usuario', 4, { required: true, maxLength: 20 }),
      Form.Field('password', 'password', 'Contraseña', 4, { required: true, minLength: 3 }),
      Form.Field('password', 'password2', 'Confirmar contraseña', 4, { required: true, minLength: 3 }),
      Form.Section('Datos empresario'),
      Form.Field('text', 'empresario', 'Nombre', 4, { required: true, maxLength: 40 }),
      Form.Field('text', 'tel_empresario', 'Teléfono', 4, { required: true, maxLength: 15 }),
      Form.Field('text', 'mail_empresario', 'Correo', 4, { required: true, email: true, maxLength: 40 }),
      Form.Section('Datos asistente'),
      Form.Field('text', 'asistente', 'Nombre', 4, { required: false, maxLength: 40 }),
      Form.Field('text', 'tel_asistente', 'Teléfono', 4, { required: false, maxLength: 15 }),
      Form.Field('text', 'mail_asistente', 'Correo', 4, { required: false, maxLength: 40 }),
    ], ['Guardar'], 'productor', false)
  );

  useEffect(() => {
    fillForm();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (Params.id) {
      let newFormData = Form.FieldMod({ ...FormData }, 'password', 'Validators', { required: false });
      newFormData = Form.FieldMod({ ...newFormData }, 'password2', 'Validators', { required: false });
      setFormData({ ...newFormData });
    }
    // eslint-disable-next-line
  }, [Params]);

  const fillForm = async () => {
    try {
      let Res = await Http.get('productor/form');
      if (!Res.error) {
        setFormData(Form.FieldOptions({ ...FormData }, 'idpropietario', Res.data.propietarios, 'idpropietario', 'descripcion'))
      }
    } catch (error) {

    }
  }

  const onSubmit = async (Values, Button) => {
    console.log(`[Submit] Botón ${Button}, valores: `, Values)
    if (Values.password !== Values.password2) {
      message.error('Las contraseñas no coinciden')
      return;
    }
    if (Params.id) Values.id = Params.id
    try {
      let Res = await Http.post(`productor/save/dos`, { ...Values });
      console.log(Res)
      if (!Res.error) {
        // console.log('Guardado');
        if(!Params.id)
          History.goBack()
        message.info(`Guardado`);
      }
      else {
        message.warn(`Error: ${Res.message}`);
      }
    } catch (error) {
      message.warn(`Error al guardar`);
      console.log(`Error al guardar: `, error)
    }
    return;
  }

  return (
    <div>
      <BackButton />
      <h2>Productor</h2>
      <FormComponent
        data={FormData}
        onSubmit={onSubmit}
        onValueChanged={setFormData}
      />
    </div>
  )
}
