import React, { useEffect, useState, useContext, useRef } from 'react'
import { Table, Button, Modal, message, Select, Input } from 'antd'
import Http from '../../Http'
import { useHistory, useLocation } from "react-router-dom";
import { AppContext } from 'App';
import Permitido from 'Procesos';
import './Table.css'
import { capitalize, SetAppTitle } from 'Utils';
import usePersistedState from 'utils/usePersistedState';
import { ArrowLeftOutlined, DeleteOutlined, EditOutlined, PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
const { confirm } = Modal;



let QueryParams = [];
let OrderBy = [];
// const setQueryParams = (Pars = []) => {
//   Pars.forEach((Par) => {
//     let QPIdx = QueryParams.findIndex((qp) => qp[0] === Par[0]);
//     if (QPIdx >= 0)
//       QueryParams[QPIdx][1] = Par[1]
//     else
//       QueryParams.push(Par);
//   })
//   console.log(`[setQueryParams]: `, QueryParams)
//   return QueryParams;
// }
let timeoutCallback = 0;

export function TableComponent({ data, goBack = true, create = true, newRow }) {
  const { Fields, Actions, Config } = data;
  const [DataSource, setDataSource] = useState([])
  // const [Filters, setFilters] = useState({ Select: [], Inputs: [] })
  // const [Pagination, setPagination] = useState({ current: 1, pageSize: 10, pageSizeOptions: ['5', '10', '25', '50'], total: 100, showSizeChanger: true })
  const [Filters, setFilters] = usePersistedState(`${data.Config.Model}-filters`, { Select: [], Inputs: [] })
  const [Pagination, setPagination] = usePersistedState(`${data.Config.Model}-pagination`, { current: 1, pageSize: 10, pageSizeOptions: ['5', '10', '25', '50'], total: 100, showSizeChanger: true })
  // const [QueryParams, setQueryParams] = useState([])
  const [Loading, setLoading] = useState(true)
  let History = useHistory();
  let Location = useLocation();
  const AppValues = useContext(AppContext)
  const inputRef = useRef([]);
  // let Location = useLocation();
  const _queryString = new URLSearchParams(Location.search);
  const _queryParams = Object.fromEntries(_queryString);
  const actionClicked = useRef(false);
  const ActionIcons = {
      delete: <DeleteOutlined />,
      edit: <EditOutlined />,
  }

  let Columns = [];
  if (Config.FieldId)
    Columns.push({
      title: 'ID',
      key: 'id',
      dataIndex: Config.FieldId,
      sorter: (a, b) => { },
    })
  Columns = [...Columns, ...Fields.map((Field) => ({
    title: Field.Label,
    dataIndex: Field.Name,
    key: Field.Name,
    defaultSortOrder: (_queryParams.o1 && _queryParams.o1 === Field.Name) ? _queryParams.o2 === 'ASC' ? 'ascend' : 'descend' : null,
    sorter: (a, b) => { },
    // sortDirections: ['descend'],
  }))];
  if (Actions.length > 0) {
    Columns.push({
      title: 'Acción',
      key: 'accion',
      fixed: 'right',
      render: (text, record) => (
        <span>
          {
            Actions.map((Action, Ai) => (
              <Button
                key={Ai}
                type={`${Action.Label.search('liminar') >= 0 ? 'danger' : 'primary'}`}
                shape={`${Action.Icon ? 'circle' : 'round'}`}
                // shape="circle"
                onClick={() => ActionClick(Action, record)}
                icon={Action.Icon ? ActionIcons[Action.Icon] : ''}
                style={{ marginRight: 4 }}
              >
                {Action.Icon ? null : Action.Label}
              </Button>
              // <Button key={Ai} type='primary' onClick={() => ActionClick(Action, record)} style={{ marginRight: 4 }}>{Action.Label}</Button>
            ))
          }
        </span>
      )
    });
  }
  // console.log(data)

  useEffect(() => {
    OrderBy = []
    QueryParams = []
    SetAppTitle(Config.Model)
    // GetData();

    // checkQueryInputs();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (newRow) {
      setDataSource(DataSource => {
      const index = DataSource.findIndex(dta => dta.idcontacto == newRow.idcontacto)
      if (index >= 0) {
        DataSource[index] = newRow;
        return [...DataSource];
      } else {
        return [newRow, ...DataSource,]
      }
      });
    }
  }, [newRow]);

  const ActionClick = (Action, Row) => {
    // console.log(Config.Model)
    // console.log(Row)
    // console.log('ACTION CLICK');
    actionClicked.current = true;

    if (Action.Id === 'defDelete') {
      if (!Permitido.Eliminar(AppValues, Config.Model))
        return message.warn('Acción no permitida');
      confirm({
        title: 'Confirmación para borrar registro',
        content: `¿Borrar el registro ${Row[Config.DeleteFieldId]}?`,
        okText: 'Sí',
        cancelText: 'No',
        okType: 'danger',
        async onOk() {
          // console.log('OK');
          try {
            let Res = await Http.delete(`${Config.Model}/${Row[Config.FieldId]}/delete`);
            // console.log(Res)
            if (!Res.error) {
              GetData()
            } else {
              message.error(Res.errorMsg ? Res.errorMsg : 'Error al eliminar', 10);
            }
          } catch (error) {
            console.log(error)
          }
        },
        onCancel() { }
      })
    }
    else if (Action.Id === 'defUpdate') {
      // console.log(Row, Config.FieldId, Location, Location.pathname.slice(0, -1));
      // History.push(`${Location.pathname.slice(0, -1)}/${Row[Config.FieldId]}`);
      let id = '';
      if (Row[Config.FieldId]) {
        id = `${Row[Config.FieldId]}`.trim();
      }
      History.push(`${capitalize(Config.Model)}/${id}`);
    }
    else
      Action.Cb(Row)
  }

  const GetData = async (TablePag = Pagination, ExtraParams = QueryParams, goToFirst = true) => {
    // console.log(Fields)
    // console.log(Params)
    // console.warn('go')
    // console.log(TablePag)
    // clearTimeout(timeoutCallback);
    setLoading(true);
    try {
      let TableParams = [['p', TablePag.current - 1], ['l', TablePag.pageSize]];
      TableParams = [...TableParams, ...ExtraParams];
      if (OrderBy.length > 0) TableParams = [...TableParams, ['order1', OrderBy[0]], ['order2', OrderBy[1]]]
      // console.log(TableParams)
      let Req = await Http.get(`${Config.Model}/filter`, TableParams);
      // console.log(Req)
      if (Req.error) { setLoading(false); return console.error(`[Table] Error al traer datos de ${Config.Model}/filter: ${Req.errMsg}`) }
      const { rows, total } = Req.data;
      let DataSource = rows.map((row, ri) => {
        return {
          key: ri,
          ...row,
        }
      })
      // console.log(DataSource)
      setDataSource(DataSource)
      let currentPagination = {
        ...TablePag, total
      }
      // if(!DataSource || DataSource.length < 1) currentPagination.current = 1;
      setPagination(currentPagination)

      if (goToFirst && (!DataSource || DataSource.length < 1) && TablePag.current !== 1)
        GetData({ ...TablePag, current: 1 }, ExtraParams, false);

    } catch (error) {
      console.error(error)
      console.error(`[Table] Error (req) al traer datos de ${Config.Model}/filter: ${error.response}`)
    }
    setLoading(false);
  }

  const TableChange = (pagination, filters, sorter) => {
    // console.log(pagination, sorter)
    OrderBy = []
    if (sorter.order) {
      OrderBy = [sorter.field, sorter.order === 'descend' ? 'DESC' : 'ASC']
    }
    // console.log(NewQP)
    // setQueryParams(NewQP);
    setPagination(pagination)

    GetData(pagination)

    updateQueryUrl(pagination);
  }

  const SelectFilterChange = (filters) => {
    let inputs = filters.map((filter) => {
      let field = Fields.find((field) => field.Name === filter);
      let input = Filters.Inputs.findIndex((input) => input.Name === filter);
      return {
        ...field,
        Value: input >= 0 ? Filters.Inputs[input].Value : '',
      }
    })
    // console.log(inputs)
    setFilters({ ...Filters, Select: filters, Inputs: inputs })

    if (filters.length === 0) {
      GetData(Pagination, [])
      updateQueryUrl();
    }
  }

  const updateQueryUrl = () => {
    // console.log(data)
    let queryString = `?`;
    Filters.Inputs.forEach((input) => {
      queryString = `${queryString}${input.Name}=${input.Value}&`;
    })

    if (Pagination.current && Pagination.pageSize) {
      queryString = `${queryString}p=${Pagination.current}&l=${Pagination.pageSize}`;
    }

    if (OrderBy && OrderBy.length > 1) {
      queryString = `${queryString}&o1=${OrderBy[0]}&o2=${OrderBy[1]}`;
    }

    History.replace({
      pathname: Location.pathname,
      search: queryString,
    })
  }

  const InputFilterChange = (ii, name, val) => {
    // console.log(ii, name, val)

    setFilters((prevFilters) => {
      prevFilters.Inputs = prevFilters.Inputs.map((inputs) => {
        if (inputs.Name !== name) return inputs;
        inputs.Value = val;
        return inputs;
      })
      return { ...prevFilters };
    })

    // console.log(Location);
  }

  const selectFilters = () => {
    // console.log('HOLAAA')
    if (!Filters.Inputs || Filters.Inputs.length < 1) {
      checkQueryInputs();
    }
  }

  const Filter = (pagination = Pagination) => {
    // console.log(Filters.Inputs)
    let Pars = Filters.Inputs.map((inp) => ([inp.Name, inp.Value]))
    // console.log(Pars)
    QueryParams = Pars;
    GetData(pagination, Pars)
  }

  useEffect(() => {
    selectFilters();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    updateQueryUrl();
    clearTimeout(timeoutCallback);
    const doFilter = () => {
      // setPagination({ ...Pagination, current: 1 });
      // Filter({ ...Pagination, current: 1 });
      // console.log('DO FILTER')
      Filter();
    }
    timeoutCallback = setTimeout(() => {
      doFilter();
    }, 1000);
    // eslint-disable-next-line
  }, [Filters]);

  useEffect(() => {
    updateQueryUrl();
    // console.log(Pagination)
    // GetData(Pagination)
    // eslint-disable-next-line
  }, [Pagination])

  const checkQueryInputs = () => {
    // console.log(Location)
    // if (!Location.search || Location.search.length < 2) return GetData();
    if (!Location.search || Location.search.length < 2) return;
    // console.log(Location.search)
    let queryString = new URLSearchParams(Location.search);
    let queryParams = Object.fromEntries(queryString);
    // console.log(queryParams);
    let inputs = [], filters = [];
    for (let key in queryParams) {
      if (key === 'p' || key === 'l' || key === 'o1' || key === 'o2') continue;
      filters.push(key);
      let field = Fields.find((field) => field.Name === key);
      inputs.push({
        ...field,
        Value: queryParams[key]
      })
    }

    if (queryParams.p && queryParams.l) {
      setPagination({ current: parseInt(queryParams.p), pageSize: parseInt(queryParams.l) });
    }

    if (queryParams.o1 && queryParams.o2) {
      OrderBy[0] = queryParams.o1;
      OrderBy[1] = queryParams.o2;
    }

    // console.log({ ...Filters, Select: filters, Inputs: inputs });
    setFilters({ ...Filters, Select: filters, Inputs: inputs })
  }

  const onFilterInputKey = (e) => {
    // console.log(e.key)
    if (e.key === 'Enter') {
      Filter();
    }
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      if (typeof Config.Selectable == 'function') Config.Selectable(selectedRows);
    },
    // getCheckboxProps: record => ({
    //   disabled: record.name === 'Disabled User', // Column configuration not to be checked
    //   name: record.name,
    // }),
  };

  const onRowClick = (row, index) => {
    // console.log('ON ROW CLICK');
    if (actionClicked.current) {
      actionClicked.current = false;
      return
    }
    let accionesNoEliminar = Actions.filter(_action => !(_action.Label.search('liminar') >= 0));
    let accion = accionesNoEliminar && accionesNoEliminar.length > 0 ? accionesNoEliminar[0] : null;
    if (accion)
      ActionClick(accion, row)
  }

  return (
    <div className='atable'>
      {/* <div className='add'>
        <Button onClick={() => History.push(`${capitalize(Config.Model)}`)} icon='plus' type='primary' size='large' shape='circle'></Button>
      </div> */}
      { (goBack || create) && (
        <div className='add2'>
          <Button onClick={() => History.goBack()} icon={<ArrowLeftOutlined />} type='ghost'>Regresar</Button>
          <Button onClick={() => History.push(`${capitalize(Config.Model)}`)} icon={<PlusCircleOutlined />} type='primary'>Crear</Button>
        </div>)
      }
      <div className='filtros'>
        <div className='par'>
          <Select style={{ minWidth: 160 }} placeholder='Filtros' mode='multiple' value={Filters.Select} onChange={SelectFilterChange}>
            {
              Fields.map((Field, Fi) => (
                <Select.Option key={`${Field.Name}-${Fi}`} value={Field.Name}>{Field.Label}</Select.Option>
              ))
            }
          </Select>
          {/* </div> */}
          {
            Filters.Inputs.length > 0 && (
              // <div className='par'>
              <React.Fragment>
                {
                  Filters.Inputs.map((InputI, Ii) => (
                    <Input onKeyPress={onFilterInputKey} key={`${InputI.Name}.${Ii}`} placeholder={InputI.Label} value={InputI.Value} onChange={(event) => InputFilterChange(Ii, InputI.Name, event.target.value)} ref={el => inputRef.current[Ii] = el} />
                  ))
                }
                <Button icon={<SearchOutlined />} shape='circle' onClick={Filter}></Button>
              </React.Fragment>
              // </div>
            )
          }
        </div>
      </div>
      <Table
        rowSelection={Config.Selectable ? rowSelection : undefined}
        onChange={TableChange}
        dataSource={DataSource}
        columns={Columns}
        pagination={Pagination}
        loading={Loading}
        size="small"
        scroll={{
          x: true,
          scrollToFirstRowOnChange: true
        }}
        onRow={(record, rowIndex) => {
          return {
            onClick: event => onRowClick(record, rowIndex), // click row
          };
        }}
      // indentSize={50}
      />
    </div>
  )
}

