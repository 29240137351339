import React, { useState, useEffect } from 'react'
import { Form, FormComponent } from 'components/Form'
import { useParams } from "react-router-dom";
import Http from 'Http';
import { message, Button, Modal, Spin } from 'antd';
import Numerar from '../Recintos/NumerarCalendario';
import { CopyOutlined } from '@ant-design/icons';

let SumaCupo = { filas: 0, columnas: 0, muertas: 0, total: 0 };
export default function Area({ idrecinto = null, onSave = null, save = true, area: defaultArea = null, adding: addingArea = false, loadAreas = null, omitirParamId = false, closeModal }) {
  const Params = useParams();
  const [FormData, setFormData] = useState(
    Form.Group([
      // Form.ID('idarea'),
      Form.Field('select', 'idrecinto', 'Recinto', 4, { required: true }),
      Form.Field('text', 'area', 'Nombre del área', 6, { required: true, maxLength: 15 }, []),
      Form.Field('checkbox', 'numerado', 'Numerado', 2, { required: true }),
      Form.Field('number', 'filas', 'Filas', 3, { required: false }, [], 0, (val, fields) => filasChange(val, fields)),
      Form.Field('number', 'columnas', 'Columnas', 3, { required: false }, [], 0, (val, fields) => columnasChange(val, fields)),
      Form.Field('number', 'muertas', 'Muertas', 3, { required: false }, [], 0, (val, fields) => muertasChange(val, fields)),
      Form.Field('number', 'cupo', 'Cupo', 3, { required: false }, [], 0, false), /**Este no existe en DB */
      Form.Field('select', 'eje_escenario', 'Escenario', 4, { required: false }, [
        { Label: 'Arriba', Value: 1 },
        { Label: 'Abajo', Value: 2 },
        { Label: 'Sin escenario', Value: -1 },
      ], undefined),
      Form.Field('number', 'ancho', 'Ancho', 4, { required: false }, [], undefined),
      Form.Field('number', 'alto', 'Alto', 4, { required: false }, [], undefined),
      Form.Field('number', 'x', 'Posición X', 4, { required: false }, [], undefined),
      Form.Field('number', 'y', 'Posición Y', 4, { required: false }, [], undefined),
      Form.Field('color', 'colarea', 'Color', 4, { required: true }),
      Form.Field('checkbox', 'vender_mb', 'Vender como mejor boleto', 4, { required: true }),
      Form.Field('checkbox', 'ventaweb', 'Vender en internet', 4, { required: true }),
      Form.Field('checkbox', 'ilimitada', 'Área ilimitada', 4, { required: true }),
    ], ['Guardar'], null, false)
  );
  const [ModalNumerar, setModalNumerar] = useState(false)
  const [loading, setLoading] = useState(false)

  // console.log('Hola', FormData)

  const calcularCupo = (fields) => {
    // console.log('HOLAAAAAAAAAAAAAAAAAAAAA')
    SumaCupo.total = SumaCupo.filas * SumaCupo.columnas - SumaCupo.muertas;
    let SumaFields = fields.map((Field) => {
      if (Field.Name !== 'cupo') return Field;
      Field.Value = SumaCupo.total;
      return Field;
    })
    // console.log(SumaCupo, SumaFields)
    setFormData({ ...FormData, Fields: SumaFields });
  }

  const filasChange = (val, fields) => {
    if (!val) return;
    // console.log('Filas change', val, fields)
    if (SumaCupo.filas === val) return;
    SumaCupo.filas = val;
    calcularCupo(fields);
  }

  const columnasChange = (val, fields) => {
    if (!val) return;
    if (SumaCupo.columnas === val) return;
    SumaCupo.columnas = val;
    calcularCupo(fields);
  }

  const muertasChange = (val, fields) => {
    if (!val) return;
    if (SumaCupo.muertas === val) return;
    SumaCupo.muertas = val;
    calcularCupo(fields);
  }

  useEffect(() => {
    // console.log('Default Area', defaultArea, idrecinto)
    if (defaultArea) {
      // setFormData(Form.Patch({ ...FormData }, { ...defaultArea }))

      SumaCupo.filas = defaultArea.filas;
      SumaCupo.columnas = defaultArea.columnas;
      SumaCupo.muertas = defaultArea.muertas;
      calcularCupo(Form.Patch({ ...FormData }, { ...defaultArea, idrecinto: `${idrecinto}` }).Fields);
    }
    else {
      // console.log('Default Area Reset')
      setFormData({ ...Form.Reset({ ...FormData }) })

      // let reseted = Form.Reset({ ...FormData });
      // setFormData(Form.Patch({ ...reseted }, { idrecinto: `${idrecinto}` }));
    }
    if (idrecinto) {
      // console.log(`[Área] Recinto default: ${idrecinto}`)
      setFormData(Form.Patch({ ...FormData }, { idrecinto }))
    }
    // eslint-disable-next-line
  }, [defaultArea, idrecinto]);

  useEffect(() => {
    // console.log('holaaa', FormData.Fields);
  }, [FormData])

  useEffect(() => {
    fillForm();
    // eslint-disable-next-line
  }, [])

  const loadPreds = async (id = 1) => {
    if (!defaultArea && !Params.id) return;
    // console.log(addingArea)
    if (addingArea) return;
    id = defaultArea ? (defaultArea.idarea) : !omitirParamId ? Params.id : null;
    if(!id) return;
    // if(!defaultArea ||)  
    try {
      let Res = await Http.get(`area/${id}`);
      console.log(Res)
      if (!Res.error) {
        const { data } = Res;
        let { Fields } = FormData;
        let FieldsWPreds = Fields.map((Field) => {
          if (!data[Field.Name]) return Field;
          Field.Value = data[Field.Name];
          return Field;
        })
        SumaCupo.filas = data.filas;
        SumaCupo.columnas = data.columnas;
        SumaCupo.muertas = data.muertas;
        calcularCupo(FieldsWPreds);
        // setFormData({ ...FormData, FieldsWPreds })
      }
    } catch (error) {

    }
  }

  const fillForm = async () => {
    setLoading(true);
    try {
      let Res = await Http.get('area/form');
      if (!Res.error) {
        if (isNaN(idrecinto)) {
          setLoading(false);
          return;
        }
        // console.log(Res.data.recintos);
        // console.log(FormData);
        setFormData(
          Form.FieldOptionsGroup({ ...FormData }, [
            Form.FOG_Field('idrecinto', Res.data.recintos, 'idrecinto', 'nombre'),
          ])
        )
        loadPreds();
      }
    } catch (error) {

    }
    setLoading(false);
  }

  const onSubmit = async (Values, Button) => {
    // console.log(`[Submit] Botón ${Button}, valores: `, Values)
    // console.log({ ...Values, id: Params.id });
    // return;
    let idarea = defaultArea ? (defaultArea.idarea) : !omitirParamId ? Params.id : null;
    Values = { ...Values, idarea }
    if (save) {
      // No viene desde crear Recinto, está en Crear Área
      try {
        let Res = await Http.post(`area/save/area`, { ...Values, id: idarea });
        // console.log(Res)
        if (!Res.error) {
          // console.log('Guardado');
          message.info(`Guardado`);
          if (onSave) {
            onSave(true, Res.data);
            return;
          }
          History.goBack()
        }
        else {
          message.warn(`Error: ${Res.message}`);
          if (onSave)
            onSave(false, Res.errorMsg);
        }
      } catch (error) {
        message.warn(`Error al guardar`);
        if (onSave)
          onSave(false, error);
        console.log(`Error al guardar (doSubmit) para Área (dc): `, error)
      }
      return;
    }
    else {
      // Viene desde crear/editar Recinto, mandar datos del área sin guardar
      onSave(true, { ...Values, ai: (defaultArea ? defaultArea.ai : null), idarea: (defaultArea ? defaultArea.idarea : null) });
      setFormData(Form.Reset({ ...FormData }))
    }
    return;
  }

  const onNumerar = (idarea) => {
    closeModal();
    window.open(`${window.location.protocol}//${window.location.host}/#/Catalogo/Numeracion/${idarea}`, '_blank');
  }

  const confirmCopy = async () => {
    Modal.confirm({
      centered: true,
      title: 'Confirmar',
      content: '¿Copiar el área?',
      okText: 'Sí, copiar',
      cancelText: 'No copiar',
      onCancel: () => { },
      onOk: async () => { await copyArea() }
    })
  }

  const copyArea = async () => {
    console.log(defaultArea)
    try {
      let Res = await Http.post(`area/copiar/${defaultArea.idarea}`, {});
      console.log(Res);
      if (!Res.error) {
        message.info('Área copiada');
        if (loadAreas) loadAreas();
      }
      else {
        message.error('Error al copiar')
      }
    } catch (error) {
      message.error('Error inesperado al copiar')
    }
  }

  return (
    <div>
      {
        defaultArea && defaultArea.idarea && (
          <div style={{ textAlign: 'right', paddingBottom: 10 }}>
            <Button icon={<CopyOutlined />} shape='circle' onClick={() => confirmCopy()} />
          </div>
        )
      }
      <h2>Área</h2>
      { loading ?
        <Spin /> 
        :
        (<FormComponent
            data={FormData}
            onSubmit={onSubmit}
            onValueChanged={(fd) => { setFormData(fd); }}
          >
            {
              defaultArea && defaultArea.idarea && (
                <div>
                  <Button onClick={() => onNumerar(defaultArea.idarea)}>Numeración</Button>
                </div>
              )
            }
          </FormComponent>
        )
      }

      <Modal
        destroyOnClose={true}
        visible={ModalNumerar}
        onOk={() => setModalNumerar(false)}
        onCancel={() => setModalNumerar(false)}
        footer={null}
        width='100%'
        centered
      >
        <Numerar
          area={defaultArea ? defaultArea.idarea : null}
          filas={SumaCupo.filas}
          columnas={SumaCupo.columnas}
          onSaveModal={() => {
            setModalNumerar(false);
            if (closeModal) {
              closeModal();
            }
          }}
        />
      </Modal>
    </div>
  )
}
