import React, { useState, useEffect } from 'react'
import { Form, FormComponent } from 'components/Form'
import moment from 'moment';
import Global from 'Global';

export default function Internet({ onNext, data = {}, fill = {}, loading = false, onData = () => { } }) {

  const [FormData, setFormData] = useState(
    Form.Group([
      Form.Field('checkbox', 'pinternet', 'Publicar', 6, { required: false }),
      Form.Field('checkbox', 'vinternet', 'Vender', 6, { required: false }, null, undefined, (val, cf) => venderChange(val, cf)),
      Form.Field('radio', 'estado_noventa', 'Estado', 12, { required: false }, [
        { Value: 1, Label: 'Normal' },
        { Value: 2, Label: 'Proximamente' },
        { Value: 3, Label: 'Solo en taquilla' },
        { Value: 4, Label: 'Agotado' },
        { Value: 5, Label: 'Cerrado' },
        { Value: 6, Label: 'Cancelado' },
      ], undefined, null, false),
      Form.Field('number', 'mincantventa', 'Cantidad mínima', 6, { required: true }, null, 1),
      Form.Field('number', 'maxcantventa', 'Cantidad máxima', 6, { required: true }, null, 10),
      Form.Field('datetime', 'fec_ini_vta_web', 'Vigencia inicio venta Web', 6, { required: true }, null, moment().hours(0).minutes(0).toDate()),
      Form.Field('datetime', 'fec_fin_vta_web', 'Vigencia fin venta Web', 6, { required: true }, null, moment().add(1, 'd').hours(23).minutes(59).toDate()),
    ], ['Guardar'], null, false)
  );

  useEffect(() => {
    if (data) {
      // console.log('data', data)
      let newFormData = Form.Patch({ ...FormData }, { ...data });
      newFormData = Form.FieldMod({...FormData }, 'estado_noventa', 'Type', data.vinternet ? 'hidden' : 'radio');
      newFormData =  Form.FieldMod({...FormData }, 'estado_noventa', 'Hidden', data.vinternet)
      setFormData(newFormData)
    }
    propSettings();
    // eslint-disable-next-line
  }, [fill]);

  const propSettings = () => {
    if (Global.PROP === "BPMEX") {
      setFormData({
        ...Form.FieldModGroup(FormData, [
          Form.FMG_Field("pinternet", "Disabled", true),
          Form.FMG_Field("vinternet", "Disabled", true),
          Form.FMG_Field("estado_noventa", "Disabled", true),
          Form.FMG_Field("mincantventa", "Disabled", true),
          Form.FMG_Field("maxcantventa", "Disabled", true),
          Form.FMG_Field("fec_ini_vta_web", "Disabled", true),
          Form.FMG_Field("fec_fin_vta_web", "Disabled", true),
        ])
      });
    }
  }

  const venderChange = (val, cf) => {
    
    let newFormData = Form.FieldMod({...FormData, Fields: cf}, 'estado_noventa', 'Type', val ? 'hidden' : 'radio');
    newFormData =  Form.FieldMod({...FormData, Fields: cf}, 'estado_noventa', 'Hidden', val)
    newFormData =  Form.FieldMod({...FormData, Fields: cf}, 'estado_noventa', 'Value', 1)
    setFormData({ ...newFormData })
    //   if(val) {
    // }
    // else {

    // }
  }

  const onSubmit = (Values, Button) => {
    console.log(`[Submit] Botón ${Button}, valores: `, Values)
    onNext(Values)
  }

  const onValueChange = (value) => {
    const values = Form.Values(value);
    setFormData(value)
    onData(values);
  }

  return (
    <div>
      <FormComponent
        data={FormData}
        onSubmit={onSubmit}
        onValueChanged={onValueChange}
        loading={loading}
      />
    </div>
  )
}
